import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from './mlldrawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import EditMLLConsignment from "./editrailsconsignment";
import CountUp from 'react-countup';
import MLLConsignmentsChildGrid from "./railconsignmentlegs";
import ShowRoute from "./showtptroute";
import ShowEtaLegs from "./showtptetalegs";
import CreatableSelect from 'react-select/creatable';


$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var Constant = require('../common/Constant');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class chettinadRailStockAtLoadingTerminals extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			detailCellRendererParams:{},
            frameworkComponents: {
				editMLLConsignment:EditMLLConsignment,
				showroute:ShowRoute,
				showetalegs:ShowEtaLegs
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"",
			sliderRouteTranslate:'',
			sliderForceDestination:"",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
			sourceopt:'',
			fnr_no:"",
			gpsdevice:"",
			sources:[],
			totalvinsarr:[],
			totalvins:0,
			atplantarr:[],
			atplant:0,
			intransitloadarr:[],
			intransitload:0,
			atloadarr:[],
			atload:0,
			unloadingatloadingyardarr:[],
			unloadingatloadingyard:0,
			rakeassignarr:[],
			rakeassign:0,
			intransitdestarr:[],
			intransitdest:0,
			atdestinationarr:[],
			atdestination:0,
			intransitdealerarr:[],
			intransitdealer:0,
			nearcutomerarr:[],
			nearcutomer:0,
			intransitrsoarr:[],
			intransitrso:0,
			atrsoarr:[],
			atrso:0,
			neardealerarr:[],
			neardealer:0,
			tripendrsoarr:[],
			tripendrso:0,
			podreceiptarr:[],
			podreceipt:0,
			unloadatloadingarr:[],
			unloadatloading:0,
			startrailloadingarr:[],
			startrailloading:0,
			unloadvehiclesarr:[],
			unloadvehicles:0,
			lastmiledestination:"",
			desttype:"",
			destypeopt:"",
			consignees:[],
			rsos:[],
			destopts:[],
			sliderForceLastMile:"",
			sliderRso:"",
			modetype:"",
			dropdownOpen: false,
			dropdownAssetOpen: false,
			sliderLoadingYardTranslate: "",
			destinations:[],
			unloadofficers:[],
			loading_officer:"",
			batransporter:"",
			transporters:[],
			devices:[],
			sliderDeviceRetrival:"",
			retrived:"",
			retrivalon:"",
			retrivedyes:"",
			retrivedno:"",
			showaction:"show-n",
			mapinfo:[],
			sliderDealerArrival:"",
			vehicles:[],
			gpsdeviceids:[],
			mapfirstmile:[],
			mapmiddlemile:[],
			maplastmilerso:[],
			maplastmiledealr:[],
			destopt:"",
			routefor:"",
			fmile:[],
			mmile:[],
			ulmile:[],
			rsomile:[],
			refno:"",
			rail_no:"",
			confirmdeliversarr:[],
			confirmdelivers:0,
			livelocation:"",
			liveaddress:"",
			liveactiveon:"",
			tosfile:"",
			verifytos:0,
			rake_no:"default",
			rakeList:({value:"",label:""})
        };
        // this.editRakeData = this.editRakeData.bind(this);
        // this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onClickShowMap = this.onClickShowMap.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.changeDestFileHandler = this.changeDestFileHandler.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.onClickEditBlock = this.onClickEditBlock.bind(this);
		this.changeLastMileFileHandler = this.changeLastMileFileHandler.bind(this);
		this.onChangeLastMilDestinationItem = this.onChangeLastMilDestinationItem.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleAsset = this.toggleAsset.bind(this);
		this.checkRadioEvent = this.checkRadioEvent.bind(this);
		this.onClickShowMapRoute = this.onClickShowMapRoute.bind(this);
		this.onClickShowETALegs = this.onClickShowETALegs.bind(this);
		this.changeTOSFileHandler = this.changeTOSFileHandler.bind(this);
	}
	
    componentDidMount(){
		loadDateTimeScript();
		var rake_no = this.props.match.params.rake_no;
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
		// console.log("Match props ", this.props.match.params.refno)
		this.setState({
			showaction:"show-n"
		})
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate,
			rake_no:rake_no
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("mindate ", mindate)
       // console.log("minactdate ", minactdate)
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var urlpath;
        var dpt;
        
			dpt='SNDG';
        
        this.setState({
			dept_code:dpt,
		});
		
		
		var tquery = {
			//transporter_code:localStorage.getItem("transportercode")
		}
		redirectURL.post("/dashboard/tptfirstmileretrivedevices", tquery)
		.then((response) => {
			var transporters = response.data.transporters;
			//var loadofficers = response.data.loadingofficers;
			//var devices = response.data.devices;
			var recordsarr = [];
			var unrecordsarr = []
			// if(loadofficers.length > 0)
			// {
			// 	loadofficers.map((item) => {
					
			// 		if(item.officer_type == "Unloading")
			// 		{
			// 			unrecordsarr.push(item)
			// 		}
			// 	})
			// }

			this.setState({
				transporters:transporters,
				//unloadofficers:unrecordsarr,
				//devices:devices
			});
		})

        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
		var parameters = {
			startdate:sdate,
			enddate:edate
		}
        
        this.onLoadRakePlanningData(parameters)

	}
    
    onLoadRakePlanningData(parameters)
    {
		var rake_no = this.props.match.params.rake_no;
		var transporter_code = localStorage.getItem("transportercode")
		var prms = {
			transporter_code:transporter_code
		}
		if(rake_no != "default")
		{
			parameters.rake_no = rake_no;
		}
		redirectURL.post("/dashboard/loaddestpoints",prms)
		.then((response) => {
			var records = response.data.records;
			var loadings = [];
			var destinatinos = [];
			if(records.length > 0)
			{
				records.map((item) => {
					if(item.rail_loading_point == 1)
					{
						loadings.push(item)
					}
					if(item.rail_delivery_point == 1)
					{
						destinatinos.push(item)
					}
				})
			}
			this.setState({
				sources:loadings,
				destinations:destinatinos
			});
		})
       
	   redirectURL.post("/dashboard/tptsndconsignees")
		.then((response) => {
			var records = response.data.records;
			this.setState({
				consignees:records
			});
		})
       
	   redirectURL.post("/dashboard/tptrsos")
	   .then((response) => {
		   var records = response.data.records;
		   this.setState({
			   rsos:records
		   });
	   })
       
	   redirectURL.post("/dashboard/getRakeNumbers")
	   .then((response) => {
		   	var records = response.data;
		   	var rakeList = [];
			records.map(function(item){
				rakeList.push({value:item,name:item});
			});
			console.log(rakeList,"rakeList-123")
		   	this.setState({
				rakeList:records
		   	});
	   })
	   try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				parameters.plant_code = localStorage.getItem("pc");
			}
		}catch(e){
			
		}
		if(localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="")
		{
			parameters.transporter_code = localStorage.getItem("transportercode")
		}
		if(localStorage.getItem("customer_code") !=undefined && localStorage.getItem("customer_code") !="undefined" && localStorage.getItem("customer_code") !="")
		{
			parameters.customer_code = localStorage.getItem("customer_code")
		}
        parameters.status = 9
		redirectURL.post("/dashboard/tptconsignments",parameters)
		.then((response) => {
			var records = response.data.records;
			this.setState({
				rowData:records,
                loadshow:"show-n",
                overly:"show-n"
			});
		})
    }
    
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='Consignments';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			try{
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
				
			}
			catch(e){

			}
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
	}
	onClickShowUploadTOSSidebar = (params) => {
		this.setState({
            //forceclosedata:params.data,
            sliderUploadTOSTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
	}

	onClickShowLoadingYardSidebar(){
		this.setState({
            sliderLoadingYardTranslate:"slider-translate-60p",
            overly:'show-m',
        })
	}
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderBulkForceClose:"",
			sliderForceLastMile:"",
			sliderRso:"",
			sliderLoadingYardTranslate: "",
			sliderDeviceRetrival:"",
			sliderDealerArrival:"",
            sliderUploadTOSTranslate:'',
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
	handleChange = (item) => {
        let rakeNo = item
		this.setState({ rake_no: rakeNo })
        // if (rakeNo !== null && rakeNo !== "") {
        //     let rake_no = rakeNo.value;
        //     // if (rakeNo.__isNew__ == true) {
        //     //     rake_no = rakeNo.value.toUpperCase()
        //     //     this.setState({rake_no:{value:rake_no,label:rake_no}})
        //     // }else{
        //     //     this.setState({rake_no:item})
        //     // }
        //     // console.log("rake_no", rake_no)
        //     this.setState({ rake_no: item })
        // }else{
        //     this.setState({
        //         rake_no : ""
        //     })
        // }
    };
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
	rakeList(){
		
		let items = [];
		console.log(this.state.rakeList,"this.state.rakeList")    
		try{
			if(this.state.rakeList.length > 0)
			{
				this.state.rakeList.map((item) =>{
					items.push({"value":item,"label":item})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    selectdestinations(){
		let items = [];    
		try{
			if(this.state.destinations.length > 0)
			{
				this.state.destinations.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
        
    gpsdevices(){
		let items = [];
		var devices = this.state.devices;
		if(devices.length > 0)
		{
			devices.map((item) => {
				items.push({"value":item.device_code,"label":item.device_code})
			})
		}    
		return items;
	}
    
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onChangeDestinationItem(destopt){
		this.setState(
			{ destopt },
			() => console.log(`Option selected:`, this.state.destopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                   // this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    onClickShowView(view)
    {
       // console.log("View ",view)
        if(view == "grid")
        {
            this.setState({
                gridview:'show-m',
                calcview:'show-n'
            })
        }
        if(view == "calender")
        {
            this.setState({
                gridview:'show-n',
                calcview:'show-m',
                calview:"timeGridWeek"
            })
        }
    }
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			silderUploadTOSTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			sliderDealerArrival:"",
		});
	}
	onClickShowMap = (params) =>{
       
    }

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

     handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;	
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;	
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

	
	changeDestFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }

    formRakeSchedule = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Rake Planning Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
		// console.log("csvcontent ", this.state.csvcontent);
		var fnr_no = this.state.fnr_no;
		var rake_no = "";
		var rakeNo = this.state.rake_no;
		if(rakeNo !=undefined && rakeNo !="")
		{
			if(rakeNo.value !="" && rakeNo.value !=undefined)
			{
				rake_no = rakeNo.value;
			}
		}
		if(this.state.gpsdevice != "")
		{
			var gpsdevice = this.state.gpsdevice.value
        }
		else
		{
			var gpsdevice = ""
		}
		var gpsdeviceids = []
		if(this.state.gpsdeviceids.length > 0)
		{
			
			this.state.gpsdeviceids.map((item) => {
				gpsdeviceids.push(item.value)
			})
		}
		// console.log("gpsdeviceids ", gpsdeviceids)
		var dest_yard_reachedon = $("#dest_yard_reachedon").val()
		// console.log("dest_yard_reachedon ", dest_yard_reachedon)
		// var transporter_code  = this.state.batransporter.value;
		// var transporter_name  = this.state.batransporter.label;

		if(this.state.loading_officer != "")
		{
			var loading_officer_code =  this.state.loading_officer.value;
			var loading_officer_name =  this.state.loading_officer.label;
	
		}
		else
		{
			var loading_officer_code =  "";
			var loading_officer_name =  "";
		}
		
		
		if(this.state.sourceopt != "")
		{
			var source =  this.state.sourceopt.value;
			var source_name =  this.state.sourceopt.label;
	
		}
		else
		{
			var source =  "";
			var source_name =  "";
		}
		
		if(this.state.destopt != "")
		{
			var destination = this.state.destopt.value;
			var destination_name =this.state.destopt.label;
		}
		else
		{
			var destination = "";
			var destination_name ="";
		}

		if(rake_no != "" && dest_yard_reachedon != "" && this.state.csvcontent != "" 
		 && this.state.csvcontent.length != 0 && dest_yard_reachedon != "__-__-____ __:__")
		{
			
			
			var reqparams = {
				refno:this.state.refno,
				csvcontent:this.state.csvcontent,
				source:source,
				source_name:source_name,
				destination:destination,
				destination_name:destination_name,
				fnr_no:fnr_no,
				rake_no:rake_no,
				gpsdevice:gpsdeviceids,
				dest_yard_reachedon:dest_yard_reachedon,
				unloading_officer_code:loading_officer_code,
                unloading_officer_name:loading_officer_name,
                transporter_code:localStorage.getItem("transportercode"),
				// transporter_code:transporter_code,
				// transporter_name:transporter_name,
				userid:localStorage.getItem("userid"),
				username:localStorage.getItem("username"),
				useremail:localStorage.getItem("email")
			}
			// console.log("reqparams ", reqparams)
			
			redirectURL.post("/dashboard/verifytptrakedata",{csvcontent:this.state.csvcontent})
			.then((response) => {
				console.log("resposnedata ", response.data)
				if(response.data.status == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Uploaded Batches are not exists",
						basicType:"danger"
					})
			
				}
				else if(response.data.status == "empty")
				{
					this.setState({
						show:true,
						basicTitle:"No data found",
						basicType:"danger"
					})
			
				}
				else
				{
					if(response.data.records.length == 0)
					{
						this.setState({
							show:true,
							basicTitle:"Uploaded Batches are not marked arrival",
							basicType:"danger"
						});
					}
					else
					{
						redirectURL.post("/dashboard/updaterakeschedulevins", reqparams)
						.then((response) => {
							if(response.data.status == "success" && response.data.notvalid.length == 0)
							{
								$("#dest_yard_reachedon").val("")
								this.setState({
									sliderRakeTranslate:"",
									overly:'show-n',
									sourceopt:"",
									gpsdevice:"",
									csvcontent:[],
									fnr_no:"",
									loadshow:'show-n',
									uploadFile:'',
									file:'',
									show:true,
									basicTitle:"Successfully update items",
									basicType:"success"
								});
								var parameters = {
									startdate:this.state.startDate,
									enddate:this.state.endDate,
									refno:this.state.refno
								}
								this.onLoadRakePlanningData(parameters)
							}
							else
							{
								if( response.data.notvalid.length > 0)
								{
									this.setState({
										show:true,
										basicTitle:"Vins are not maked arrival at loading yard. Vins : "+response.data.notvalid,
										basicType:"danger"
									})
								}
								else
								{
									this.setState({
										show:true,
										basicTitle:"Failed to update items",
										basicType:"danger"
									})
								}
								
							}
						})
						.catch(function(e){
							console.log("Error ", e)
						})
					}
				}
				
			})
			
			
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"All * fields are mandatory",
				basicType:"danger"
			})
		}
    }

	
    formUploadTOS = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Upload TOS Dummy Trucks",
				"action": "form",
				"label": "Upload TOS Dummy Trucks",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
		var reqparams = new FormData()
			reqparams.append("file", this.state.tosfile)
			reqparams.append("userid", localStorage.getItem("userid"))
			reqparams.append("username", localStorage.getItem("username"))
			reqparams.append("useremail", localStorage.getItem("email"))
			//console.log("reqparams ", reqparams)
			redirectURL.post("/dashboard/readtosdata", reqparams)
			.then(async (response) => {
				//console.log("TOS", response.data)
				var records = response.data.records;
				var vins = []
				var emptyvins = []
				if(records.length > 0)
				{
					await records.map((item) => {
						Object.keys(item).forEach(async function(key) {
							// console.log("VEHICLE ID ", key)
							if(key == "VIN")
							{
								vins.push(item[key])
								//console.log("item[key] ", item[key])
								
							}
							
						})
						// console.log("prpery ", item.hasOwnProperty("VEHICLE ID"))
						if(item.hasOwnProperty("VIN"))
						{
						
						}
						else{
							emptyvins.push(1)
						}
					})
					
				}
				if(emptyvins.length > 0)
				{
					this.setState({
						show:true,
						basicTitle:"Upload data should not be empty",
						basicType:"danger"
					})
				}
				else
				{
					var params = {
						"xlsdata": records,
						"vins" : vins,
						"userid" : localStorage.getItem("userid"),
						"username": localStorage.getItem("username"),
						"useremail": localStorage.getItem("email"),
						"transporter_code": localStorage.getItem("transportercode"),
						"refno" : this.state.refno
					}
					redirectURL.post("/dashboard/uploadtosdata", params)
					.then((resp) => {
						console.log("data ",resp.data)
						if(resp.data.message == "vins")
						{
							this.setState({
								show:true,
								basicTitle:"VINs are not exists",
								basicType:"danger"
							})
						}
						else if(resp.data.message == "vinsempty")
						{
							this.setState({
								show:true,
								basicTitle:"VIN is empty in uploaded file",
								basicType:"danger"
							})
						}
						else
						{
							this.setState({
								sliderUploadTOSTranslate:"",
								overly:'show-n',
								transporter_code:"",
								transporter_name:"",
								csvcontent:[],
								loadshow:'show-n',
								uploadFile:'',
								tosfile:'',
								showrel:true,
								basicTitlerel:"Successfully upload file",
								basicTyperel:"success"
							})
							
						}
					})
				}
			})
	}
	
    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
    
    onClickEditBlock(params){
        console.log("params ",params)
	}
    
    onChangeDeviceItem(gpsdeviceids){
		this.setState(
			{ gpsdeviceids },
			() => {
				if(gpsdeviceids.length > 0)
				{
					if(gpsdeviceids.length > 2)
					{
						this.setState({
							show1:true,
							basicTitle1:"Maximum 2 devices are allowed",
							basicType1:"info",
							gpsdeviceids:[]
						})
					}	
				}
			}
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	onClickForceMoveDestinationYard(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceDestination:'slider-translate',
			overly:"show-m"
		});
	}
	
	formForceMoveDestinationYardHandler = async (event) =>{
		event.preventDefault();

		var dest_yard_reachedon = document.getElementById("dest_yard_reached").value;
		var dest_yard = dest_yard_reachedon.split(" ");
		var spyard = dest_yard[0].split("-");
		var destyardreachedon = spyard[2]+"-"+spyard[1]+"-"+spyard[0]+" "+dest_yard[1]+":00"
		if(dest_yard_reachedon != "" && dest_yard_reachedon != "__-__-____ __:__")
		{
			var reqparams = {
				dest_yard_reachedon:dest_yard_reachedon,
				//refno:this.state.refno,
				userid:localStorage.getItem('userid'),
				username:localStorage.getItem('username'),
				email:localStorage.getItem('email'),
                csvcontent:this.state.csvcontent,
                //transporter_code:localStorage.getItem("transportercode")
			}
			redirectURL.post("/dashboard/verifytptrakedestdata",{csvcontent:this.state.csvcontent})
			.then((response) => {
				console.log("resposnedata ", response.data)
				if(response.data.status  == "nodata")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid data uploaded",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Uploaded Batches are not rake assigned",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "success")
				{
					redirectURL.post("/dashboard/tptmovedestinationyardarrival", reqparams)
					.then((response) => {
						// console.log("Respone ", response.data)
						var rec = response.data;
						if(rec.notvalid.length == 0 && rec.notexists.length == 0)
						{
							this.setState({
								show: true, 
								basicType:'success', 
								basicTitle:"Successfully Uploaded.",
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate,
								refno:this.state.refno
							}
							this.onLoadRakePlanningData(parameters)
						}
						else if(rec.notvalid.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:"Destination yard reached on should be greater than Dispatch date. "+rec.notvalid,
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						else if(rec.notexists.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:rec.notexists+" are not exists",
								uploadDivWidth:'0%',
								sliderForceDestination:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						else{
							
							
							
						}
						
					})
				}
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Please select data and time",
				basicType:"danger"
			});
		}
		
	
	}
	
    onClickCounter(data)
	{
		if(data == "all")
		{
			this.setState({
				rowData:this.state.totalvinsarr
			})
		}
		if(data == "atplant")
		{
			this.setState({
				rowData:this.state.atplantarr
			})
		}
		if(data == "intransitload")
		{
			this.setState({
				rowData:this.state.intransitloadarr
			})
		}
		if(data == "atload")
		{
			this.setState({
				rowData:this.state.atloadarr
			})
		}
		if(data == "rakeassign")
		{
			this.setState({
				rowData:this.state.rakeassignarr
			})
		}
		if(data == "intransitdest")
		{
			this.setState({
				rowData:this.state.intransitdestarr
			})
		}
		if(data == "atdestination")
		{
			this.setState({
				rowData:this.state.atdestinationarr
			})
		}
		if(data == "intransitrso")
		{
			this.setState({
				rowData:this.state.intransitrsoarr
			})
		}
		if(data == "atrso")
		{
			this.setState({
				rowData:this.state.atrsoarr
			})
		}
		if(data == "intransitdealer")
		{
			this.setState({
				rowData:this.state.intransitdealerarr
			})
		}
		if(data == "neardealer")
		{
			this.setState({
				rowData:this.state.neardealerarr
			})
		}
		if(data == "podreceipt")
		{
			this.setState({
				rowData:this.state.podreceiptarr
			})
		}
		if(data == "tripendrso")
		{
			this.setState({
				rowData:this.state.tripendrsoarr
			})
		}
		// 
		
		if(data == "unloadatload")
		{
			this.setState({
				rowData:this.state.unloadatloadingarr
			})
		}
		
		if(data == "startrail")
		{
			this.setState({
				rowData:this.state.startrailloadingarr
			})
		}
		
		if(data == "unloadvehicles")
		{
			this.setState({
				rowData:this.state.unloadvehiclesarr
			})
		}
		
		if(data == "delivered")
		{
			this.setState({
				rowData:this.state.confirmdeliversarr
			})
		}
		
		if(data == "nearcutomer")
		{
			this.setState({
				rowData:this.state.nearcutomerarr
			})
		}
		
		if(data == "unloadingatloadingyard")
		{
			this.setState({
				rowData:this.state.unloadingatloadingyardarr
			})
		}
	}

	
	onClickLastMileTransport(){
		//console.log("Force MOve ")
		this.setState({
			sliderForceLastMile:'slider-translate',
			overly:"show-m"
		});
	}
	
	changeLastMileFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		// var out = new Promise(function(reject, resolve){
		// 	var reader =  new FileReader();
		// 	reader.onload = async function(e) {
		// 		var contents = await e.target.result;
		// 		console.log("contents ", contents )
		// 		resolve(contents);
		// 	};
		// 	var tt =  reader.readAsText(e.target.files[0]);
		// 	console.log("tt ",tt)
		// });
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

	onChangeLastMilDestinationItem(lastmiledestination){
		this.setState(
			{ lastmiledestination },
			async () => {
				var items = []
				// console.log("lastmiledestination.value ", lastmiledestination.value)
				if(lastmiledestination.value != "")
				{
					var desttype = "";
					// console.log("lastmiledestination.value ",this.state.rsos)
					if(lastmiledestination.value == "RSO")
					{
						var rso = this.state.rsos;
						if(rso.length > 0)
						{
							rso.map((item) => {
								items.push({
									value:item.plant_code,
									label:item.plant_name
								});
							})
						}
						desttype = "Select RSO";
					}
					if(lastmiledestination.value == "Dealer")
					{
						var consignees = this.state.consignees;
						// console.log("consignees", consignees)
						if(consignees.length > 0)
						{
							consignees.map((item) => {
								items.push({
									value:item.consignee_code,
									label:item.consignee_name
								});
							})
						}
						
						// console.log("consignees items ", items)
						desttype = "Select Dealer";
					}
					
				}
				// console.log("items ", items)
				await this.setState({
					destopts:items,
					desttype:desttype
				})
			}
          );
		  
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:'',
			//   destopts:items
		  })
	}

	onChangeDestType(destypeopt){
		this.setState(
			{ destypeopt },
			() => console.log(`Option selected:`, this.state.destypeopt)
		  );
		  
		 
	}
	onChangeModeType(modetype){
		this.setState(
			{ modetype },
			() => console.log(`Option selected:`, this.state.modetype)
		  );
	}
	desttype(){
		var items = []
		if(this.state.destypeopt != "")
		{
			if(this.state.destypeopt.value == "RSO")
			{
				var rso = this.state.rsos;
				if(rso.length > 0)
				{
					rso.map((item) => {
						item.push({
							value:item.rso,
							label:item.rso
						});
					})
				}
			}
			if(this.state.destypeopt.value == "Dealer")
			{
				var consignees = this.state.consignees;
				if(consignees.length > 0)
				{
					consignees.map((item) => {
						item.push({
							value:item.consignee_code,
							label:item.consignee_name
						});
					})
				}
			}
		}
		return items;
	}

	formLastMileData(event)
	{
		event.preventDefault();
		var destypeopt = this.state.destypeopt.value;
		var destypeoptname = this.state.destypeopt.label;
		var lastmiledestination = this.state.lastmiledestination.value;
		var csvcontent = this.state.csvcontent;
		var modetype = this.state.modetype.value;
		var parameters = {
			destypeopt:destypeopt,
			destypeoptname:destypeoptname,
			lastmiledestination:lastmiledestination,
			modetype:modetype,
			csvcontent:csvcontent,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		// console.log("parameters ", parameters)

		redirectURL.post("/dashboard/verifytptlastmile",{csvcontent:this.state.csvcontent})
		.then((response) => {
			// console.log("resposnedata ", response.data)
			if(response.data.records.length == 0)
			{
				this.setState({
					show:true,
					basicTitle:"Uploaded Vins destination not exists",
					basicType:"danger"
				});
			}
			else
			{
				
				redirectURL.post("/dashboard/tptlasmileupdate", parameters)
				.then((response) => {
					// console.log("Respone ", response.data)
					var rec = response.data;
					if(rec.notvalid.length == 0)
					{
						this.setState({
							show: true, 
							basicType:'success', 
							basicTitle:"Successfully updated.",
							uploadDivWidth:'0%',
							sliderForceLastMile:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:'',
							csvcontent:""
						});
						var parameters = {
							startdate:this.state.startDate,
							enddate:this.state.endDate,
							refno:this.state.refno
						}
						this.onLoadRakePlanningData(parameters)
					}
					else if(rec.notvalid.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:"Destination yard not exists. ",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else if(rec.notexists.length > 0)
					{
						this.setState({
							show: true, 
							basicType:'danger', 
							basicTitle:rec.notexists+" are not exists",
							uploadDivWidth:'0%',
							sliderForceDestination:'',
							overly:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
					}
					else{
						
						
						
					}
					
				})
			}
		})
	}

	onClickRSOData(){
		this.setState({
			sliderRso:'slider-translate',
			overly:'show-m',
		})
	}

	formRSOData(event){
		event.preventDefault();
		var rsoreachedon = $("#rso_reached").val();
		console.log("rsoreachedon ", rsoreachedon)
		if(rsoreachedon != "" && rsoreachedon != "__-__-____ __:__" && rsoreachedon != undefined)
		{
			var csvcontent = this.state.csvcontent;
			var parameters = {
				rsoreachedon:rsoreachedon,
                csvcontent:csvcontent,
                transporter_code:localStorage.getItem("transportercode"),
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username"),
				userid:localStorage.getItem("userid")
			}
			// console.log("parameters ", parameters)
	
			redirectURL.post("/dashboard/verifymllrso",{csvcontent:this.state.csvcontent})
			.then((response) => {
				// console.log("resposnedata ", response.data)
				if(response.data.status  == "nodata")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid data uploaded",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Uploaded Vins are not rake assigned",
						basicType:"danger"
					});
				}
				else if(response.data.status  == "success")
				{				
					redirectURL.post("/dashboard/mllrsoupdate", parameters)
					.then((response) => {
						// console.log("Respone ", response.data)
						var rec = response.data;
						$("#uploadFile").val("")
						if(rec.notvalid.length == 0)
						{
							$("#rso_reached").val("");
							this.setState({
								show: true, 
								basicType:'success', 
								basicTitle:"Successfully updated.",
								uploadDivWidth:'0%',
								sliderRso:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:'',
								csvcontent:""
							});
							var parameters = {
								startdate:this.state.startDate,
								enddate:this.state.endDate,
								refno:this.state.refno
							}
							this.onLoadRakePlanningData(parameters)
						}
						else if(rec.notvalid.length > 0)
						{
							this.setState({
								show: true, 
								basicType:'danger', 
								basicTitle:"In Transit RSO not exists. ",
								uploadDivWidth:'0%',
								sliderRso:'',
								overly:'show-n',
								uploadFile:'',
								loadshow:'show-n',
								file:''
							});
						}
						
						else{
							
							
							
						}
						
					})
				}
				else
				{
					this.setState({
						show:true,
						basicTitle:"Failed to update data",
						basicType:"danger"
					});
				}
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Please select data and time",
				basicType:"danger"
			});
		}
		
	}
	
	toggle() {

        this.setState(prevState => ({

            dropdownOpen: !prevState.dropdownOpen

        }));

	}
	
	toggleAsset() {

        this.setState(prevState => ({

            dropdownAssetOpen: !prevState.dropdownAssetOpen

        }));

	}
	transporters()
	{
		let items = [];
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.transporter_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	loadingofficers()
	{
		let items = [];
		try{
			if(this.state.unloadofficers.length > 0)
			{
				this.state.unloadofficers.map((item) =>{
					items.push({"value":item.load_officer_code,"label":item.load_officer_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	
	onChangeBA(batransporter){
		this.setState(
			{ batransporter },
			() => console.log(`Option selected:`, this.state.batransporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeLoadingOfficer(loading_officer){
		this.setState(
			{ loading_officer },
			() => console.log(`Option selected:`, this.state.loading_officer)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	
	onClickGPSDeviceRetrival(){
		this.setState({
			sliderDeviceRetrival:'slider-translate',
			overly:'show-m',
		})
	}

	formDeviceRetrival(event){
		event.preventDefault();
		var retrivalon = $("#retrivalon").val();
		var retrived = ""
		$(".retrivecls").each(function(){
			if($(this).is(":checked") == true)
			{
				retrived = $(this).val();
			}
		})
		if(this.state.gpsdevice != "")
		{
			var gpsdeviceid = this.state.gpsdevice.value
		}
		else{
			var gpsdeviceid = ""
		}
		var parameters = {
			retrivalon:retrivalon,
			retrived:retrived,
			gpsdeviceid:gpsdeviceid,
			email:localStorage.getItem("email"),
			username:localStorage.getItem("username"),
			userid:localStorage.getItem("userid")
		}
		console.log("parameters ", parameters)

					
		redirectURL.post("/dashboard/mllretrived", parameters)
		.then((response) => {
			// console.log("Respone ", response.data)
			var rec = response.data;
			if(rec.status == "success")
			{
				document.getElementById("retrivalon").value = "";
				this.setState({
					show: true, 
					basicType:'success', 
					basicTitle:"Successfully updated.",
					sliderDeviceRetrival:'',
					overly:'show-n',
					loadshow:'show-n',
					gpsdevice:""
				});
				$(".retrivecls").each(function(){
					if($(this).is(":checked") == true)
					{
						retrived = $(this).prop("checked", false);
					}
				})

			}
			else
			{
				this.setState({
					show: true, 
					basicType:'danger', 
					basicTitle:"Failed to update. ",
					sliderDeviceRetrival:'',
					overly:'show-n',
					loadshow:'show-n'
				});
			}
			
			
		})
			
	}

	
    checkRadioEvent = (event) => {
        var boolValue = event.target.value;

        if (typeof event.target.value == "string" && event.target.name != "env_show") {
            boolValue = (event.target.value.toLowerCase() === 'true');
            
        }

        if ( event.target.name == "is_group_header") {
            let displayGroupHeader = (boolValue) ? "show-m" : "show-n";
            this.setState({groupheader:displayGroupHeader});
        }
        
    	let name = event.target.name;
		//let value = event.target.value;
        this.setState({[name]:boolValue});
        if (this.state.updateData) {
            let newUpdateData = this.state.updateData;
            newUpdateData[name] = boolValue;
            this.setState({updateData:newUpdateData});
        }
    }
	

	onClickShowMapRoute(rownode)
	{
		this.setState({
			loadshow:'show-m'
		})
		// console.log("Rownode ", rownode)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		
		var truck_no = rownode.data.truck_no;
		var status = parseInt(rownode.data.status);
		if(status <= 12)
		{
			var cdate = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
		}
		else
		{
			var cdate = moment.parseZone(rownode.data.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss");
		}
		if(rownode.data.parking_in_time != "")
		{
			var fdate = moment.parseZone(rownode.data.parking_in_time).format("YYYY-MM-DD HH:mm:ss");
		}
		else
		{
			var fdate = "";
		}
		var parameters = {
			truck_no: truck_no,
			from_date: fdate,
			to_date: cdate,
			consignment_code: rownode.data.consignment_code,
		}
		redirectURL.post("/dashboard/mllmaproute",parameters)
		.then((response) => {
			console.log("response ", response.data)
			// console.log("records ", response.data.records)
			if(response.data.status == "success")
			{
				var mp = response.data;
				
				// var phases = mp.phase_details;
				var mapfirstmile =[]
				var mapmiddlemile =[]
				var maplastmilerso =[]
				var maplastmiledealr =[]
				var breaks = []
				/*if(phases.length > 0)
				{
					phases.map((item) => {
						if(item.breaks.length > 0)
						{
							var allbreaks = item.breaks;
							allbreaks.map((brk) => {
								breaks.push(brk);
							})
						}
						if(item.set == "1")
						{
							mapfirstmile.push(item)
						}
						if(item.set == "2")
						{
							mapmiddlemile.push(item)
						}
						if(item.set == "3" && parseInt(item.directly_to_dealer) == 0)
						{
							maplastmilerso.push(item)
						}
						
						if(item.set == "3" && parseInt(item.directly_to_dealer) == 1)
						{
							maplastmiledealr.push(item)
						}
						try{
							if(item.set == "4")
							{
								maplastmiledealr.push(item)
							}
						}
						catch(e){

						}
						
					})
				}*/
				// console.log("breaks ", breaks)
				var mpdata = {}
				mpdata.coords = mp.coords;
				mpdata.route_details = mp.route_details
				mpdata.additional_details = mp.additional_details
				mpdata.breaks = breaks
				this.setState({
					overly:'show-m',
					loadshow:'show-n',
					sliderRouteTranslate:"slider-translate-98p",
					mapinfo:mpdata,
					routefor:rownode.data.vehicle_id,
					mapfirstmile:mapfirstmile,
					mapmiddlemile:mapmiddlemile,
					maplastmilerso:maplastmilerso,
					maplastmiledealr:maplastmiledealr
				})
			}
			else
			{
				this.setState({
					loadshow:'show-n',
					show1:true,
					basicTitle1:"No data found",
					basicType1:"danger"
				})
			}
		})
		
		
	}

	
	onClickDealerArrival(){
		this.setState({
			sliderDealerArrival:'slider-translate',
			overly:"show-m"
		});
	}
	
	formDealerData(event){
		event.preventDefault();
		var dealer_reached = $("#dealer_reached").val();
		var csvcontent = this.state.csvcontent;
		var dealer_reached = document.getElementById("dealer_reached").value;
		if(dealer_reached != "" && dealer_reached != "__-__-____ __:__")
		{
			var parameters = {
				dealer_reached_on:dealer_reached,
				csvcontent:csvcontent,
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username"),
				userid:localStorage.getItem("userid")
			}
			// console.log("parameters ", parameters)

			redirectURL.post("/dashboard/verifymlldealer",{csvcontent:this.state.csvcontent})
			.then(async (response) => {
				console.log("resposnedata ", response.data)
				if(response.data.status == "empty")
				{
					this.setState({
						show:true,
						basicTitle:"No Data found in uploaded file",
						basicType:"danger"
					});
				}
				else if(response.data.status == "notexists")
				{
					this.setState({
						show:true,
						basicTitle:"Invalid Vins",
						basicType:"danger"
					});
				}
				else
				{
					var recs = response.data.records;
					var vehicles = this.state.vehicles;
					if(recs.length > 0)
					{
						// var notacceptvins =await recs.filter(f =>
						// 	!vehicles.some(d => d.vin_no == f.vin_no)
						// );
						// if(notacceptvins.length > 0)
						// {
						// 	this.setState({
						// 		show:true,
						// 		basicTitle:"Uploaded vehicle ids are not exists",
						// 		basicType:"danger"
						// 	});
						// }
						// else
						// {
							redirectURL.post("/dashboard/mlldealerupdate", parameters)
							.then((response) => {
								// console.log("Respone ", response.data)
								var rec = response.data;
								$("#uploadFile").val("")
								
								$("#dealer_reached").val("");
								this.setState({
									show: true, 
									basicType:'success', 
									basicTitle:"Successfully updated.",
									uploadDivWidth:'0%',
									sliderDealerArrival:'',
									overly:'show-n',
									uploadFile:'',
									loadshow:'show-n',
									file:'',
									csvcontent:""
								});
								
							})
						// }
					}
					else
					{
						this.setState({
							show:true,
							basicTitle:"No Data found in uploaded file",
							basicType:"danger"
						});
					}
				}
				
				
			})
		}
		else
			{
				this.setState({
					show:true,
					basicTitle:"Please select date and time",
					basicType:"danger"
				});
			}
	}


	

	onClickShowETALegs = async(params) =>{

		console.log("Rownode ", params)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		await this.setState({
						
			detailCellRendererParams:{
				suppressRefresh: true,
				detailGridOptions: {
					headerHeight:50,
					columnDefs: [
						{
							headerName:"Source",
							width:450,
							resizable:true,
							field:"source_station",
						},
						{
							headerName:"Destination",
							width:200,
							resizable:true,
							field:"destination_station",
						},
						{
							headerName:"Recent Location Status",
							width:400,
							resizable:true,
							field:"last_destination_report",
						},	
						{
							headerName:"Destination ETA",
							width:150,
							resizable:true,
							field:"destinatiion_eta",
							valueGetter:function(params){
								try{
									if(params.data.destinatiion_eta != "" && params.data.destinatiion_eta != undefined)
									{
										return getHyphenDDMMMYYYYHHMM(params.data.destinatiion_eta);
									}
									else{
										return "";
									}
								}
								catch(e){}
							}
						},
					],
					overlayNoRowsTemplate: 'No rows to show',						
				},
				getDetailRowData:async function(param) {
					param.successCallback([]);
					// console.log("Step 4 ",param)
					// console.log("reqparams",reqparams);
					await redirectURL.post("/dashboard/getFnrTransitLogs",{
						fnr_no : param.data.fnr_no
					}).then(async (response) =>{
						//console.log("Step 1 ",response.data)
						// console.log(response.data.data);
						var comments=response.data;
						param.successCallback(comments);
						
						
						//console.log("Step 2 ",response.data)
						
					}).catch(function(error){
						console.log(error);
					})
					 
				},
			}
		});
		
		//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
		if(params.column.colDef.field == 'vehicle_id')
		{
			params.node.setExpanded(!params.node.expanded);
			
		}
		else{

			params.node.setExpanded(false);
		}
		
	}
	changeTOSFileHandler = async (e) => {
		
		this.setState({
			tosfile:e.target.files[0]
		});
		
		
	}
    render(){
		
		
		var columnwithDefs = [
			{
				headerName:"",
				field:"consignment_code",
				width:50,
				resizable:true,
				pinned:"left",
				cellRendererSelector:function(params){
					var rendComponent = {
						component: 'showroute'
					};
					return rendComponent
				
				},
			},
			{
				headerName:"",
				field:"vehicle_id",
				width:50,
				resizable:true,
				pinned:"left",
				cellRendererSelector:function(params){
					var rendComponent = {
						component: 'showetalegs'
					};
					return rendComponent
				
				},
			},
			{
				headerName:"Batch No",
				field:"consignment_code",
				width:150,
				resizable:true,
				pinned:"left"
			},
			{
                headerName:Constant.COL_SHIPMENT_DOC_NO,
                field:"shipment_id",
                width:120,
            },
            {
                headerName:Constant.COL_SHIPMENT_DATE,
                field:"shipment_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            {
                headerName:Constant.COL_PARKING_ENTRY_NO,
                field:"parking_id",
                width:120
            },
            
            {
                headerName:Constant.COL_PARKING_DATE,
                field:"parking_in_time",
                width:140,
                valueGetter:function(params){
                    try{
                        if(params.data.parking_in_time != "" && params.data.parking_in_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){}
                }
            },
            
            {
                headerName:Constant.COL_TRUCK_NO,
                field:"truck_no",
                width:120
            },
            {
                headerName:Constant.COL_TRUCK_TYPE,
                field:"vehicle_mode",
                width:80
            },
            {
                headerName:Constant.COL_SHIPMENT_MODE,
                field:"shipment_mode",
                width:80
            },
			{
                headerName:Constant.COL_DELIVERY_NO,
                field:"delivery_no",
                width:120,
            },
            {
                headerName:Constant.COL_SALE_ORDER,
                field:"sales_order",
                width:120,
            },
            
            {
                headerName:Constant.COL_ITEM_NO,
                field:"item_no",
                width:120,
            },
            {
                headerName:Constant.COL_ITEM_DESC,
                field:"item_desc",
                width:200,
            },
            {
                headerName:Constant.COL_ITEM_QTY,
                field:"item_quantity",
                width:120,
            },
            
            {
                headerName:Constant.COL_CUSTOMER_NAME,
                field:"consignee_name",
                width:200,
            },
            {
                headerName:Constant.COL_CUSTOMER_CODE,
                field:"consignee_code",
                width:120,
            },
            {
                headerName:Constant.COL_CUSTOMER_CITY,
                field:"consignee_city",
                width:140,
            },
            {
                headerName:"Duration since available at loading stockyard",
                field:"consignee_city",
                width:200,
                pinned:"right",
                valueGetter:function(params){
                    try{
                        if(params.data.loading_yard_reached_on != undefined && params.data.loading_yard_reached_on != "")
                        {
                            var ste = params.data.loading_yard_reached_on;
                            var stsec = moment.parseZone(ste).format("x");
                            var etsec = moment.parseZone().utcOffset("+00:00")._d;
                            etsec = moment.parseZone(etsec).format("YYYY-MM-DD HH:mm:ss");
                            etsec = moment.parseZone(etsec).format("x");
                            var diff = (parseInt(etsec)-parseInt(stsec))/1000;
                            var timespent = secondsToDhms(diff);
                            return timespent;
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                },
            },
		]
        const { calview } = this.state;
        const events = [
            {
                start: '2015-07-20',
                end: '2015-07-02',
                eventClasses: 'optionalEvent',
                title: 'test event',
                description: 'This is a test description of an event',
            },
            {
                start: '2015-07-19',
                end: '2015-07-25',
                title: 'test event',
                description: 'This is a test description of an event',
                data: 'you can add what ever random data you may want to use later',
            },
        ];
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}

            	<div className="row">
            		<div className="col-xl-12 col-lg-12 pl-0">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Stock at Loading Terminals</span>
                                    <button className={"float-right btn btn-danger f14 "} style={{marginRight:"10px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className={"float-right btn btn-info f14 "} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
								</h5>
				   			</div>
				   			<div className="card-body row">

                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											masterDetail={true}
											detailCellRendererParams={this.state.detailCellRendererParams}
									
										/>

                                    </div>
                                </div>
                                
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				
				<div className={"slide-r "+(this.state.sliderRakeTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Rake Schedule
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRakeSchedule.bind(this)}>
                                
                            {/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Loading Yard *</label>
                                
                                <Select 
                                placeholder={"Select Loading Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.sourceopt}
                                options={this.selectsources()} />  
                            </div> */}
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="">Rake No *</label>
								{/* <CreatableSelect
									isClearable
									onChange={this.handleChange}
									value={this.state.rake_no}
									style={{padding:"0"}}
									className="col-sm-12" 
									// onInputChange={this.handleInputChange}
									options={this.state.rakeList}
								/> */}
								<Select 
                                placeholder={"Select Rake No"}
                                closeMenuOnSelect={true}
                                onChange={this.handleChange} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px",padding:"0px"}}
                                // value={this.state.rake_no}
                                options={this.rakeList()} />
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="">FNR No </label>
								<input 
									type="text" 
									name="fnr_no"
									value={this.state.fnr_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            {/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
								closeMenuOnSelect={true}
								isMulti={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdeviceids}
                                options={this.gpsdevices()} />  
                            </div> */}
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="">Dispatch Date and Time *</label>
							    <input type="text" placeholder="Dispatch Date and Time" id="dest_yard_reachedon" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination Yard *</label>
                                
                                <Select 
                                placeholder={"Select Destination Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destopt}
                                options={this.selectdestinations()} />  
                            </div> */}
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Unloading Officer:</label>
								<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
								<Select 
									placeholder={"Select Unloading Officer"}
									closeMenuOnSelect={true}
									onChange={this.onChangeLoadingOfficer.bind(this)} 
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.loading_officer}
									options={this.loadingofficers()} 
								/> 
								</div>
							</div> */}
						
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_mll_rake_schedule_new.csv')} target="_blank">Sample Template</a>
							
							</div>
						</form>

                        </div>
					</div>
				</div>
				
				
				<div className={"slide-r "+(this.state.sliderUploadTOSTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
							Upload Dummy Truck Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUploadTOS.bind(this)}>
                            
                            <div className="form-group mt-20p">
								<label className="">Upload File *</label> 
								<input type="file" name="uploadFile" onChange={this.changeTOSFileHandler}  className="form-control" required  />
							</div>
                            
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_tos.XLSX')} target="_blank">Sample Template</a>
								<p style={{color:"#ff0000",fontSize:"11px"}} className="mt-20p"><b>Note:</b> Following fields should not be empty in uploaded file.</p>
								<p style={{color:"#ff0000",fontSize:"11px"}}>
									Fields: <b>Plat No, TOS NO, Dealer, VIN</b>
								</p>
							</div>
						</form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<div className={"slide-r "+(this.state.sliderForceDestination)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Move To Destination Yard
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceMoveDestinationYardHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination Yard Reached On</label>
							    <input type="text" placeholder="Destination Yard Reached On " id="dest_yard_reached" name="dest_yard_reachedon" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeDestFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_destination_yard.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderForceLastMile)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Last Mile Transport Batch
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formLastMileData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Destination *</label>
                                
                                <Select 
                                placeholder={"Select Destination"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeLastMilDestinationItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.lastmiledestination}
                                options={[
									{"value":"RSO", "label":"RSO"},
									{"value":"Dealer", "label":"Dealer"}
								]} />  
                            </div>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">{this.state.desttype} *</label>
                                
                                <Select 
                                placeholder={this.state.desttype}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDestType.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.destypeopt}
                                options={this.state.destopts} />  
                            </div>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Mode *</label>
                                
                                <Select 
                                placeholder={"Select Mode"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeModeType.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.modetype}
                                options={[
									{"value":"Trailer","label":"Trailer"},
									{"value":"Convoy","label":"Convoy"},
								]} />  
                            </div>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rake_destination_yard_vins_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Update RSO Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRSOData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">RSO Reached On</label>
							    <input type="text" placeholder="RSO Reached On " id="rso_reached" name="rso_reached" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_rso_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderLoadingYardTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Consignments
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
								<MLLConsignmentsChildGrid refno={this.state.refno} />
                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderDeviceRetrival)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           GPS Device Retrival
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDeviceRetrival.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select GPS Device *</label>                                
                                <Select 
                                placeholder={"Select GPS Device"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeDeviceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.gpsdevice}
                                options={this.gpsdevices()} />  
                            </div>
							<div className="form-group mt-20p">
								<label className="">Is Retrived</label> 
								<input 
									type="radio" 
									name="retrived" 
									id="retrivedyes"   
									className="retrivecls"
									value="Yes"
									required  /> Yes

								<input 
									type="radio" 
									name="retrived" 
									id="retrivedno"   
									className="retrivecls"
									value="No"
									required  /> No
							</div>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Retrival On</label>
								<input 
									type="text"
									placeholder="Retrival On " 
									id="retrivalon" 
									name="retrivalon" 
									className="datetimepicker_mask form-control" required />
							</div>
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

                        </div>
					</div>
				</div>


				<div className={"slide-r "+(this.state.sliderDealerArrival)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Customer Arrival
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDealerData.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Customer Reached On</label>
							    <input type="text" placeholder="Customer Reached On " id="dealer_reached" name="dealer_reached" className="datetimepicker_mask form-control" required />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="uploadFile" onChange={this.changeLastMileFileHandler}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_mll_dealer_new.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                        </div>
					</div>
				</div>


				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route {this.state.routefor}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								{console.log("this.state.mapinfo ", this.state.mapinfo)}
								<DrawMap 
									context={this}  
									mapFor={"mllrailconsignments"} 
									mapinfo={this.state.mapinfo} 
								/>
							
							</div>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0 mt-20p">
								<div className="col-xl-4 col-lg-4">
									<div className="card-body blc-1">
										<h4 className="map-h pl-10px"><i className="icofont icofont-train-line"></i> First Mile</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
												
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.mapfirstmile.length > 0)?
												<td className="map-htxt"> {this.state.mapfirstmile[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
								<div className="col-xl-4 col-lg-4">
									<div className="card-body blc-2">
										<h4 className="map-h pl-10px"><i className="icofont icofont-train-line"></i> Middle Mile</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.mapmiddlemile.length > 0)?
												<td className="map-htxt"> {this.state.mapmiddlemile[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
								
								<div className="col-xl-4 col-lg-4">
									<div className="card-body blc-3">
										<h4 className="map-h pl-10px"><i className="icofont icofont-user-alt-2"></i> Last Mile (Customer)</h4>
										<table className="table table-border map-tbl">
											
											<tr>
												<td>Truck/GPS Device</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].device_id}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Mode</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].mode_type}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Start Date & Time</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].from_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>End Date & Time</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].to_date}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Avg. Speed (km/h)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].avg_speed_kmph}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Break Time (hrs)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].total_break_time_mins}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
											<tr>
												<td>Total Distance (Kms)</td>
												{(this.state.maplastmiledealr.length > 0)?
												<td className="map-htxt"> {this.state.maplastmiledealr[0].distance}</td>
												:
												<td className="map-htxt"> </td>
												}
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				:""}


            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$("#retrivalon").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#dealer_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#dest_yard_reached').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#dest_yard_reachedon').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#rso_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$("#retrivalon").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	
	$("#dealer_reached").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

