import './dieselPricesTable.css';
import React, { useEffect, useState } from 'react';
var redirectURL = require('../redirectURL');

const cityNames = [
    "Alwar", "Dharuhera", "Aurangabad", "Kolkata", "Unnao",
    "Hyderabad", "Naharlagun", "Mysore", "Cuttack", "Shillong", "Daman"
];

const PAGE_SIZE = 10; // Display 20 records per page

const DieselPricesCityWise = ({ currentDate, endDate, baseDate, title, onLoadingComplete }) => {
    const [formattedData, setFormattedData] = useState({});
    const [cities, setCities] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const reqparams = {
                current_diesel_date_start: currentDate,
                current_diesel_date_end: endDate,
                base_diesel_date: baseDate,
                fuel_type: ["Diesel"],
                city_names: cityNames
            };

            try {
                const response = await redirectURL.post("/consignments/getDieselPrices", reqparams);
                if (Array.isArray(response.data)) {
                    const processedData = processData(response.data);
                    setFormattedData(processedData);
                    setCities(Array.from(new Set(response.data.map(item => item.city))));
                    setTotalRecords(Object.keys(processedData).length);
                } else {
                    console.error("Expected an array but received:", response.data);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                // Ensure loader hides once data fetching is complete or if there's an error
                setShowTable(true)
                onLoadingComplete();
            }
        };

        fetchData();
    }, [currentDate, endDate, baseDate, title, onLoadingComplete]);

    const processData = (data) => {
        const processedData = {};
        data.forEach((record) => {
            const { city, base_diesel_fuel_price, current_fuel_price, current_diesel_date } = record;
            const formattedDate = new Date(current_diesel_date).toLocaleDateString();

            if (!processedData[formattedDate]) {
                processedData[formattedDate] = {};
            }

            const previousData = Object.values(processedData)
                .map((dateData) => dateData[city])
                .filter(Boolean)
                .pop();

            const priceHike = current_fuel_price - base_diesel_fuel_price;
            let arrowIndicator = '→';
            let arrowValue = '0';
            if (previousData) {
                const diff = current_fuel_price - previousData.currentPrice;
                if (diff > 0) {
                    arrowIndicator = '↑';
                    arrowValue = `+${diff.toFixed(2)}`;
                } else if (diff < 0) {
                    arrowIndicator = '↓';
                    arrowValue = `-${Math.abs(diff).toFixed(2)}`;
                }
            }

            const percentChange = ((current_fuel_price - base_diesel_fuel_price) / base_diesel_fuel_price) * 100;

            processedData[formattedDate][city] = {
                currentPrice: current_fuel_price,
                priceHike: priceHike.toFixed(2),
                arrowIndicator,
                arrowValue,
                percentChange: percentChange.toFixed(2),
                basePrice: base_diesel_fuel_price,
            };
        });

        return processedData;
    };

    const paginatedData = Object.keys(formattedData)
        .slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE)
        .reduce((acc, key) => ({ ...acc, [key]: formattedData[key] }), {});

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 0));
    };

    return (
        showTable ? (
            <div className="diesel-prices-table-wrapper">
                <h3 className="chart-title">{title}</h3>
                <div className="diesel-prices-scroll">
                    <table className="diesel-prices-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                {cities.map((city) => (
                                    <th colSpan="4" key={city} className="city-header">{city}</th>
                                ))}
                            </tr>
                            <tr>
                                <th></th>
                                {cities.map((city) => (
                                    <React.Fragment key={city}>
                                        <th>Diesel Price</th>
                                        <th>Price Hike</th>
                                        <th>% Change</th>
                                        <th>Change</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(paginatedData).map((date, rowIndex) => (
                                <tr key={date} className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
                                    <td>{date}</td>
                                    {cities.map((city) => {
                                        const cityData = paginatedData[date][city] || {
                                            currentPrice: 'N/A',
                                            priceHike: 'N/A',
                                            percentChange: 'N/A',
                                            arrowIndicator: '→',
                                            arrowValue: '0'
                                        };
                                        return (
                                            <React.Fragment key={city}>
                                                <td>{cityData.currentPrice !== 'N/A' ? cityData.currentPrice.toFixed(2) : 'N/A'}</td>
                                                <td>{cityData.priceHike !== 'N/A' ? cityData.priceHike : 'N/A'}</td>
                                                <td className={cityData.percentChange < 0 ? 'change-negative' : 'change-positive'}>
                                                    {cityData.percentChange !== 'N/A' ? `${cityData.percentChange}%` : 'N/A'}
                                                </td>
                                                <td>{cityData.arrowIndicator} {cityData.arrowValue}</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination-footer">
                    <div className="total-count">
                        Total Records: {totalRecords}
                    </div>
                    {totalRecords > 10 ?
                        <div className="pagination-controls">
                            <button onClick={handlePreviousPage} disabled={currentPage === 0} className="pagination-button">
                                &lt;
                            </button>
                            <button onClick={handleNextPage} disabled={currentPage >= Math.ceil(totalRecords / PAGE_SIZE) - 1} className="pagination-button">
                                &gt;
                            </button>
                        </div>
                        : ""}
                </div>
            </div>
        ) : null
    );
};

export default DieselPricesCityWise;
