import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import './dieselPricesChart.css';
var redirectURL = require('../redirectURL');

const breweries = [
    "Alwar", "Dharuhera", "Aurangabad", "Kolkata", "Unnao",
    "Hyderabad", "Naharlagun", "Mysore", "Cuttack", "Shillong", "Daman"
];

const DieselPricesChart = ({ baseDate, currentDate, onLoadingComplete }) => {
    const [priceData, setPriceData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const reqparams = {
                current_diesel_date_start: currentDate,
                base_diesel_date: baseDate,
                fuel_type: ["Diesel"],
                city_names: breweries
            };

            try {
                const response = await redirectURL.post("/consignments/getDieselPrices", reqparams);
                if (Array.isArray(response.data)) {
                    setPriceData(response.data);
                } else {
                    console.error("Expected an array but received:", response.data);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                onLoadingComplete();
            }
        };

        fetchData();
    }, [baseDate, currentDate, onLoadingComplete]);

    const cities = priceData.map(item => item.city);
    const basePrices = priceData.map(item => item.base_diesel_fuel_price || 0);
    const currentPrices = priceData.map(item => item.current_fuel_price || 0);
    const percentageChanges = priceData.map(item => item.changes || 0);

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: 'Diesel Prices Comparison by City'
        },
        xAxis: {
            categories: cities,
            title: {
                text: 'Cities'
            }
        },
        yAxis: [
            {
                title: {
                    text: 'Diesel Price'
                },
                opposite: false // Aligns this axis on the left
            },
            {
                title: {
                    text: '% Change'
                },
                opposite: true, // Aligns this axis on the right
                labels: {
                    format: '{value}%'
                }
            }
        ],
        tooltip: {
            shared: true,
            formatter: function () {
                if (this.points && this.points.length > 0) {
                    const basePrice = this.points[0] && this.points[0].y != null ? this.points[0].y : 'N/A';
                    const currentPrice = this.points[1] && this.points[1].y != null ? this.points[1].y : 'N/A';
                    const percentChange = this.points[2] && this.points[2].y != null ? this.points[2].y : 'N/A';

                    return `
                        <b>${this.x}</b><br/>
                        Base Price: ${basePrice}<br/>
                        Current Price: ${currentPrice}<br/>
                        % Change: ${percentChange}%
                    `;
                }
                return `<b>${this.x}</b><br/>No data available`;
            }
        },
        plotOptions: {
            column: {
                borderRadius: 5,
                pointPadding: 0.2,
                groupPadding: 0.1,
                dataLabels: {
                    enabled: true,
                    format: '{y}'
                }
            }
        },
        series: [
            {
                name: 'Base Diesel Price',
                type: 'column',
                data: basePrices,
                color: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, '#4facfe'],  // Lighter color at the top
                        [1, '#0575e6']   // Darker color at the bottom
                    ]
                }
            },
            {
                name: 'Current Diesel Price',
                type: 'column',
                data: currentPrices,
                color: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, '#ff6a6a'],  // Lighter color at the top
                        [1, '#ff3d3d']   // Darker color at the bottom
                    ]
                }
            },
            {
                name: '% Change',
                type: 'line',
                yAxis: 1,
                data: percentageChanges,
                color: '#003366',
                tooltip: {
                    valueSuffix: '%'
                },
                marker: {
                    enabled: true,
                    radius: 4,
                    symbol: 'circle'
                }
            }
        ]
    };

    return (
        <div className="chart-container">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default DieselPricesChart;
