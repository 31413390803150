import React from "react";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsReact from "highcharts-react-official";

drilldown(Highcharts);

class DrillDownBarChartAlpha extends React.Component {
  constructor(props) {
    super(props);
    this.allowChartUpdate = true;
    this.state = { seriesData: [], chart_hit_count:"" };
  }

  componentDidMount() {
    const chart = this.chartComponent.chart;
    this.setState({
      seriesData: this.props.seriesData,
      chart_hit_count: this.props.chart_hit_count,
    });
    console.log(this.props.seriesData,this.props.chart_hit_count,"seriesData--did")
  }

  componentWillReceiveProps(newProps) {
    const chart = this.chartComponent.chart;
      this.setState({
        seriesData: newProps.seriesData,
        chart_hit_count: this.props.chart_hit_count,
      });      
      console.log(newProps.seriesData,newProps.chart_hit_count,"seriesData--will")
  }

  categoryClicked() {
    this.allowChartUpdate = false;
    this.setState({
      value: 2,
    });
  }

  render() {
    return (
      <HighchartsReact
        allowChartUpdate={this.allowChartUpdate}
        ref={(chartComponent) => (this.chartComponent = chartComponent)}
        highcharts={Highcharts}
        options={{
          chart: {
            type: "column",
          },
          series: {
            events: {
              click: (e) => {
                this.categoryClicked(e);
              },
            },
            data: this.state.seriesData,
          },
          drilldown: {
            series: this.props.drilldowndata,
          },
          xAxis: {
            type: "category",
            // categories:this.props.seriesCategories
          },
          yAxis: {
            title: {
              text: "Plant Wise Count",
            },
          },
          title: {
            text: this.props.chartTitle,
          },
          legend: {
            enabled: true,
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            pointWidth: "40px",
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            },
            colorByPoint: true,
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y}'
              },
              cursor: 'pointer',
              point: {

              }
            },
          },
        }}
      />
    );
  }
}

export default DrillDownBarChartAlpha;