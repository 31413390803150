import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';

drilldown(Highcharts);
export default class LineChartComponent extends Component {

    constructor(props) {
        super(props);
            this.afterChartCreated = this.afterChartCreated.bind(this);

        this.state = {			
            series : {},
            drilldownseries : {}
		}
    }
    componentDidMount (){
        // console.log(this.props.drilldownseries,"props")
        this.setState({
            series: this.props.series,
            title: this.props.title,
            subtitle: this.props.subtitle,
            categories: this.props.categories,
            yaxistitle: this.props.yaxistitle,
        })

    }
    componentWillReceiveProps (newprops){
        if(newprops.drilldownseries.series !=this.state.drilldownseries)
        {
            // console.log(newprops.drilldownseries.series,"newprops")
            this.setState({
                series: newprops.series,
                title: newprops.title,
                subtitle: newprops.subtitle,
                categories: newprops.categories,
                yaxistitle: newprops.yaxistitle,
            })
            for (var i = 0; i < Highcharts.charts.length; i++) {
                if (Highcharts.charts[i] !== undefined) {
                  Highcharts.charts[i].reflow();
                }
              }

        }
        
    }
    afterChartCreated(chart) {
        // this.internalChart = chart;
        // this.forceUpdate();
    }
    render(){
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions =  {
            chart: {
                type: 'spline'
            },
            title: {
                text: this.state.title
            },
            subtitle: {
                text: this.state.subtitle
            },
            xAxis: {
                categories:this.state.categories,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.state.yaxistitle
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:1f} </b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: this.state.series,
            
        }
                // console.log(this.props.chartType ,"this.props.chartTyp");
        highchartoptions.chart= {type: "spline",backgroundColor:'rgba(0,0,0,0)',style: {
            color: "#fff"
        }};

        return ( 
            <div className='col-sm-12'>
                {/* {this.state.drilldownseries !=undefined && this.state.drilldownseries !="" ? */}
               <HighchartsReact containerProps={{ style: { width: '100%', height: '450px' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} callback={this.afterChartCreated} allowChartUpdate={true} immutable={true}/> 
               {/* : ""} */}
            </div>
        );
    }
}