import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DailyRun from './dailyrunningdashboard';

var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');

export default class DailyRunningDashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state={
            locations:[],
            transporters:[],
            location:'',
            transporter:'',
            startDate:'',
            endDate:'',
            modules:AllModules,
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true,
            },
            rowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            columnwithDefs:[{
                headerName:"Plant Name",

            }],
            showexclude:0
        }
    }
    componentDidMount(){
        var edate = moment.parseZone().format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
        //var sdate = "2020-05-01";
        var dept_code='SNDG';
        if(this.props.match.path == '/dailyrunningdashboard')
        {
            dept_code='SNDG';
        }
        if(this.props.match.path == '/prtdailyrunningdashboard')
        {
            dept_code='LOG-PRT';
        }
        
        var preqparams = {
            dept_code:dept_code
        }
        this.setState({
            dept_code:dept_code,
            startDate:sdate,
            endDate:edate
        })
      
    }
    componentWillReceiveProps(newprops){    
        if(newprops.showexclude != this.state.showexclude)
        {
            this.setState({
                showexclude:newprops.showexclude
            });
        }
        else{
            this.setState({
                showexclude:0
            });
        }
    }
    
    onClickLoadDept(deptcode){
        this.setState({
            dept_code:deptcode
        })
    }
    
    render(){
        //console.log("this.state.locations ",this.state.locations)
        var taburl = "/snddashboardtemplate";
        var loadingurl='';
        var transiturl='';
        var transporturl='';
        var dailyurl = "/dailyrunningdashboard";
        if(this.state.dept_code == "SNDG")
        {
          dailyurl = "/dailyrunningdashboard";
          taburl="/snddashboardtemplate";
          loadingurl="/sndloadingdashboard";
          transiturl="/sndtransitanalytics";
          transporturl="/sndtransportdashboard";
        }
        if(this.state.dept_code == "LOG-TNP")
        {
          taburl="/tnpdashboardtemplate";
          loadingurl="/tnploadingdashboard";
          transiturl="/tnptransitanalytics";
          transporturl="/tnptransportdashboard";
        }
        if(this.state.dept_code == "LOG-PRT")
        {
            dailyurl = "/prtdailyrunningdashboard";
          taburl="/prtdashboardtemplate";
          loadingurl="/prtloadingdashboard";
          transiturl="/prttransitanalytics";
          transporturl="/prttransportdashboard";
        }
        if(this.state.dept_code == "LOG-SC")
        {
          taburl="/scdashboardtemplate";
        }
    

        return ( 
        		<div className="col-xl-12 col-lg-12">
                    <div className={"row col-xl-12 col-lg-12 "}>
                    <ul className="dashboard-page">
                            <li>
                            {(this.props.match.path == "/dailyrunningmanagement")?
                                    <a href={"/dashboardsummary"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                    <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                </a>
                        
                            :""}
                            {(this.state.dept_code == "SNDG" && this.props.match.path != "/dailyrunningmanagement")?
                                    <a href={"/snddashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                        <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                    </a>
                                :""}
                                {(this.state.dept_code == "LOG-PRT" && this.props.match.path != "/dailyrunningmanagement")?
                                <a href={"/prtdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                        <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                    </a>
                                :""}
                                {(this.state.dept_code == "LOG-TNP" && this.props.match.path != "/dailyrunningmanagement")?
                                <a href={"/tnpdashboardtemplate"} title="Performance Metrics" className="loadclickm" id="dashboard" onClick={this.onClickLoadDept.bind(this,'dashboard')}>
                                        <img src={require("../../assets/icons/performance.png")} className="dashboard-icon" />
                                    </a>
                                :""}
                            </li>
                            {/* {(localStorage.getItem("roles").indexOf("analytics") != -1)? */}
                            {(this.state.dept_code != 'LOG-SC')?
                            <li className="btn-group active">
                                <a href="javascript:;"  className="loadclickm active" id="load" onClick={this.onClickLoadDept.bind(this,'load')}>
                                <img src={require("../../assets/icons/kpi.png")} className="dashboard-icon" />
                                
                                <ul className="dashboard-sub" style={{"display":"none"}}>
                                     {(this.props.match.path == "/dailyrunningmanagement")?
                                    <li className="active">
                                        <a href="/dailyrunningmanagement">
                                            Daily Running
                                        </a>
                                    </li>
                                    :""}
                                    {((this.state.dept_code == 'SNDG' || this.state.dept_code == 'LOG-PRT') && this.props.match.path != "/dailyrunningmanagement")?
                                        <li className="active">
                                            <a href={dailyurl}>
                                                Daily Running
                                            </a>
                                        </li>
                                        :""}

                                        {(this.props.match.path == "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/loadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>
                
                                        :""}
                                        {(this.state.dept_code == "SNDG" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/sndloadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>
                
                                        :""}
                                        {(this.state.dept_code == "LOG-PRT" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/prtloadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>
                
                                        :""}
                                        {(this.state.dept_code == "LOG-TNP" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/tnploadingdashboard">
                                                Loading Dashboard
                                            </a>
                                        </li>
                
                                        :""}
                                      
                                      {(this.props.match.path == "/dailyrunningmanagement")?
                                        <li className="">
                                            <a href="/livetrucksinfo">
                                                Truck Location (Map)
                                            </a>
                                        </li>
                                    :""}

                                        {/* {(this.state.dept_code == 'SNDG' && this.props.match.path != "/dailyrunningmanagement")? */}
                                        {/* {(localStorage.getItem('roles') != '' && this.props.match.path != "/dailyrunningmanagement")?
                                            (localStorage.getItem('roles').indexOf("SITE_ADMIN") >= 0)?
                                            <li>
                                                <a href="/unloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                            </li>
                                            :""
                                            
                                        :""} */}

                                          <li>
                                                {(this.state.dept_code == "SNDG" && this.props.match.path != "/dailyrunningmanagement")?
                                                <a href="/sndunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                :""}
                                                {(this.state.dept_code == "LOG-PRT" && this.props.match.path != "/dailyrunningmanagement")?
                                                <a href="/prtunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                :""}
                                                {(this.state.dept_code == "LOG-TNP" && this.props.match.path != "/dailyrunningmanagement")?
                                                <a href="/tnpunloadingdashboard">
                                                    Unloading Dashboard
                                                </a>
                                                :""}
                                                
                                            </li>
                                          
                                        
                                        {(this.state.dept_code == "SNDG" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/sndtransitanalytics">
                                            Transit Analytics
                                            </a>
                                        </li>
                
                                        :""}
                                        {(this.state.dept_code == "LOG-PRT" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/prttransitanalytics">
                                            Transit Analytics
                                            </a>
                                        </li>
                
                                        :""}
                                        {(this.state.dept_code == "LOG-TNP" && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                            <a href="/tnptransitanalytics">
                                                Transit Analytics
                                            </a>
                                        </li>
                
                                        :""}

                
                                    {(this.state.dept_code == 'SNDG' && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                        <a href="/sndtransportdashboard">
                                            Transporter Daily
                                        </a>
                                        </li>
                                        :""}

                                          
                                        {
                                        (this.state.dept_code == 'SNDG' && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                        <a href="/sndgpsdashboard">
                                            GPS Dashboard
                                        </a>
                                        </li>
                                        :""}

                                        {
                                        (this.state.dept_code == 'LOG-PRT' && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                        <a href="/prtgpsdashboard">
                                            GPS Dashboard
                                        </a>
                                        </li>
                                        :""}

                                        {
                                        (this.state.dept_code == 'LOG-TNP' && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                        <a href="/tnpgpsdashboard">
                                            GPS Dashboard
                                        </a>
                                        </li>
                                        :""}

                                            

                                        {(this.state.dept_code == 'SNDG' && this.props.match.path != "/dailyrunningmanagement")?
                                        <li>
                                        <a href="/ontimedashboard">
                                        On TIme Performance
                                        </a>
                                        </li>
                                        :""}

                                    {(this.props.match.path == "/dailyrunningmanagement")?
                                        <li className="">
                                             <a href="/msilvesselschedule">
                                            chettinad Vessel Schedule
                                            </a>
                                        </li>
                                    :""}
                                    </ul>
                                </a>
                            </li>
                            :""}
                            {/* :""} */}
                        </ul>	
                    </div>
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px ml-18p whiteRow "}>		
                        <div className="col-xl-12 col-lg-12">		            	
                            <h5>
                                 <i className="icofont icofont-dashboard-web cus-i"></i> <span>Daily Running Dashboard</span>
                            
                            </h5>                    
                        </div>
	                    
                        <div className="card-body n-p-0 mt-10p">
                            <ul className="tabc-sm">
                                {(this.state.dept_code == 'SNDG')?
                                <li>
                                    <a href="javascript:;" className="loadclick active" id="SNDG" onClick={this.onClickLoadDept.bind(this,'SNDG')}>Sales and Dispatch</a>
                                </li>
                                :""}
                                {(this.state.dept_code == 'LOG-PRT')?
                                <li>
                                    <a href="javascript:;" className="loadclick active" id="LOG-PRT" >Spare Parts</a>
                                </li>
                                :""}
                                
                                {/* <li>
                                    <a href="javascript:;" className="loadclick" id="LOG-PRT" onClick={this.onClickLoadDept.bind(this,'LOG-PRT')}>Spare Parts</a>
                                </li>
                                <li>
                                    <a href="javascript:;" className="loadclick" id="LOG-TNP" onClick={this.onClickLoadDept.bind(this,'LOG-TNP')}>Train and Production</a>
                                </li> */}
                            </ul>
                            <div className=" col-xl-12 col-lg-12">
                                <DailyRun 
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                //locations={this.state.locations}
                                //transporters={this.state.transporters}
                                dept_code={this.state.dept_code}
                                showexclude={this.state.showexclude}
                                />
                            </div>
                        </div>
	                   
	                </div>
	           </div>
			);
		}
}


$(document).ready(function(){
    $(".loadclickm").on("mouseover",function(){
      var id = $(this).attr("id");
      $(".loadclickm").removeClass("active");
      $(".dashboard-sub").hide()
      if(id == "load")
      {
        $("#"+id).addClass("active");
        $(".dashboard-sub").show()
      }
  
    })
  
    $(".loadclickm").on("mouseout",function(){
      var id = $(this).attr("id");
      //$(".loadclickm").removeClass("active");
      $(".dashboard-sub").hide()
      // $("#"+id).addClass("active");
      // $("#"+id+">dashboard-sub").show()
  
    })
});










