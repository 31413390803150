import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import $ from "jquery";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';

import * as Datetime from 'react-datetime';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Map from './mapLocationComponent';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
}from 'react-accessible-accordion';

import { getHyphenYYYYMMDDHHMMSS} from '../common/utils';


export default class ForceCloseSideBar extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
			rowData: null,
			animateRows: true,
            reasonforceclose : "",
            
            selectedReason : "",
            displayReasonField : "show-n"

        };
       // this.showRelatedData()
       
    } 
    componentDidMount(){
        
        loadDateTimeScript()
        console.log("sidebar");
    }
    componentWillReceiveProps(){
        console.log()
    }
    selectReason(){
        
        try{
        let arr=[
            {value:"Empty Exited",label:"Empty Exited"},
            {value:"Shipment Cancelled",label:"Shipment Cancelled"},
            {value:"Others",label:"Others"},

        ];
        if(this.props.deptcode == "SNDG")
        {
            // arr.splice(4,0,{value:"TVP-B: Chennai Stockyard Consignment",label:"TVP-B: Chennai Stockyard Consignment"})
        }
        if(this.props.deptcode == "LOG-PRT")
        {
            arr.splice(4,0,{value:"Pass Through Courier - Direct Delivery Project",label:"Pass Through Courier - Direct Delivery Project"},{value:"Same GR - Multi Truck Dispatch",label:"Same GR - Multi Truck Dispatch"},
            {value:"North East Trans-shipment",label:"North East Trans-shipment"},)
        }
        return arr;
        }
        catch(e){

        }
        
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({reasonforceclose:value});
    }
    selecetedReason(params) {
        //console.log(params);
        try{
            this.setState({
                selectedReason : params.value
            });
            //console.log(params.value);
            if(params.value == "Others"){
                this.setState({
                    displayReasonField : "show-m"
                })
            }
            else{
                this.setState({
                    displayReasonField : "show-n"
                })
            }
        }catch(e){}

    }
    changeDateHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ deliveryDate: value });
        console.log("Select", event.target);
    }
    handleSubmit(){
        // var valuesArr = [this.state.reasonforceclose,this.state.selectedReason]
        // this.props.context.handleInput(valuesArr);

        const { deliveryDate, selectedReason, reasonforceclose } = this.state
        const toDate = $("#datetimepicker_mask").val();
        var valuesArr = [reasonforceclose, selectedReason, deliveryDate]
        this.props.context.handleInput(valuesArr);

    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());

        this.setState({
            deliveryDate: getHyphenYYYYMMDDHHMMSS(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    render(){
    
        return ( 
                <div className="col-xl-12 co-xl-12">
                    <div className="form-group col-xl-12 col-lg-12">
                            <label>Select Reason </label>
                            <Select 
                           onChange={this.selecetedReason.bind(this)} 
                            closeMenuOnSelect={true}
                            className={"border-radius-0"}
                            style={{borderRadius:"0px"}}
                            options={this.selectReason()} />            
                    </div>
                    {/* <div className="form-group col-xl-12 col-lg-12"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >                 */}
                            <div className={"form-group col-xl-12 col-lg-12 "+(this.state.displayReasonField)}>
                                <label className="col-xl-12 col-lg-12">Reason for closure</label>
                                <textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} value={this.props.context.reasonForClose} rows="5" className="form-control col-xl-12 col-lg-12"></textarea>
                            </div>
                            {/* <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success" onClick={this.handleSubmit.bind(this)}>Submit</button>
                            </div> */}
                    {/* </div> */}
                    {/* <div className="form-group col-xl-12 col-lg-12"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >                 */}
                       
                    <div className="col-xl-4 col-lg-4 form-group">
                        <label>Delivery Date</label>
                        {/* <input type="text" placeholder="Delivery Date" id="datetimepicker_mask" value={this.state.deliveryDate} autoComplete="off" className="datetimepicker_date form-control" name="deliveryDate" onChange={this.changeDateHandler.bind(this)} /> */}
                        <Datetime
                            inputProps={{ placeholder: 'Delivery Date', name: 'deliveryDate', autoComplete: 'off' }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm:ss"
                            name="deliveryDate"
                            onChange={this.handlerStartDateTime.bind(this)} />
                    </div>

                            <div className="form-group col-xl-12 col-lg-12">
                                <button type="submit" className="btn btn-success" onClick={this.handleSubmit.bind(this)}>Submit</button>
                            </div>
                    {/* </div> */}
                </div>
			);
		}
}



function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        // mask:'39-19-9999 29:59',
        format: 'Y-m-d H:i',
        timepicker: true
    });
    $('.datetimepicker_date').datetimepicker({
        // mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}