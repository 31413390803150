import React from 'react';
import { FaChartLine } from 'react-icons/fa';
import './detentionDashboard.css';

const SummaryCard = ({ title, count, backgroundColor }) => {
  return (
    <div className={`summary-card ${backgroundColor}`}>
      <div className="summary-card-content">
        <FaChartLine size={50} className="summary-card-icon" />
        <div className="summary-card-text">
          <h3 className="summary-card-title">{title}</h3>
          <p className="summary-card-count">{count}</p>
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
