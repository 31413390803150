
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const Logsbtn = (props) => {
    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onCloseRouteDiv(e);
    //     props.context.componentParent.onCloseUploadDiv(e);
    //     props.context.componentParent.onShowTimelineDiv(props.data);
    // };
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickLogsBtn(props);
    };

    return (
        <div className="map-icon-component">
			{/*<button onClick={handleClick} className="custom-btn label label-success" title="View Timeline"><i className="icofont icofont-tree-alt"></i> Timeline </button>
            &nbsp;| &nbsp;
            */}
            <button onClick={handleRouteClick} className="custom-btn label label-success" title="History"><i className="icofont icofont-history f25"></i> </button>
       
        </div>
    );
};

export default Logsbtn;
