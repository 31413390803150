import './dieselPricesTable.css';
import React, { useEffect, useState } from 'react';
var redirectURL = require('../redirectURL');

const breweries = [
    "Alwar", "Dharuhera", "Aurangabad", "Kolkata", "Unnao",
    "Hyderabad", "Naharlagun", "Mysore", "Cuttack", "Shillong", "Daman"
];

const breweriesMapping = [
    { source: "NCCI, Sangareddy", city: "Sangareddy" },
    { source: "Barmalt, Kotputli", city: "Alwar" },
    { source: "PMV, Kashipur", city: "Unnao" },
    { source: "Padmavati, Kekri, Ajmer", city: "Ajmer" },
    { source: "Soufflet, Alwar", city: "Alwar" },
    { source: "HNG Rishra (Hooghly), Kolkata", city: "Hoogly" },
    { source: "AGI Bhonagir, Hyderabad", city: "Hyderabad" },
    { source: "HNG Sinnar, Nasik", city: "Aurangabad" },
    { source: "HNG, Pondy", city: "Mysore" },
    { source: "Can Pack, Aurangabad", city: "Aurangabad" },
    { source: "Can Pack, Nuh", city: "Nuh" }
];


const DieselPricesTable = ({ baseDate, currentDate, title, onLoadingComplete }) => {
    const [priceData, setPriceData] = useState([]);
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const cityNames = title === "Outbound" ? breweries : breweriesMapping.map(item => item.city);

            const reqparams = {
                current_diesel_date_start: currentDate,
                base_diesel_date: baseDate,
                fuel_type: ["Diesel"],
                city_names: cityNames
            };

            try {
                const response = await redirectURL.post("/consignments/getDieselPrices", reqparams);
                if (Array.isArray(response.data)) {
                    const mappedData = response.data.map(item => {
                        const mappedCity = breweriesMapping.find(b => b.city === item.city);
                        return {
                            ...item,
                            source: mappedCity ? mappedCity.source : item.city
                        };
                    });
                    setPriceData(mappedData);
                } else {
                    console.error("Expected an array but received:", response.data);
                }
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                // Hide loader once data is loaded or if there's an error
                setShowTable(true)
                onLoadingComplete();
            }
        };

        fetchData();
    }, [baseDate, currentDate, title, onLoadingComplete]);

    const calculateAverages = React.useMemo(() => {
        if (priceData.length === 0) return { avgBase: 0, avgCurrent: 0, avgChange: 0 };

        const totalBase = priceData.reduce((acc, item) => acc + (item.base_diesel_fuel_price || 0), 0);
        const totalCurrent = priceData.reduce((acc, item) => acc + (item.current_fuel_price || 0), 0);
        const avgBase = (totalBase / priceData.length).toFixed(2);
        const avgCurrent = (totalCurrent / priceData.length).toFixed(2);
        const avgChange = (((avgCurrent - avgBase) / avgBase) * 100).toFixed(2);

        return { avgBase, avgCurrent, avgChange };
    }, [priceData]);

    const { avgBase, avgCurrent, avgChange } = calculateAverages;

    return (
        showTable ? (
            <div className="table-container">
                <h3 className="chart-title">{title}</h3>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>{title === "Outbound" ? "City" : "Source"}</th>
                            <th>New Base Diesel Prices <br />{baseDate}</th>
                            <th>Current Diesel Prices <br />{currentDate}</th>
                            <th>% Change</th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceData.map((item, index) => {
                            const label = title === "Outbound" ? item.city : item.source;
                            const basePrice = item.base_diesel_fuel_price;
                            const currentPrice = item.current_fuel_price;
                            const change = item.changes;

                            return (
                                <tr key={index}>
                                    <td>{label}</td>
                                    <td>{basePrice ? basePrice.toFixed(2) : 'N/A'}</td>
                                    <td>{currentPrice ? currentPrice.toFixed(2) : 'N/A'}</td>
                                    <td className={change < 0 ? 'change-negative' : 'change-positive'}>
                                        {change !== undefined ? `${((change/basePrice)*100).toFixed(2)}%` : 'N/A'}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="avg-row">
                            <td>AVG Pan India</td>
                            <td>{avgBase}</td>
                            <td>{avgCurrent}</td>
                            <td>{avgChange}%</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        ) : null
    );

};

export default DieselPricesTable;
