module.exports = {
    ORG_NAME: 'chettinad',
    SNDG:"Sales and Dispatch",
    LOG_PRT:"Spare Parts",
    LOG_TNP:"Train and Production",
    LOG_SC:"Supply Chain",
    USER_TYPE_MSIL:"MSIL",
    USER_TYPE_DCT:"DCT",
    USER_TYPE_TPT:"TRANSPORTER",
    SHIPMENT_MODE_ROAD: "ROAD",
    SHIPMENT_MODE_RAIL: "RAIL",
    INSIDE_TPA : "Inside TPA",
    INTRANSIT_TO_MY: "Intransit To Plant",
    INSIDE_MY_PMILL: "Inside Plant",
    LOADING_STARTED: "Loading Started",
    LOADING_COMPLETED: "Loading Completed",
    INSIDE_TPA_AFTER_LOADING: "Inside TPA After Loading",
    INTRANSIT: "Intransit To Customer",
    INTRANSIT_TO_LOADING_YARD: "Intransit To Loading Yard",
    FORCE_CLOSE:"Force Close",
    NEAR_CUSTOMER:"Inside Customer Vicinity",
    REACHED_CUSTOMER:"Reached Customer Location",
    LEFT_CUSTOMER:"Left Customer Vicinity",
    NEW_CUSTOMER: "New Customer",
    FIELDSET_LABEL_PARKING: "Vehicle Details",
    FIELDSET_LABEL_SHIPMENT: "Shipment Details",
    FIELDSET_LABEL_ORDER_ITEMS:"Order Items",
    LABEL_VEHICLE_NO: "Vehicle No",
    LABEL_VEHICLE_TYPE: "Vehicle Type",
    LABEL_PARKING_ENTRY_NO: "Parking Entry No",
    LABEL_PARKING_DATE: "Parking Date",
    LABEL_PARKING_TIME: "Parking Time",
    LABEL_TPT_CODE: "Transporter Code",
    LABEL_TPT_NAME: "Transporter Name",
    LABEL_DRIVER_CODE: "Driver Code",
    LABEL_DRIVER_NAME: "Driver Name",
    LABEL_SHIPMENT_MODE: "Shipment Mode",
    LABEL_SHIPMENT_SOURCE: "Shipment Source",
    LABEL_SHIPMENT_DESTINATION: "Shipment Destination",
    LABEL_SHIPMENT_DOC_NO: "Shipment No",
    LABEL_SHIPMENT_DATE: "Shipment Date",
    LABEL_SHIPMENT_TIME: "Shipment Time",
    COL_CONSIGNMENT_CODE:"Batch No",
    COL_SHIPMENT_DOC_NO : "Shipment No",
    COL_TRUCK_NO: "Truck No",
    COL_TRUCK_TYPE: "Vehicle Type",
    COL_STATUS: "Status",
    COL_TPT_CODE: "Transporter Code",
    COL_TPT_NAME: "Transporter Name",
    COL_TPT_ZONE: "Transportation Zone",
    COL_DRIVER_NAME: "Driver Name",
    COL_DRIVER_CODE: "Driver Code",
    COL_DRIVER_NUMBER : "Driver Number",
    COL_SIM_OPERATOR:"Sim Tracking Service Provider",
    COL_PARKING_ENTRY_NO: "Parking Entry No",
    COL_PARKING_DATE: "Parking Date",
    COL_SHIPMENT_MODE: "Mode",
    COL_RAILYARD_SOURCE: "Source Railyard",
    COL_RAILYARD_DESTINATION: "Destination Railyard",
    COL_SHIPMENT_DATE: "Shipment Time",
    COL_INVOICE_DATE: "Invoice No",
    COL_DELIVERY_NO: "Delivery No",
    COL_SALE_ORDER: "Invoice No",
    COL_ITEM_NO: "Item No",
    COL_ITEM_DESC: "Item Description",
    COL_ITEM_QTY: "Quantity",
    COL_CUSTOMER_NAME: "Depot Name",
    COL_CUSTOMER_CODE: "Customer Code",
    COL_CUSTOMER_CITY: "Depot City",
    COL_GROUP_HEADER_SHIPMENT_DATA:"SHIPMENT DATA",
    COL_GROUP_HEADER_TPA_TO_MY: "<i class='icofont icofont-map-pins'></i> TPA TO MY",
    COL_GROUP_HEADER_MY_TO_DSC:"<i class='icofont icofont-map-pins'></i> MY TO DSC",
    COL_GROUP_HEADER_MY_TO_CRM:"<i class='icofont icofont-map-pins'></i> MY TO CRM",
    COL_GROUP_HEADER_MY_TO_PLATE_MILL:"<i class='icofont icofont-map-pins'></i> MY TO PLATE MILL",
    COL_GROUP_HEADER_TPA_TO_PIPE_MILL:"<i class='icofont icofont-map-pins'></i> TPA TO PIPE MILL",
    COL_MYLOADING_YARD_TYPE:"Loading Yard Type",
    COL_MY_IN: "Loading Yard Entry Time",
    COL_MY_OUT: "Loading Yard Exit Time",
    COL_TIME_SPEND_HRS: "Time Spend Hr(s)",
    COL_GROUP_HEADER_MY_TO_LOADING_AREA:"MY TO LOADING AREA",
    COL_LOADING_AREA_NAME:"Area Name",
    COL_LOADING_AREA_ENTRY_TIME:"Loading Area Entry Time",
    COL_LOADING_AREA_EXIT_TIME:"Loading Area Exit Time",
    COL_LOADING_AREA_DURATION: "Time Spend Hr(s)",
    COL_GROUP_HEADER_MY_TO_TPA: "MY TO TPA",
    COL_TPA_ENTRY_AFTER_LOADING: "Entry Time After Loading",
    COL_TPA_EXIT_AFTER_LOADING: "Exit Time After Loading", 
    COL_TPA_DURATION_AFTER_LOADING: "Time Spend Hr(s)", 
    COL_TIME_SPEND_MINUTE: "Time Spend min(s)",
    COL_TIME_SPEND_HH_MM: "Time Spend (HH:mm)",
    COL_TIME_SPEND: "Time Spend",
    COUNTER_TOTAL_CONSIGNMENTS: "Batches",
    COUNTER_TOTAL_TRUCKS: "Trucks",
    COUNTER_INSIDE_TPA: "Inside TPA",
    COUNTER_TRANSIT_DELAY: "Transit Delays",
    COUNTER_GPS_NOT_AVAILABLE: "GPS Not Available",
    COUNTER_OVERSPEED: "Overspeeding",
    COUNTER_NIGHT_DRIVING: "Night Driving",
    COUNTER_LOADING_DELAY: "Loading Delay",
    COUNTER_UNLOADING_DELAY: "Unloading Delay",
    COUNTER_STOPPAGES:  "Stoppages",
    COUNTER_CRITICAL: "Critical",
    COUNTER_PRIORITY: "Priority",
    COUNTER_PROBABLE_ACCIDENT: "Probable Accident",
    COUNTER_DEVIATED_ROUTES: "Deviated Routes",
    COUNTER_CANCELLED: "Cancelled (chettinad)",
    COUNTER_POD_RECEIVED: "POD Received (chettinad)",
    COUNTER_TRIP_CLOSED_BY_POD: "Trip Closed By POD (chettinad)",
    COUNTER_TRUCK_REPORTED: "Trucks Reported",
    PAGE_TITLE_CONSIGNMENT_SNDG:"Active Batches",
    PAGE_TITLE_CONSIGNMENT_PRT:"Active Batches",
    PAGE_TITLE_CONSIGNMENT_TNP:"Active Batches",
    PAGE_TITLE_CONSIGNMENT_ALL:"All Batches",
    PAGE_TITLE_CONSIGNMENT_DELIVERED:"Delivered Batches",
    PAGE_TITLE_CONSIGNMENT_RAIL: "Rail Batches",
    COL_LEGT_START_TIME: "Leg Start Time",
    COL_LEGT_END_TIME: "Leg End Time",
    COL_EXP_LEG_DISTANCE: "Exp. Leg Distance (KM)",
    COL_ACTUAL_LEG_DISTANCE_COVERED: "Actual Leg Distance Covered (KM)",
    COL_EXP_DISTANCE_FROM_TRIP_START: "Exp. Distance from Trip Start(KM)",
    COL_ACTUAL_DISTANCE_FROM_TRIP_START: "Actual Distance from Trip Start (KM)",
    COL_GOOGLE_DISTANCE_FROM_START: "Google Distance from Start (KM)",
    COL_GPS_AVAILABLE:  "GPS Data Available",
    COL_TRIP_COMPLETED: "Trip Completed",
    COL_SPEED_KMPH: "Avg Speed (km/h)",
    COL_REPORTED_AT: "Reported At",
    COL_LAST_DATA_RECEIVED: "Last Data Receieved",
    COL_DATA_RECEIVED_ON: "Data Received On",
    COL_GPS_PROVIDER: "GPS Provider",
    COL_EVENT_TIME: "Event Time",
    COL_EVENT: "Event",
    COL_TRAVELLED_FROM: "Traveled From",
    COL_TRAVELLED_UNTIL: "Traveled Until",
    COL_DISTANCE_TRAVELLED_KM: "Distance Traveled (km)",
    COL_BREAK_START: "Break Start",
    COL_EXP_CLOSED_ON: "Exception Closed On",
    COL_BREAK_TIME: "Break Time",
    COL_GATE_IN_TIME: "Gate In Time",
    COL_GATE_OUT_TIME: "Gate Out Time",
    COL_LOADING_TIME: "Loading Time",
    COL_FROM_DATE: "From Date",
    COL_TO_DATE: "To Date",
    COL_UNLOADING_TIME: "Unloading Time",
    COL_TICKET: "Ticket",
    COL_TICKET_NO: "Ticket No",
    COL_TRANSPORTER: "Transporter",
    COL_DEPARTMENT: "Department",
    COL_ACTUAL_LSP_USER: "Actual LSP User",
    TICKET_STATUS_OPEN: "Open",
    TICKET_STATUS_WORK_IN_PROGRESS: "Work in progress",
    TICKET_STATUS_CLOSED: "Closed",
    TICKET_STATUS_ONHOLD: "On hold",
    TICKET_STATUS_AUTO_CLOSED: "Auto closed",
    TICKET_STATUS_INVALID: "Invalid",
    TICKET_STATUS_CLOSED_NO_RESPONSE: "Closed - No response",
    TICKET_STATUS_FORCE_CLOSED: "Force Closed",
    COL_TRANSIT_DELAY_HOURS: "Transit Delay Hours",
    COL_EXCEPTION_LOCATION: "Exception Location",
    COL_LAST_KNOWN_CITY: "Last Known City/Area",
    COL_LAST_KNOWN_STATE: "Last Known State",
    COL_LAST_PACKET_TIME: "Last Packet Time",
    COL_GATE_INVOICE: "Gate Out / Invoice Time",
    COL_CREATED_DATE: "Created Date",
    COL_MODIFIED_DATE: "Modified Date",
    COL_INVOICE_NO: "Invoice No",
    COL_SRV_NO: "SRV No",
    COL_SRV_STATUS: "SRV Status",
    COL_DLOC_CODE: "DLoc Code",
    COL_PART_NAME: "Part Name",
    COL_PART_NO: "Part No",
    COL_TRANSACTION_TYPE: "Transaction Type",
    COL_UNLOAD_NO: "Unload No",
    COL_DISTANCE_FROM_DESTINATION_KM: "Dist. From Destination (km)",
    COL_REACHED_DESTINATION_AT: "Reached Customer Location at",
    COL_DISTANCE_FROM_SOURCE_PLANT_KM: "Dist. From Source Plant (km)",
    COL_REACHED_SOURCE_AT: "Reached Source Plant At",
    COL_TRANSIT_TIME_HOURS: "Transit Time (Hours)",
    COL_TRANSIT_TIME_DAYS: "Transit Time (Days)",
    COL_OVERSPEED: "Overspeed",
    COL_ORIGINAL_ETA: "Original ETA",
    COL_REVISED_ETA: "Revised ETA",
    COL_DURATION_FROM_GATE_OUT: "Duration from Gate Out",
    COL_MGPA_DATE_TIME: "MGPA Date Time",
    COL_MGPA_NO: "MGPA No",
    COL_LAST_CITY: "Last City/Town",
    COL_LAST_STATE: "Last State",
    COL_INVOICE_TIME: "Invoice Time",
    COL_CONSIGNER_CODE: "Consigner Code",
    COL_CUSTOMER_STATE: "Customer State",
    COL_ZONE: "Zone",
    COL_LAST_CONSIGNMENT_ZONE: "Last Batch Zone",
    COL_GPS_GATE_IN_TIME: "GPS Gate In Time",
    COL_GPS_GATE_OUT_TIME: "GPS Gate Out Time",
    COL_DISTANCE_LAST_24_HR_KM: "Dist. Traveled last 24Hr (KM)",
    COL_ORG_POD_DATE: "chettinad POD Date",
    COL_TRUCK_REPORTING_DATE: "Truck Reporting Date",
    COL_TRANSSHIPMENT: "Transshipment",
    COL_OLD_TRUCK_NO: "Old Truck No",
    COL_TRANSSHIPMENT_DATE: "Transshipment Date",
    COL_TPT_DELIVERED: "TPT Delivered",
    COL_TPT_DELIVERED_ON: "TPT Delivered On",
    COL_TPT_DELIVERED_REASON: "TPT Delivered Reason",
    COL_REACHED_PLANT_GATE_IN: "Reached Plant Gate In",
    COL_LEFT_DESTINATION_AT: "Left Customer Vicinity at",
    COL_UNLOADING_TIME_HRS: "Unloading Time (In Hrs)",
    COL_ONTIME_DELIVERY: "OnTime Delivery",
    COL_LOCAL_MOVEMENT_YES_NO: "Local Movement(Yes/No)",
    COL_TVP_GATE_IN: "TVP Gate In",
    COL_POD_RECEIVED: "POD Received",
    COL_POD_DATE: "POD Date",
    COL_EXPECTED_DISTANCE_KM: "Customer Location Distance From Plant (KM)",
    COL_CLUSTER: "Cluster",
    COL_SPOC_CONTACT_NAME: "SPOC Contact Name",
    COL_SPOC_CONTACT_NUMBER: "SPOC Contact Number",
    COL_VEHICLE_MODE: "Vehicle Mode",
    COL_TRIP_TYPE: "Trip Type",
    COL_ITMES: "Items",
    COL_PROBABLE_ACCIDENT: "Probable Accident",
    COL_CRITICAL: "Critical",
    COL_FORCE_CLOSE: "Force Close",
    COL_FORCE_CLOSE_REASON: "Closed Reason",
    COL_USER_TYPE: "User Type",
    COL_COMMENT: "Comment",
    COL_COMMENT_BY: "Commented By",
    COL_PLANT_CODE: "Plant Code",
    COL_TOTAL_TRUCKS: "Total Trucks",
    COL_ON_TRIP_TRUCKS: "On Trip Trucks",
    COL_TRUCKS_WITH_GPS_IN_24HRS: "Trucks with GPS Data in 24hrs",
    COL_GPS_DATA_NOT_REPORTED_IN_24HRS: "GPS Data Not Reported in 24hrs",
    COL_AT_CUSTOMER: "At Customer",
    COL_GPS_NO_DATE_IN_24HRS: "GPS No Data in 24hrs",
    COL_TRUCKS_WITH_NO_TRASNPORTERS: "Trucks with No Transporters",
    COL_TRUCKS_WITH_GPS_DATA: "Trucks with GPS Data",
    COL_GPS_DATE_NOT_REPORTED_LAST_24_HRS: "GPS Data Not Reported Last 24 Hours",
    COL_GROUP_HEADER_UNAUTHORIZED_STOPPAGES: "UNAUTHORIZED STOPPAGES (WorkSHOP)",
    COL_WORKSHOP_NAME:"Workshop Name",
    COL_WORKSHOP_ENTRY_TIME: "Workshop Entry Time",
    COL_WORKSHOP_EXIT_TIME: "Workshop Exit Time",
    COL_WORKSHOP_DURATION: "Time Spend Hr(s)",
    COUNTER_INTRANSIT_TO_MY:"Intransit To Plant",
    COUNTER_INSIDE_MY_PM:"Inside Plant",
    COUNTER_LOADING_STARTED:"Loading Started",
    COUNTER_LOADING_COMPLETED: "Loading Completed",
    COUNTER_INSIDE_TPA_AFTER_LOADING: "Inside TPA After Loading",
    COUNTER_INSTRANSIT_TO_CUSTOMER: "Intransit To Customer",
    COUNTER_INSTRANSIT_TO_LOADING_YARD: "Intransit To Loading Yard",
    COUNTER_NEAR_CUSTOMER: "Inside Customer Vicinity",
    COUNTER_REACHED_CUSTOMER: "Reached Customer Location",
    COUNTER_LEFT_CUSTOMER: "Left Customer Vicinity",
    COUNTER_FORCE_CLOSE: "Force Close",
    COUNTER_MYS: "MY",
    COUNTER_DSC: "DSC",
    COUNTER_CRM: "CRM",
    COUNTER_PLATEMILL: "Plate Mill",
    COUNTER_PIPEMILL: "Pipe Mill",
    COUNTER_MA: "MA",
    COUNTER_MB: "MB",
    COUNTER_MC: "MC",
    COUNTER_MD1: "MD1",
    COUNTER_MD2: "MD2",
    COUNTER_MD3: "MD3",
    COUNTER_MD4: "MD4",
    COUNTER_ME: "ME",
    COUNTER_LOADING_AREA:"Loading Area",
    COUNTER_NONLOADING_AREA:"Non Loading Area",
    COUNTER_DSC1: "DSC D1",
    COUNTER_DSC2: "DSC D2",
    COUNTER_DSC3: "DSC D3",
    COUNTER_DSC4: "DSC D4",
    COUNTER_DSC5: "DSC D5",
    COUNTER_DSC6: "DSC D6",
    COUNTER_DSC7: "DSC D7",
    COUNTER_DSC8: "DSC D8",
    COUNTER_CRM1: "CRM C1",
    COUNTER_CRM2: "CRM C2",
    COUNTER_CRM3: "CRM C3",
    COUNTER_CRM4: "CRM C4",
    COUNTER_CRM5: "CRM C5",
    COUNTER_CRM6: "CRM C6",
    COUNTER_CRM7: "CRM Pickle 3",
    COUNTER_PLATEMILL1: "Plate Mill",
    COUNTER_PLATEMILL2: "Plate Mill2",
    COUNTER_PLATEMILL3: "Plate Mill3",
    COUNTER_PLATEMILL4: "Plate Mill4",
    COUNTER_PIPEMILL1: "Pipe Mill LSAW",
    COUNTER_PIPEMILL2: "Pipe Mill 416",
    COUNTER_PIPEMILL3: "Pipe Mill 339",
    COUNTER_PIPEMILL4: "Pipe Mill Coating Yard",
    COUNTER_EMPTYLOADED: "Empty Vehicles",
    COUNTER_PARTIALLOADED: "Partial Loaded",
    COUNTER_COMPLETELOADED: "Complete Loaded",
    CONDITION_MA: "MA",
    CONDITION_MB: "MB",
    CONDITION_MC: "MC",
    CONDITION_MD1: "MD1",
    CONDITION_MD2: "MD2",
    CONDITION_MD3: "MD3",
    CONDITION_MD4: "MD4",
    CONDITION_ME: "ME",
    CONDITION_NMA: "NMA",
    CONDITION_DSC1: "DSC D1",
    CONDITION_DSC2: "DSC D2",
    CONDITION_DSC3: "DSC D3",
    CONDITION_DSC4: "DSC D4",
    CONDITION_DSC5: "DSC D5",
    CONDITION_DSC6: "DSC D6",
    CONDITION_DSC7: "DSC D7",
    CONDITION_DSC8: "DSC D8",
    CONDITION_CRM1: "CRM C1",
    CONDITION_CRM2: "CRM C2",
    CONDITION_CRM3: "CRM C3",
    CONDITION_CRM4: "CRM C4",
    CONDITION_CRM5: "CRM C5",
    CONDITION_CRM6: "CRM C6",
    CONDITION_CRM7: "CRM Pickle 3",
    CONDITION_PLATEMILL1: "Plate Mill",
    CONDITION_PLATEMILL2: "PlateMill2",
    CONDITION_PLATEMILL3: "PlateMill3",
    CONDITION_PLATEMILL4: "PlateMill4",
    CONDITION_PIPEMILL1: "Pipe Mill LSAW",
    CONDITION_PIPEMILL2: "Pipe Mill 416",
    CONDITION_PIPEMILL3: "Pipe Mill 339",
    CONDITION_PIPEMILL4: "Pipe Mill Coating Yard",
    CONDITION_CRM: "CRM",
    CONDITION_FG: "FG Inspection",
    COL_POD_DATE_TIME:"POD Date Time",
    COL_POD_REMARKS:"Remarks",
    COL_POD_DONE_BY:"POD Done By",
    COL_POD_DAMAGES_ANY: "Damages (If any)",
    COL_POD_DAMAGE_DESCRIPTION:"Damage Description",
    COL_TRAVEL_STATUS:"Travel Status",
    PAGE_EPOD:"EPOD",
    COUNTER_TOTAL_POD: "Total POD",
    COUNTER_PENDING_POD: "POD Pending",
    COUNTER_COMPLETED_POD: "POD Completed",
    COL_UPDATE_LRNO: "Update LR No",
    COL_LRNO: "LR No",
    COL_GSTNO: "GST No",
    COL_TIME_SPEND_AFTER_SHIPMENT_CREATION: "Duration After Shipment Creation To TPA Exit",
    COL_REMAINING_TOTAL_LRNO: "Remaining LR Nos / Total LR Nos",
    COL_TRACKING_MODE: "Tracking Mode",
    COL_SHIP_TO:"Ship To Name",
    COL_SHIPP:"Ship To Code",
    COL_SOLD_TO:"Sold To Name",
    COL_SOLDP:"Sold To Code",
    COL_SOLD_TO_CITY:"Ship To City",
    COL_SOLD_TO_STATE:"Ship To State",
    COL_SOLD_TO_PINCODE:"Ship To Pincode",
    COL_SOLD_TO_ADDRESS:"Ship To Address",
    COL_SOLD_TO_CONTACT_NAME:"Ship To Contact Name",
    COL_SOLD_TO_CONTACT_NO:"Ship To Contact No",
    COL_CONSIGNEE_STATE:"Depot State",
    COL_CONSIGNEE_ADDRESS:"Depot Address",
    MATERIAL_INFO:"Material Info",
    SR_GRADE:"SR Grade",
    BGS_AB :"AB",
    BGS_BC :"BC",
    BGS_CD :"CD",
    BGS_DE :"DE",
    BGS_AB_Entry_Gate:"AB Entry",
    BGS_BC_Entry_Gate:"BC Entry",
    BGS_CD_Entry_Gate:"CD Entry",
    BGS_DE_Entry_Gate:"DE Entry",
    BGS_DE_Exit_Gate:"DE Exit",
    BGS_AB_Exit_Gate:"AB Exit",
    BGS_BC_Exit_Gate:"BC Exit",
    BGS_CD_Exit_Gate:"CD Exit",
    BGS_SNDC: "SNDC"

    

    




    

    




}