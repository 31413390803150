import React, { useEffect, useState, useRef } from 'react';
import SummaryCard from './SummaryCard.js';
import StackedBarChart from './charts/StackedBarChart.js';
import LineChartComponent from './charts/LineChart1.js';
import PieChartComponent from './charts/PieChart1.js';
import StatewiseBubbleChart from './charts/BubbleChart.js'
import FilterComponent from './FilterComponent.js';
import './detentionDashboard.css'
import { getYYYYMMDDHHMMSS } from '../common/utils';
import { AgGridReact } from '@ag-grid-community/react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import Papa from 'papaparse'; 
var redirectURL = require('../redirectURL');

const Dashboard = () => {
    const [counters, setCounters] = useState({
        over1: 0,
        over2: 0,
        over7: 0,
        over15: 0,
        over21: 0,
        over30: 0,
        overr30: 0,
    });

    const [show, setshow] = useState(false);
    const[basicType, setbasicType] = useState(''); 
    const [basicTitle, setbasicTitle] = useState('default');
    const [loadshow, setLoadShow] = useState('show-n');
    const [transporterRecords, setTransporterData] = useState([]);
    const [consigneeRecords, setConsigneeData] = useState([]);
    const [consigneeCityRecords, setConsigneeCityDate] = useState([]);
    const [consigneeStateRecords, setConsigneeStateDate] = useState([]);
    const [detentionData, setDetentionData] = useState([]);
    const [userGridState, setUserGridState] = useState([]);
    const [screenTitle, setScreenTitle] = useState("Detention Report");
    // const [statusVal, setStatusVal] = useState(true);  
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);
    const [tripStatus, setTripStatus] = useState("");
    const [uploadFile, setUploadFile] = useState(null);
    const [sliderBulkUpload, setSliderBulkUpload] = useState('');
    // const [columnDefs, setColumnDefs] = useState([
    const columnDefs = [
        { headerName: "Truck No", field: "truck_no", width: 140, },
        { headerName: "Shipment ID", field: "shipment_id", width: 140, },
        { headerName: "Transporter Code", field: "transporter_code", width: 160, },
        { headerName: "Transporter Name", field: "transporter_name", width: 160, },
        {
            headerName: "Shipment Time",
            field: "shipment_time",
            valueFormatter: (params) => {
                console.log(params.value,"params.value")
                if (params.value === undefined || params.value === null) {
                    return ""; // Show empty string for undefined or null
                }
                return params.value
            },
            width: 160,
        },
        { headerName: "Consignee Code", field: "consignee_code", width: 140 },
        { headerName: "Consignee Name", field: "consignee_name", width: 200 },
        { headerName: "Consignee City", field: "consignee_city", width: 150 },
        {
            headerName: "Recent Dealer Reported",
            field: "recent_dealer_reported",
            valueFormatter: (params) => {
                console.log(params.value,"params.value")
                if (params.value === undefined || params.value === null) {
                    return ""; // Show empty string for undefined or null
                }
                return params.value
            },
            width: 160,
        },
        {
            headerName: "Loading Yard Exit Time",
            field: "loading_yard_exit_time",
            valueFormatter: (params) => {
                console.log(params.value,"params.value")
                if (params.value === undefined || params.value === null) {
                    return ""; // Show empty string for undefined or null
                }
                return params.value
            },
            width: 160,
        },
        {
            headerName: "Invoice Time",
            field: "invoice_time",
            valueFormatter: (params) => {
                console.log(params.value,"params.value")
                if (params.value === undefined || params.value === null) {
                    return ""; // Show empty string for undefined or null
                }
                return params.value
            },
            width: 160,
        },
        // {
        //     headerName: "Left Dealer Marked",   
        //     field: "left_dealer_marked",
        //     hide: statusVal,
        //     valueFormatter: (params) => {
        //         console.log(params.value,"params.value")
        //         if (params.value === undefined || params.value === null) {
        //             return ""; // Show empty string for undefined or null
        //         }
        //         return params.value
        //     },
        //     width: 160,
        // },
        {
            headerName: "Final Trip Completed Time",
            field: "final_trip_completed_time",
            valueFormatter: (params) => {
                console.log(params.value,"params.value")
                if (params.value === undefined || params.value === null) {
                    return ""; // Show empty string for undefined or null
                }
                return params.value
            },
            width: 160,
        },
        // { headerName: "Status", field: "status", type: "numberColumn", width: 140 },
        { headerName: "Consignment Code", field: "consignment_code", width: 140 },
        { headerName: "Transit Time (hrs)", field: "transit_time", type: "numberColumn", width: 140 },
        { headerName: "Consigner Code", field: "consigner_code", width: 140 },
        { headerName: "Consignee State", field: "consignee_state", width: 140 },
        { headerName: "Parking ID", field: "parking_id", width: 140 },
        { headerName: "Driver Mobile No", field: "driver_mobile_no", width: 140 },
        { headerName: "Type of Tracking", field: "type_of_tracking", width: 140 },
        // { headerName: "Detention Time", field: "detention_time",width:140  },
        {
            headerName: "Status",
            field: "status",
            resizable:true,
            type: "numberColumn", width: 180, pinned: 'right',
            valueGetter: function (params) {
                try {
                    var status = "At Plant"
                    if (params.data.status == 3 || params.data.status == "3") {
                        status = "At Plant";
                    }
                    if (params.data.status == 6 || params.data.status == "6") {
                        status = "Force Close";
                    }
                    if (params.data.status == 8 || params.data.status == "8") {
                        status = "Intransit To Customer";
                    }
                    if (params.data.status == 9 || params.data.status == "9") {
                        status = "Proximity Customer Location";
                    }
                    if (params.data.status == 10 || params.data.status == "10") {
                        status = "";
                    }
                    if (params.data.status == 11 || params.data.status == "11") {
                        status = "";
                    }
                    if (params.data.status == 12 || params.data.status == "12") {
                        status = "Left Customer Vicinity";
                    }
                    if (params.data.status == 13 || params.data.status == "13") {
                        status = "Possible Trip Closure";
                    }
                    if (params.data.status == 14 || params.data.status == "14") {
                        status = "Proximity Trip Closure";
                    }
                    if (params.data.status == 15 || params.data.status == "15"
                        || params.data.status == 16 || params.data.status == "16" ||
                        params.data.status == 17 || params.data.status == "17") {
                        status = "Trip Completed";
                    }
                    if (params.data.status_text != undefined && params.data.status_text != "undefined" && params.data.status_text != null && params.data.status_text != "") {
                        return params.data.status_text;
                    }
                    else {
                        return status;
                    }
                }
                catch (e) { }
            }
        },
        {
            headerName: "Detention Time (Hrs)",
            field: "detention_time_sec",
            type: "numberColumn", width: 140, pinned: 'right',
            valueGetter: function (params) {
                if (params.data.detention_time_sec) {
                    return (params.data.detention_time_sec / 3600).toFixed(2)
                }
                else {
                    return 0
                }

            },
        },
        // { headerName: "Waiting for Unloading (Days)", field: "waiting_for_unloading_from_days", type: "numberColumn" }
    ]



    useEffect(() => {
        // Fetch the saved grid state when the component mounts
        getUserGridState();
    }, []);

    const closeAlert = () => {
        setshow(false)
        setLoadShow('show-n')
	}

    // Function to save current grid layout state
    const onClickSaveGridState = () => {
        const reqparams = {
            gridcolumns: gridColumnApi.current.getColumnState(),
            gridgroup: gridColumnApi.current.getColumnGroupState(),
            gridcolsort: gridApi.current.getSortModel(),
            gridcolfilter: gridApi.current.getFilterModel(),
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenTitle
        };

        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                gridApi.current.closeToolPanel()
                alert("Successfully saved grid layout");
            })
            .catch((error) => {
                console.error("Error saving grid state:", error);
            });
    };

    // Function to reset grid layout to default state
    const resetState = () => {
        gridColumnApi.current.resetColumnState();
        gridColumnApi.current.resetColumnGroupState();
        gridApi.current.setSortModel(null);
        gridApi.current.setFilterModel(null);

        const reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenTitle
        };

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                alert("Successfully reset to default grid layout");
                gridApi.current.closeToolPanel()
            })
            .catch((error) => {
                console.error("Error resetting grid state:", error);
            });
    };

    // Function to retrieve and apply saved grid state
    const getUserGridState = () => {
        const reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname
        };

        redirectURL.post("/consignments/usergridstates", reqparams)
            .then((response) => {
                setUserGridState(response.data);
                restoreGridStates(response.data);
            })
            .catch((error) => {
                console.error("Error fetching user grid state:", error);
            });
    };

    // Function to restore grid layout state from saved data
    const restoreGridStates = (stateData) => {
        if (stateData.length > 0) {
            const gridState = stateData[0];
            gridColumnApi.current.setColumnState(gridState.gridcolumns);
            gridColumnApi.current.setColumnGroupState(gridState.gridgroup);
            gridApi.current.setSortModel(gridState.gridcolsort);
            gridApi.current.setFilterModel(gridState.gridcolfilter);
        }
    };




    const groupAndAverageData = (data, groupKey, nameKey) => {
        const groupedData = data.reduce((acc, item) => {
            const groupValue = item[groupKey];
            const nameValue = item[nameKey];
            const detentionTimeSec = item.detention_time_sec;

            if (acc[groupValue]) {
                acc[groupValue].detention_time_sec += detentionTimeSec;
                acc[groupValue].count += 1;
            } else {
                acc[groupValue] = {
                    [groupKey]: groupValue,
                    name: nameValue,
                    detention_time_sec: detentionTimeSec,
                    count: 1,
                };
            }
            return acc;
        }, {});

        return Object.values(groupedData).map(item => ({
            code: item[groupKey],
            name: item.name,
            averageDetentionHours: (item.detention_time_sec / item.count / 3600).toFixed(2),
        }));
    };

    const handleFilterSubmit = async (filterValues) => {
        setLoadShow('show-m')
        try {
            // const isStatusVal =  filterValues.tripStatus && filterValues.tripStatus.value !== 2
            // setStatusVal(isStatusVal);
            const response = await redirectURL.post('carlsberg/fetchDetentionReportt', filterValues);
            const records = response.data;
            setDetentionData(response.data.detention_data || []);
            const countersData = {
                over1: records.counts["waiting for unloading < 1 day"] || 0,
                over2: records.counts["waiting for unloading < 2 day"] || 0,
                over7: records.counts["waiting for unloading < 7 days"] || 0,
                over15: records.counts["waiting for unloading < 15 days"] || 0,
                over21: records.counts["waiting for unloading < 21 days"] || 0,
                over30: records.counts["waiting for unloading < 30 days"] || 0,
                overr30: records.counts["waiting for unloading > 30 days"] || 0,
            };
            const transporterData = groupAndAverageData(response.data.detention_data, 'transporter_code', 'transporter_name');
            const consigneeData = groupAndAverageData(response.data.detention_data, 'consignee_code', 'consignee_name');
            const cityData = groupAndAverageData(response.data.detention_data, 'consignee_city', 'consignee_city');
            const StateData = groupAndAverageData(response.data.detention_data, 'consignee_state', 'consignee_state');
            console.log(transporterData, "transporterData")
            setLoadShow('show-n')
            setTransporterData(transporterData);
            setConsigneeData(consigneeData);
            setConsigneeCityDate(cityData)
            setConsigneeStateDate(StateData)
            setCounters(countersData);

            setLoadShow('show-n') // Update the state with the response data
        } catch (error) {
            setLoadShow('show-n')
            console.error('Error fetching detention report:', error);
        }
    }

    const onClickBulkUpload = () => {
        setSliderBulkUpload('slider-translate');
    };

    const onCloseRouteDiv = () => {
        setSliderBulkUpload('');
    };

    const changeFileHandler = (e) => {
        setUploadFile(e.target.files[0]);
    };



    const bulkUploadSubmit = async (event) => {
        event.preventDefault();

        const fileInput = event.target.uploadFile.files[0];
        if (!fileInput) {
            console.error('No file selected');
            return;
        }

        try {

            const parsedData = await new Promise((resolve, reject) => {
                Papa.parse(fileInput, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => resolve(results.data),
                    error: (error) => reject(error),
                });
            });

            const uploadedShipmentIds = parsedData.map(row => ({
                shipmentId: row['Shipment ID'],
                reachedDate: row['Reached Date'],
            }));

            const reqData = {
                uploadedShipmentIds,
                trip_status: '',
            };

            console.log(uploadedShipmentIds, "uploadedShipmentIds")
            const response = await redirectURL.post("/consignments/bulkDetentionStatus", reqData);

            if (response.data.status === "success") {
                setbasicType('success');
                setbasicTitle(`Bulk upload successful. ${response.data.message}`);
            } else {
                setbasicType('warning');
                setbasicTitle(
                    `Partial Success: ${response.data.message}. Missing Shipments: ${response.data.missingShipments && response.data.missingShipments.length > 0
                        ? response.data.missingShipments.join(", ")
                        : "None"
                    }`
                );
            }
            setshow(true); // Show the SweetAlert
            setSliderBulkUpload('');
        } catch (error) {
            console.error('Error:', error);
            setbasicType('error');
            setbasicTitle('An error occurred during the upload process. Please try again.');
            setshow(true);
        }
    };





    useEffect(() => {
        try {
            setLoadShow('show-m')
            const currentDate = new Date();
            const oneDayBefore = new Date();
            // oneDayBefore.setDate(currentDate.getDate() - 7); //Kept current date for both
            console.log(currentDate, oneDayBefore, "123")
            const formattedCurrentDate = currentDate.toISOString().split('T')[0];
            const formattedOneDayBefore = oneDayBefore.toISOString().split('T')[0];
            const required_params = {
                "fromDate": formattedOneDayBefore,
                "toDate": formattedCurrentDate,
                "tripStatus" : {
                    "label": "Waiting For Unload", "value": 1
                }
            }
            if (!["", undefined, null, "undefined"].includes(localStorage.getItem("pc"))) {
                required_params.plant_code = localStorage.getItem("pc")
            }
            redirectURL.post('carlsberg/fetchDetentionReportt'
                , required_params
            )
                .then((response) => {
                    const records = response.data;
                    const countersData = {
                        over1: records.counts["waiting for unloading < 1 day"] || 0,
                        over2: records.counts["waiting for unloading < 2 day"] || 0,
                        over7: records.counts["waiting for unloading < 7 days"] || 0,
                        over15: records.counts["waiting for unloading < 15 days"] || 0,
                        over21: records.counts["waiting for unloading < 21 days"] || 0,
                        over30: records.counts["waiting for unloading < 30 days"] || 0,
                        overr30: records.counts["waiting for unloading < 30 days"] || 0,
                    };
                    const detentionData = records.detention_data || []; // array of objects

                    // Group data by transporter and consignee separately
                    const transporterData = groupAndAverageData(detentionData, 'transporter_code', 'transporter_name');
                    const consigneeData = groupAndAverageData(detentionData, 'consignee_code', 'consignee_name');
                    const cityData = groupAndAverageData(detentionData, 'consignee_city', 'consignee_city');
                    const StateData = groupAndAverageData(detentionData, 'consignee_state', 'consignee_state');
                    console.log(StateData, "stateDatas")
                    setLoadShow('show-n')
                    setTransporterData(transporterData);
                    setConsigneeData(consigneeData);
                    setConsigneeCityDate(cityData)
                    setConsigneeStateDate(StateData)
                    setCounters(countersData);
                    setDetentionData(detentionData);
                });
        }
        catch (e) {
            setLoadShow('show-n')
            console.log("error", e)
        }

    }, [])

    const cardTitles = [
        { title: "Below 1 Day", key: "over1", color: "blue-card" },
        { title: "1 - 2 Days", key: "over2", color: "red-card" },
        { title: "2 - 7 Days", key: "over7", color: "purple-card" },
        { title: "7 - 15 Days", key: "over15", color: "green-card" },
        { title: "15 - 21 Days", key: "over21", color: "yellow-card" },
        { title: "21 - 30 Days", key: "over30", color: "pink-card" },
        { title: "Above 30 Days", key: "overr30", color: "blue-card" },

    ];

    return (
        <div className="card" style={{ padding: '20px' }}>
            <SweetAlert
					show={show}
					type={basicType}
					title={basicTitle}
					onConfirm={closeAlert}
				>
				</SweetAlert>
            <h5 className="dashboard-title mb-3">Detention Report</h5>

            <div className="d-flex justify-content-end mb-2">
                <button
                    className="float-right custom-btn white btn-primary p-1"
                    style={{ marginRight: "10px", whiteSpace: "nowrap" , borderRadius: "5px"}}
                    onClick={onClickBulkUpload}
                >
                    Bulk Upload
                </button>
            </div>

            <FilterComponent onSubmit={handleFilterSubmit} />
            {/* Summary Cards */}
            <div className="summary-card-container chart-wrapper mb-6">
                {cardTitles.map(({ title, key, color }) => (
                    <SummaryCard
                        key={key}
                        title={title}
                        count={`${counters[key]} trucks`}
                        backgroundColor={color}
                    />
                ))}
            </div>

            <div className="chart-container">
                <div className="chart-wrapper">
                    <StackedBarChart detentionRecords={transporterRecords} title="Transporter-wise Average Detention Hours" />
                </div>

            </div>
            <div className="chart-container">
                <div className="chart-wrapper">
                    <LineChartComponent detentionRecords={consigneeRecords} title="Dealer-wise Average Detention Hours" />
                </div>
            </div>

            <div className="chart-container row">
                <div className="col-12 d-flex justify-content-between">
                    <div className="chart-wrapper col-xl-6 col-lg-6 mr-3">
                        <PieChartComponent detentionRecords={consigneeCityRecords} title="City-wise Average Detention Hours" />
                    </div>

                    <div className="chart-wrapper col-xl-6 col-lg-6">
                        <StatewiseBubbleChart
                            detentionRecords={consigneeStateRecords}
                            title="State-wise Average Detention Hours"
                        />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 mb-10p">
                <h3 className="chart-title float-left">Detention Report Raw Data</h3>
                <button
                    className="float-right custom-btn white btn-danger"
                    onClick={onClickSaveGridState}
                    title="Save Grid Layout">
                    <i className="icofont icofont-save"></i>

                    {/* Save Grid Layout */}
                </button>
                <button
                    className="float-right custom-btn white btn-info"
                    style={{ marginRight: "10px" }}
                    onClick={resetState}
                    title="Reset Default Layout">
                    <i className="icofont icofont-refresh"></i>
                    {/* Reset Default Layout */}
                </button>
            </div>


            <div className="ag-theme-balham" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={detentionData}
                    columnDefs={columnDefs}
                    pagination={true}
                    paginationPageSize={50}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        resizable: true,
                    }}
                    onGridReady={(params) => {
                        gridApi.current = params.api;
                        gridColumnApi.current = params.columnApi;
                    }}
                    sideBar={{
                        toolPanels: [
                            {
                                id: 'columns',
                                labelDefault: 'Columns',
                                labelKey: 'columns',
                                iconKey: 'columns',
                                toolPanel: 'agColumnsToolPanel'
                            }
                        ],

                    }}
                />
            </div>

                {sliderBulkUpload !== "" && (
        <div className={`slide-r ${sliderBulkUpload}`} style={{ overflow: "auto" }}>
          <div className="slide-r-title p-0">
            <h4>
              Bulk Upload
              <span
                className="float-right closebtn"
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={onCloseRouteDiv}
              >
                X
              </span>
            </h4>
          </div>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={bulkUploadSubmit}>
              {/* <div className="col-xl-12 col-lg-12 row form-group">
                <label className="col-xl-12 col-lg-12">Select Status</label>
                <Select
                  className="col-sm-12"
                  name="select_status"
                  id="select_status"
                  value={{ value: tripStatus, label: tripStatus }}
                  onChange={(selectedOption) => setTripStatus(selectedOption.value)}
                  options={[
                    { value: "waiting_for_unload", label: "Waiting for Unload" },
                    { value: "trip_closure", label: "Trip Closure" },
                  ]}
                />
              </div> */}

              <div className="col-xl-12 col-lg-12 form-group mt-20p">
                <label>Upload CSV *</label>
                <input
                  type="file"
                  name="uploadFile"
                  accept=".csv"
                  onChange={changeFileHandler}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-xl-12 col-lg-12 form-group">
                <button type="submit" className="btn btn-success">
                  Submit
                </button>
              </div>

              <div className="col-xl-12 col-lg-12 form-group">
                <a
                  className="btn btn-primary"
                  href={require("../../assets/json/detention_shipment_bulkupload.csv")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sample Template
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
        


            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
        </div>

    );
};

export default Dashboard;
