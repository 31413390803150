
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const UpdateReverseLogistics = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onClickReverseLogisticsBtn(props);
    };
    var colr = props.data.comment_exists;
    //console.log(props.data,"Component");  
    return (

        <div>
            <button style={{color:"#fff"}} onClick={handleRouteClick} className="btn btn-danger custom-btn f12 label label-success" 
            title="" >Update</button>
        </div>
    );
};

export default UpdateReverseLogistics;
