/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import DashboardChart from "./dashboardchart";
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class DailyShipmentProgressReport extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "SIM Tracking Consents",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				// showtptgpsmap:ShowTPTGpsMap,
                // UpdateButton:UpdateButton,
                // UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
				]
			},
            seriesData: [],
            categories: [],
            ownseriesData: [],
            overallseriesData: [],
            mvsoseriesData: [],
            pinnedTotalBottomRowData:[],
            // from_date: moment.parseZone().subtract(5,'days').format("YYYY-MM-DD"),
            // to_date: moment.parseZone().format("YYYY-MM-DD"),
		};
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
    onChangeDuration(duration){
		this.setState(
			{ duration },
			// () => console.log(`duration Option selected:`, this.state.duration)
		);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        var from_date = moment().startOf('month').format('YYYY-MM-DD');
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#osfromDate").val(from_date);
		$("#ostoDate").val(to_date);
        this.onLoadPageData();
    }
    
    onLoadPageData = async () => {
		var rowData = []
        loadDateTimeScript();
        this.setState({
            loadshow:"show-m"
        })
        var from_date = $("#osfromDate").val();
		var to_date = $("#ostoDate").val();
		var params = {
			startdate : from_date,
			enddate : to_date,
		}
        if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
        {
            params.plant_code = localStorage.getItem("pc")
        }
        // console.log("params", params)
        if(from_date != undefined && from_date != "" && to_date != undefined && to_date != "")
        {
            await redirectURL.post("/carlsberg/getDailyShipmentProgressReport",params)
            .then(async(response) =>{
                var rowData = response.data;
                if(rowData.length > 0)
                {
                    rowData = rowData.sort(GetSortAscOrder("sno"));
                }
                var temp = {};
                var marketTrucks = 0;
                var marketgps = 0;
                var marketsim = 0;
                var marketTrackable = 0;
                var ownTrucks = 0;
                var owngps = 0;
                var ownsim = 0;
                var ownTrackable = 0;
                var totalTrucks = 0;
                var totalgps = 0;
                var totalsim = 0;
                var totaltrackable = 0;
                var processChk = await rowData.map((rd)=>{
                    marketTrucks = parseInt(rd.marketTrucks)+parseInt(marketTrucks);
                    marketgps = parseInt(rd.marketgps)+parseInt(marketgps);
                    marketsim = parseInt(rd.marketsim)+parseInt(marketsim);
                    marketTrackable = parseInt(rd.marketTrackable)+parseInt(marketTrackable);
                    ownTrucks = parseInt(rd.ownTrucks)+parseInt(ownTrucks);
                    owngps = parseInt(rd.owngps)+parseInt(owngps);
                    ownsim = parseInt(rd.ownsim)+parseInt(ownsim);
                    ownTrackable = parseInt(rd.ownTrackable)+parseInt(ownTrackable);
                    totalTrucks = parseInt(rd.totalTrucks)+parseInt(totalTrucks);
                    totalgps = parseInt(rd.totalgps)+parseInt(totalgps);
                    totalsim = parseInt(rd.totalsim)+parseInt(totalsim);
                    totaltrackable = parseInt(rd.totaltrackable)+parseInt(totaltrackable);
                });
                
                Promise.all([processChk]).then(async () => {
                    var marketgpsper = (marketgps)/(marketTrucks)*100;
                    if(marketgpsper > 0)
                    {
                        marketgpsper = Math.round(marketgpsper)
                    }
                    else
                    {
                        marketgpsper = 0;
                    }
                    var marketsimper = (marketsim)/(marketTrucks)*100;
                    if(marketsimper > 0)
                    {
                        marketsimper = Math.round(marketsimper)
                    }
                    else
                    {
                        marketsimper = 0;
                    }
                    var markettrackableper = (marketTrackable)/(marketTrucks)*100;
                    if(markettrackableper > 0)
                    {
                        markettrackableper = Math.round(markettrackableper)
                    }
                    else
                    {
                        markettrackableper = 0;
                    }
                    var owngpsper = (owngps)/(ownTrucks)*100;
                    var ownsimper = (ownsim)/(ownTrucks)*100;
                    var owntrackableper = (ownTrackable)/(ownTrucks)*100;
                    var marketvehicleper = (marketTrucks/totalTrucks)*100;
                    var ownvehicleper = (ownTrucks/totalTrucks)*100;
                    var totalgpsper = (totalgps/totalTrucks)*100;
                    var totalsimper = (totalsim/totalTrucks)*100;
                    var totaltrackableper = (totaltrackable/totalTrucks)*100;
                    if(owngpsper > 0)
                    {
                        owngpsper = Math.round(owngpsper)
                    }
                    else
                    {
                        owngpsper = 0;
                    }
                    if(ownsimper > 0)
                    {
                        ownsimper = Math.round(ownsimper)
                    }
                    else
                    {
                        ownsimper = 0;
                    }
                    if(owntrackableper > 0)
                    {
                        owntrackableper = Math.round(owntrackableper)
                    }
                    else
                    {
                        owntrackableper = 0;
                    }
                    if(marketvehicleper > 0)
                    {
                        marketvehicleper = Math.round(marketvehicleper)
                    }
                    else
                    {
                        marketvehicleper = 0;
                    }
                    if(ownvehicleper > 0)
                    {
                        ownvehicleper = Math.round(ownvehicleper)
                    }
                    else
                    {
                        ownvehicleper = 0;
                    }
                    if(totalgpsper > 0)
                    {
                        totalgpsper = Math.round(totalgpsper)
                    }
                    else
                    {
                        totalgpsper = 0;
                    }
                    if(totalsimper > 0)
                    {
                        totalsimper = Math.round(totalsimper)
                    }
                    else
                    {
                        totalsimper = 0;
                    }
                    if(totaltrackableper > 0)
                    {
                        totaltrackableper = Math.round(totaltrackableper)
                    }
                    else
                    {
                        totaltrackableper = 0;
                    }
                    temp.shipment_date = "Total";
                    temp.marketTrucks = marketTrucks;
                    temp.marketgps = marketgps;
                    temp.marketsim = marketsim;
                    temp.marketTrackable = marketTrackable;
                    temp.marketgpsper = marketgpsper;
                    temp.marketsimper = marketsimper;
                    temp.markettrackableper = markettrackableper;
                    temp.ownTrucks = ownTrucks;
                    temp.owngps = owngps;
                    temp.ownsim = ownsim;
                    temp.ownTrackable = ownTrackable;
                    temp.owngpsper = owngpsper;
                    temp.ownsimper = ownsimper;
                    temp.owntrackableper = owntrackableper;
                    temp.totalTrucks = totalTrucks;
                    temp.marketvehicleper = marketvehicleper;
                    temp.ownvehicleper = ownvehicleper;
                    temp.totalgps = totalgps;
                    temp.totalsim = totalsim;
                    temp.totalgpsper = totalgpsper;
                    temp.totalsimper = totalsimper;
                    temp.totaltrackable = totaltrackable;
                    temp.totaltrackableper = totaltrackableper;
                    await this.setState({
                        rowData:response.data,
                        allrowData:response.data,
                        pinnedTotalBottomRowData:[temp],
                        loadshow:"show-n"
                    })
                    await this.loadChartData();
                });
            })            
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:'From Date and To Date are required',
                basicType:"danger",
                loadshow:'show-n',
            })
        }
    }

    loadChartData = async() => {
        var rowData = this.state.rowData;
        var mktgpsData = [];
        var mktsimData = [];
        var mkttrackableData = [];
        var categories = [];
        var owngpsData = [];
        var ownsimData = [];
        var owntrackableData = [];
        var overallgpsData = [];
        var overallsimData = [];
        var overalltrackableData = [];
        var marketVehicleData = [];
        var ownVehicleData = [];
        var processChk = await rowData.map(async(m)=>{
            mktgpsData.push(m.marketgpsper);
            mktsimData.push(m.marketsimper);
            mkttrackableData.push(m.markettrackableper);
            categories.push(m.shipment_date);
            owngpsData.push(m.owngpsper);
            ownsimData.push(m.ownsimper);
            owntrackableData.push(m.owntrackableper);
            overallgpsData.push(m.totalgpsper);
            overallsimData.push(m.totalsimper);
            overalltrackableData.push(m.totaltrackableper);
            marketVehicleData.push(m.marketvehicleper);
            ownVehicleData.push(m.ownvehicleper);
        });
        Promise.all([processChk]).then(async () => {
            var seriesData = [
                {
                    name: "Mkt GPS %",
                    data: mktgpsData,
                },
                {
                    name: "Mkt SIM %",
                    data: mktsimData,
                },
                {
                    name: "Mkt Trackable %",
                    data: mkttrackableData,
                },
            ]
            var ownseriesData = [
                {
                    name: "Own GPS %",
                    data: owngpsData,
                },
                {
                    name: "Own SIM %",
                    data: ownsimData,
                },
                {
                    name: "Own Trackable %",
                    data: owntrackableData,
                },
            ]
            var overallseriesData = [
                {
                    name: "Overall GPS %",
                    data: overallgpsData,
                },
                {
                    name: "Overall SIM %",
                    data: overallsimData,
                },
                {
                    name: "Overall Trackable %",
                    data: overalltrackableData,
                },
            ]
            var mvsoseriesData = [
                {
                    name: "Market Vehicle %",
                    data: marketVehicleData,
                },
                {
                    name: "Own Vehicle %",
                    data: ownVehicleData,
                },
            ]
            this.setState({
                seriesData: seriesData,
                categories: categories,
                ownseriesData: ownseriesData,
                overallseriesData: overallseriesData,
                mvsoseriesData: mvsoseriesData,
            });
        })
    }

	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
		})
	}
	hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };

    onClickTab = (counterKey) => {
        this.setState({
            rowData : this.state[counterKey]
        });
    }
    onClickFilterData(){
        var from_date =$("#osfromDate").val();
		var to_date = $("#ostoDate").val();
        if(from_date != undefined && from_date != "" && to_date != undefined && to_date != "")
        {
            this.onLoadPageData();
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:'From Date and To Date are required',
                basicType:"danger",
                loadshow:'show-n',
            })
        }
    }
    render(){
        
		var columnWithDefs= [
            {
				headerName: "Date",
				field: "shipment_date",
                width: 100,
                filter: true,
                resizable: true,
                sortable:true,
                pinned:"left"
			},
            {
				headerName: "Market-Total",
				field: "marketTrucks",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Mkt GPS",
				field: "marketgps",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Mkt SIM",
				field: "marketsim",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Mkt Trackable",
				field: "marketTrackable",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Mkt GPS %",
				field: "marketgpsper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			}, 
            {
				headerName: "Mkt SIM %",
				field: "marketsimper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},    
            {
				headerName: "Mkt Trackable %",
				field: "markettrackableper",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Own Total",
				field: "ownTrucks",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Own GPS",
				field: "owngps",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Own SIM",
				field: "ownsim",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Own Trackable",
				field: "ownTrackable",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
            {
				headerName: "Own GPS %",
				field: "owngpsper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			}, 
            {
				headerName: "Own SIM %",
				field: "ownsimper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},    
            {
				headerName: "Own Trackable %",
				field: "owntrackableper",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},    
            {
				headerName: "Total Shipment",
				field: "totalTrucks",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			}, 
            {
				headerName: "Mkt Vehicle %",
				field: "marketvehicleper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},  
            {
				headerName: "Own Vehicle %",
				field: "ownvehicleper",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},  
            {
				headerName: "Total GPS tracking",
				field: "totalgps",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},  
            {
				headerName: "Total SIM Tracking",
				field: "totalsim",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},     
            {
				headerName: "GPS%",
				field: "totalgpsper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},  
            {
				headerName: "SIM%",
				field: "totalsimper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},  
            {
				headerName: "Trackable",
				field: "totaltrackable",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},  
            {
				headerName: "Trackable %",
				field: "totaltrackableper",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                cellStyle:{borderRight: '1px solid #ccc'}
			},
        ];
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="card">
                    <div className="card-header">
                        <h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	Shipment Tracking Status Report
                        </h5>
                    </div>
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>	
                        <div class="row">
                            <div class="col-xl-2 col-lg-2 form-group">
                                <label>From Date</label>
                                <input type="text" name="date" id="osfromDate" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/>
                            </div>
                            <div class="col-xl-2 col-lg-2form-group">
                                <label>To Date</label>
                                <input type="text" name="date" id="ostoDate" autoComplete="off" className="datetimepicker_date form-control" readonly="true"/>
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <button type="submit" onClick={this.onClickFilterData.bind(this)} class="btn btn-success mt-30p">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>	
                        <div id="myGrid" style={{width:"100%",height:"100%"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={1000}
                            pagination={false}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            // pinnedBottomRowData={this.state.pinnedTotalBottomRowData}
                            rowClassRules={this.state.rowClassRules}
                            gridOptions={{
                                context: { componentParent: this },
                                getRowStyle: function (params) {
                                    if (params.node.rowPinned) {
                                    return { 'font-weight': 'bold','font-size':'16px' };
                                    }
                                },
                            }}
                            domLayout = {'autoHeight'}
                            masterDetail={true}
                            />
                        </div>
                        <div className="row" style={{marginTop:"2em"}}>
                            <div className="col-sm-6">
                                <span>
                                    <DashboardChart
                                        title={"Market Vehicle Status"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.seriesData}
                                        categories={this.state.categories}
                                        yaxistitle={"Vehicle (%)"}
                                        drilldownseries={""}
                                    />
                                </span>
                            </div>
                            <div className="col-sm-6">
                                <span>
                                    <DashboardChart
                                        title={"Own Vehicle Status"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.ownseriesData}
                                        categories={this.state.categories}
                                        yaxistitle={"Vehicle (%)"}
                                        drilldownseries={""}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:"2em"}}>
                            <div className="col-sm-6">
                                <span>
                                    <DashboardChart
                                        title={"Overall Vehicle Status"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.overallseriesData}
                                        categories={this.state.categories}
                                        yaxistitle={"Vehicle (%)"}
                                        drilldownseries={""}
                                    />
                                </span>
                            </div>
                            <div className="col-sm-6">
                                <span>
                                    <DashboardChart
                                        title={"Market Vs Own Vehicles Deployed"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.mvsoseriesData}
                                        categories={this.state.categories}
                                        yaxistitle={"Vehicle (%)"}
                                        drilldownseries={""}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				
            </div>
              
        );
    }
}


// eslint-disable-next-line no-unused-vars
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function GetSortAscOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}