import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Datetime from 'react-datetime';
var redirectURL = require('../redirectURL');

const FilterComponent = ({ onSubmit }) => {
    const currentDate = new Date();
    const oneDayBefore = new Date(); //By default kept current date for from date and to date
    // oneDayBefore.setDate(currentDate.getDate() - 7); //7 days data from current date and time
    const [fromDate, setFromDate] = useState(oneDayBefore);
    const [toDate, setToDate] = useState(currentDate);
    const [transporter, setTransporter] = useState('');
    const [consigneeCode, setConsigneeCode] = useState('');
    const [tripStatus, setTripStatus] = useState({
        "label": "Waiting For Unload", "value": 1
    });
    const [consigneeCity, setConsigneeCity] = useState('');
    const [consigneeState, setConsigneeState] = useState('');
    const [transporterOptions, setTransporterOptions] = useState([]);
    const [consigneeCodeOptions, setConsigneeCodeOptions] = useState([]);
    const [tripStatusOptions, setTripStatusOptions] = useState([{
        "label": "Waiting For Unload", "value": 1
    }, { "label": "Trip Closure", "value": 2 }]);
    const [consigneeCityOptions, setConsigneeCityOptions] = useState([]);
    const [consigneeStateOptions, setConsigneeStateOptions] = useState([]);

    const handleSubmit = () => {
        onSubmit({
            fromDate: fromDate ? fromDate.toISOString().split('T')[0] : null,
            toDate: toDate ? toDate.toISOString().split('T')[0] : null,
            transporter,
            consigneeCode,
            consigneeCity,
            consigneeState,
            tripStatus
        });
    };

    const handleFromDateChange = (date) => {

        if (toDate && date.isAfter(toDate)) {
            alert("From Date should be less than or equal to To Date");
        } else {
            setFromDate(date);
        }
    };

    const handleToDateChange = (date) => {

        if (fromDate && date.isBefore(fromDate)) {
            alert("To Date should be greater than or equal to From Date");
        } else {
            setToDate(date);
        }
    };

    useEffect(() => {
        try {
            redirectURL.post('carlsberg/fetchFilterData')
                .then((response) => {
                    const records = response.data;
                    // console.log(records, "recordsss")
                    const trimmedStates = records.consigneStateInfo
                        .filter(state => state !== "\"" && state.trim() !== "")
                        .map(state => state.startsWith('"') ? state.slice(1).trim() : state.trim());

                    const uniqueTrimmedStates = [...new Set(trimmedStates)];

                    const trimmedCities = records.consigneCityInfo
                        .map(city => city.trim())
                        .filter(city => city !== "");

                    const uniqueTrimmedCities = [...new Set(trimmedCities)]; 

                    setTransporterOptions(records.transporter_info.map(option => ({
                        label: `${option.transporter_code} - ${option.transporter_name}`,
                        value: option.transporter_code
                    })));

                    setConsigneeCodeOptions(records.customer_info.map(option => ({
                        label: `${option.consignee_code} - ${option.consignee_name}`,
                        value: option.consignee_code
                    })));

                    setConsigneeCityOptions(uniqueTrimmedCities.map(city => ({
                        label: city,
                        value: city
                    })));

                    setConsigneeStateOptions(uniqueTrimmedStates.map(state => ({
                        label: state,
                        value: state
                    })));


                });
        }
        catch (e) {
            console.log("error", e)
        }

    }, [])

    return (
        <div className="container-fluid card p-2">
            <div className="col-xl-12 col-lg-12 d-flex justify-content-between mb-2 ml-0p">

                <div className="form-group" style={filterGroupStyle}>
                    <label>From Date</label>

                    <Datetime value={fromDate}
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: 'Select Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        name="fromDate"
                        onChange={handleFromDateChange} />
                </div>
                <div className="form-group" style={filterGroupStyle}>
                    <label>To Date</label>

                    <Datetime
                        value={toDate}
                        disableCloseOnClickOutside={false}
                        closeOnSelect={true}
                        inputProps={{ placeholder: 'Select Date', name: 'toDate', autoComplete: 'off', readOnly: true }}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        name="toDate"
                        onChange={handleToDateChange} />
                </div>

                <div className="form-group" style={filterGroupStyle}>
                    <label>Status</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={tripStatus}
                        onChange={setTripStatus}
                        options={tripStatusOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />
                </div>

                <div className="form-group" style={filterGroupStyle}>
                    <label>Transporter</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={transporter}
                        onChange={setTransporter}
                        options={transporterOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />

                </div>
                <div className="form-group" style={filterGroupStyle}>
                    <label>Dealer</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={consigneeCode}
                        onChange={setConsigneeCode}
                        options={consigneeCodeOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />
                </div>
                <div className="form-group" style={filterGroupStyle}>
                    <label>Dealer City</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={consigneeCity}
                        onChange={setConsigneeCity}
                        options={consigneeCityOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />
                </div>
                {/* <div className="form-group" style={filterGroupStyle}>
                    <label>Consignee State</label>
                    <Select
                        className="border-radius-0"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={consigneeState}
                        onChange={setConsigneeState}
                        options={consigneeStateOptions}
                        placeholder="Select"
                        styles={{
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: '12px',
                            }),
                        }}
                    />
                </div> */}
                <div className="form-group mt-25p" style={{ marginLeft: "0px" }}>
                    <button onClick={handleSubmit} style={submitButtonStyle}>Submit</button>
                </div>
            </div>
        </div>

    );
};

// Styles for the filter component
const filterContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
};

const filterGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px',
    margin: '0 10px',
};

const selectStyle = {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
};

const submitButtonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
};

export default FilterComponent;
