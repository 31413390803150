import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { escape } from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ViewPODFile from "./viewpodfileaction";
import MarkasCompletedBtn from "./btn.js";
import Logsbtn from "./logsbtn.js"
import CommentItem from "./commentItem.js"
import UpdateManualStatusBtn from "./updateManualStatusBtn.js"
import CountUp from 'react-countup';
import { func } from 'prop-types';
// import { getElockTrucksList } from '../../../../carlsberg_node/routes/controller/consignmentController';
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var Constant = require('../common/Constant');
var googleAnalytics = require("../common/googleanalytics");

const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class chettinadEPODShipments extends Component{

	constructor(props){
		super(props);
		this.state={
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                ViewPODFile:ViewPODFile,
				MarkasCompletedBtn:MarkasCompletedBtn,
				Logsbtn:Logsbtn,
				UpdateManualStatusBtn:UpdateManualStatusBtn,
				CommentItem:CommentItem
			},
            animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				
            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			transporter_code:'',
			alltransporters:[],
			components: { datePicker: getDatePicker() },
			totalpod:[],
            pendingpod:[],
            completedpod:[],
			sliderAddTransporter:"",
			rowDataforHistory:[],
			atplant:[],
			intransittocustomer:[],
			proximitycustomerlocation:[],
			nearcustomervisinity:[],
			leftcustomervisinity:[],
			reachedcustomervisinity:[],
			proximitytripclosure:[],
			possibletripclosure:[],
			intransit:[],
			atdestination:[],
			unloadingForPickup:[],
			noprocess:[],
			returnInTransit:[],
			misplace:[],
			notAvailable:[],
			nearCustomer:[],
			check:0,
			sliderForpivottable: "",
			manual_status: "",
			plantList: [],
			waitingforunload: [],
			fixedgps: [],
			gpswithdriver: [],
			gpswithtransporter: [],
			gpsdepositedatdepot: [],
			drivernotresponding: [],
			returnintransit: [],
			misplace: [],
			returned: [],
			intranit:[],
			emptystatus: [],
			totaltrucks:[]
		}
		
        this.onLoadGetShipments = this.onLoadGetShipments.bind(this);
        this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
		this.onClickMarkAsPriorityBtn = this.onClickMarkAsPriorityBtn
		this.onClickUpdateManualStatusBtn = this.onClickUpdateManualStatusBtn
	}
	componentDidMount = async () => {
        var from_date = moment.parseZone().subtract(3,'days').format("YYYY-MM-DD")
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#osfromDate").val(from_date);
		$("#ostoDate").val(to_date);
        var parameters = {
            startdate : from_date,
			enddate : to_date
        }
        this.onLoadGetShipments(parameters);
        loadDateTimeScript();
		if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("roles").indexOf("CB_HEAD") >= 0){
            redirectURL.post("/consignees/plantsData").then(async(response)=>{
                // console.log(response.data,"177")
                let selectedPlant = response.data.filter(e => e.value == localStorage.getItem("pc"))
                var resp = [{"value":"" ,"label":"ALL"}];
                var plantList = [...resp, ...response.data]
                this.setState({
                    plantList : plantList,
                })
            })
        }
    }
    onLoadGetShipments(parameters){
        this.setState({
            loadshow:"show-m"
        })
        redirectURL.post("/carlsberg/getDevicesInfo").then(async (response) =>{
			console.log(response.data,"12121")
            var devicesInfo = response.data 
			if(localStorage.getItem("pc")!=""){
				var rowData = devicesInfo.filter(e=>e.consigner_code==localStorage.getItem("pc"))
			}
			else{
				var rowData = devicesInfo
			}
			var atplant = rowData.filter(e=>e.status=="3"||e.status==3)
			console.log(atplant.length,"atplant")
			var intransittocustomer = rowData.filter(e=>e.status=="8" || e.status==8)
			var proximitycustomerlocation = rowData.filter(e=>e.status=="9" || e.status==9)
			var nearcustomervisinity = rowData.filter(e=>e.status=="10" || e.status==10)
			var reachedcustomervisinity = rowData.filter(e=>e.status=="11" || e.status==11)
			var leftcustomervisinity = rowData.filter(e=>e.status=="12" || e.status==12)
			var possibletripclosure = rowData.filter(e=>e.status=="13"||e.status==13)
			var proximitytripclosure = rowData.filter(e=>e.status=="14"||e.status==14)
			var tripcompleted = rowData.filter(e=>e.status=="15"||e.status==15)
			var waitingforunload = rowData.filter(e=>e.device_status=="Waiting for unloading")
			var fixedgps = rowData.filter(e=>e.device_status=="Fixed GPS")
			var gpswithdriver = rowData.filter(e=>e.device_status=="GPS With driver")
			var gpswithtransporter = rowData.filter(e=>e.device_status=="GPS With transporter")
			var gpsdepositedatdepot = rowData.filter(e=>e.device_status=="GPS deposited at depot")
			var drivernotresponding = rowData.filter(e=>e.device_status=="Driver not responding")
			var returnintransit = rowData.filter(e=>e.device_status=="Return in transit")
			var misplace = rowData.filter(e=>e.device_status=="Misplace")
			var returned = rowData.filter(e=>e.device_status=="Returned")
			var intranit = rowData.filter(e=>e.device_status=="In Transit")
			var emptystatus = rowData.filter(e=>e.device_status=="NA")
            this.setState({
				rowData:rowData,
				loadshow:"show-n",
				atplant:atplant,
				intransittocustomer:intransittocustomer,
				proximitycustomerlocation:proximitycustomerlocation,
				nearcustomervisinity:nearcustomervisinity,
				reachedcustomervisinity:reachedcustomervisinity,
				leftcustomervisinity:leftcustomervisinity,
				possibletripclosure:possibletripclosure,
				proximitytripclosure:proximitytripclosure,
				tripcompleted:tripcompleted,
				waitingforunload:waitingforunload,
				fixedgps:fixedgps,
				gpswithdriver:gpswithdriver,
				gpswithtransporter:gpswithtransporter,
				gpsdepositedatdepot:gpsdepositedatdepot,
				drivernotresponding:drivernotresponding,
				returnintransit:returnintransit,
				misplace:misplace,
				returned:returned,
				emptystatus:emptystatus,
				totaltrucks:rowData,
				intranit:intranit

			})
        })
    }
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "alltrips",
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = 'alltrips';


			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: "alltrips"
			}
			//console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}
	resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};
	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
	}
	onClickHide(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderAddTransporter:'',
			sliderEditTransporter:""
		});
		
    }
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
	onClickCounter(param){
        console.log("onClickCounter_parm",param)
        if(param == "0"){
			console.log("test")
            this.setState({
                rowData:this.state.atplant,
                loadshow:"show-n"
            })
        }
		if(param == "1"){
            this.setState({
                rowData:this.state.intransittocustomer,
                loadshow:"show-n"
            })
        }
		if(param == "2"){
            this.setState({
                rowData:this.state.proximitycustomerlocation,
                loadshow:"show-n"
            })
        }
		if(param == "3"){
            this.setState({
                rowData:this.state.nearcustomervisinity,
                loadshow:"show-n"
            })
        }
		if(param == "4"){
            this.setState({
                rowData:this.state.reachedcustomervisinity,
                loadshow:"show-n"
            })
        }
		if(param == "5"){
            this.setState({
                rowData:this.state.leftcustomervisinity,
                loadshow:"show-n"
            })
        }
		if(param == "6"){
            this.setState({
                rowData:this.state.possibletripclosure,
                loadshow:"show-n"
            })
        }
		if(param == "7"){
            this.setState({
                rowData:this.state.proximitytripclosure,
                loadshow:"show-n"
            })
        }
		if(param == "8"){
            this.setState({
                rowData:this.state.gpswithdriver,
                loadshow:"show-n"
            })
        }
		if(param == "9"){
            this.setState({
                rowData:this.state.gpswithtransporter,
                loadshow:"show-n"
            })
        }
		if(param == "10"){
            this.setState({
                rowData:this.state.gpsdepositedatdepot,
                loadshow:"show-n"
            })
        }
		if(param == "11"){
            this.setState({
                rowData:this.state.misplace,
                loadshow:"show-n"
            })
        }
		if(param == "12"){
            this.setState({
                rowData:this.state.returnintransit,
                loadshow:"show-n"
            })
        }
		if(param == "13"){
            this.setState({
                rowData:this.state.returned,
                loadshow:"show-n"
            })
        } 
		if(param == "18"){
            this.setState({
                rowData:this.state.intranit,
                loadshow:"show-n"
            })
        }
		if(param == "14"){
            this.setState({
                rowData:this.state.waitingforunload,
                loadshow:"show-n"
            })
        }
		if(param == "15"){
            this.setState({
                rowData:this.state.fixedgps,
                loadshow:"show-n"
            })
        }
		if(param == "16"){
            this.setState({
                rowData:this.state.drivernotresponding,
                loadshow:"show-n"
            })
        }
		if(param == "17"){
            this.setState({
                rowData:this.state.emptystatus,
                loadshow:"show-n"
            })
        }
		if(param == "18"){
            this.setState({
                rowData:this.state.totaltrucks,
                loadshow:"show-n"
            })
        }
		
    }
	onCellUpdateData = (params) => {
        
	}
	onCellValueChanged=(params)=>{
		var newValue = params.newValue 
		this.setState({manual_status:newValue})
	}
	onClickUpdateManualStatusBtn(rowdata){
		this.setState({loadshow:'show-n'})
		var row = rowdata.data 
		var manualStatus = row.manual_status 
		var comment = row.comment
		console.log(comment,"comment11")
		var deviceId = row.device_id 
		var shipmentId = row.shipment_id 
		var params = {
			deviceId:deviceId,
			shipmentId:shipmentId,
			manualStatus:manualStatus,
			comment:comment
		}
		var email = localStorage.getItem("email")
		params.updatedBy = email 
		var updatedOn = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
		params.updatedOn = updatedOn 
		params.email = localStorage.getItem("email")
		redirectURL.post("/carlsberg/updateManualStatus",params).then((resp)=>{
			if(resp.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully Completed",
                    basicType:"success",
                })
				var parameters = {}
				this.onLoadGetShipments(parameters);
            }
		})
	}
	onClickMarkAsPriorityBtn(rowdata){
		var row = rowdata.data 
		var deviceId = row.device_id 
		var shipmentId = row.shipment_id 
		var params= {
			deviceId:deviceId,
			shipmentId:shipmentId
		}
		var email = localStorage.getItem("email")
		params.updatedBy = email 
		var updatedOn = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
		params.updatedOn = updatedOn 
		console.log(params,"paramsfordevicess")
		redirectURL.post("/carlsberg/saveReverseLogisticsStatus",params).then((resp)=>{
			var devices = resp.data.devices 
			console.log(devices,"devices")
			if(resp.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully Completed",
                    basicType:"success"
                })
				var parameters = {}
				this.onLoadGetShipments(parameters);
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed",
                    basicType:"danger"
                })
            }

		})
	}
	onClickLogsBtn(rowdata){
		var row = rowdata.data 
		var params = {
			device_id:row.device_id
		}
		redirectURL.post("/carlsberg/getDeviceHistory",params).then((response) => {
			if(response.data.report_data!="" && response.data.report_data!=undefined){
				var data = JSON.parse(response.data.report_data)
			}
			console.log(data,"data1212")
			this.setState({
				rowDataforHistory:data
			})
			this.setState({
				sliderAddTransporter:'slider-translate',
				overly:"show-m"
			});

		})
	}
    onClickUpdateItem(params){
        redirectURL.post("/carlsberg/updatecustomerpod",params.data)
        .then((response) => {
            if(response.data.status == "success")
            {
                this.setState({
                    show:true,
                    basicTitle:"Successfully update data",
                    basicType:"success"
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to update data",
                    basicType:"danger"
                })
            }
        })
    }
	render(){
		var columnwithDefs = [
			{
				headerName: "",
				field: "",				
				width: 70,
				filter: true,resizable: true,
				cellRendererSelector:function(params){	
					var rendComponent = {
						component: 'Logsbtn'
					};
					return rendComponent;
				},
			},
            {
                headerName:"Device ID",
                field:"device_id",
                width:130,
            },
			{
                headerName:"Sim Number",
                field:"mobile_no",
                width:130,
            },
			{
                headerName:"Shipment ID",
                field:"shipment_id",
                width:130,
            },
			{
                headerName:"Shipment Time",
                field:"shipment_time",
                width:150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.shipment_time != "" && params.data.shipment_time != "NA" && params.data.shipment_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },

            },
			{
                headerName:"Truck No",
                field:"truck_no",
                width:130,
            },
			{
                headerName:"City",
                field:"city",
                width:130,
            },
			{
                headerName:"Trip Status",
                field:"status",
                width:150,
				valueGetter:function(params){
					try {
						var status = "NA"
						if (params.data.status == 3 || params.data.status == "3") {
							status = "At Plant";
						}
						if (params.data.status == 8 || params.data.status == "8") {
							status = "Intransit To Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer Vicinity";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer Vicinity";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Clouser";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Clouser";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							status = "Trip Completed";
						}
						return status
					}
                    catch(e){}
                }
            },
			{
                headerName:"Consigner Code",
                field:"consigner_code",
                width:150,
				valueGetter:function(params){
					if(params.data.consigner_code=="205-FG"){
						return "Alwar"
					}
					else if(params.data.consigner_code=="301-FG"){
						return "Aurangabad"
					}
					else if(params.data.consigner_code=="501-FG"){
						return "Hyderabad"
					}
					else if(params.data.consigner_code=="904-FG"){
						return "Mysore"
					}
					else if(params.data.consigner_code=="801-FG"){
						return "Unnao Copack"
					}
					else if(params.data.consigner_code=="230"){
						return "Hapur Ware House"
					}
				}
            },
			{
				headerName: "Device Status",
				field: "device_status",
				resizable:true,
				width: 130,
				filter: true,  
				
				editable:true,
				// cellEditor: 'agSelectCellEditor',
				// cellEditorParams: {
				// 	values: [
				// 		'Waiting for unloading',
				// 		'Fixed GPS',
				// 		'GPS With driver',
				// 		'GPS With transporter',
				// 		'GPS deposited at depot',
				// 		'Driver not responding',
				// 		'Return in transit',
				// 		'Misplace',
				// 		'Returned'
						
				// 	],
				// },
			},
			{
				headerName: "Reverse Logistics by",
				field: "reverse_logistics_by",
				resizable:true,
				
				width: 150,
				filter: true,
			},
			{
				headerName: "Remarks",
				field: "remarks",
				resizable:true,
				width: 150,
				filter: true,
			},
			{
				headerName: "Manual Status",
				field: "manual_status",
				resizable:true,
				width: 130,
				filter: true,  
				pinned: 'right',
				editable:true,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: [
						'At Destination',
						'At Plant',
						'In Transit',
						'Misplace/Misroute',
						'Return In Transit',
						'Unloaded/Ready for PickUp'
						
					],
				},
			},
			{
				headerName: "Comment",
				field: "comment",
				resizable:true,
				pinned: 'right',
				width: 100,
				filter: false,
			    // cellRenderer:'CommentItem',
				editable:true
			  },
			{
				headerName: "Update",
				field: "",				
				width: 150,
				pinned: 'right',
				filter: true,resizable: true,
				cellRendererSelector:function(params){	
					var rendComponent = {
						component: 'UpdateManualStatusBtn'
					};
					return rendComponent;
				},
						
			},
			{
				headerName: "Reverse Logistics Status",
				field: "device_manual_status",				
				width: 150,
				pinned: 'right',
				filter: true,resizable: true,
				cellRendererSelector:function(params){
					if(params.data.status==10 || params.data.status==11 || params.data.status==12){
						var rendComponent = {
							component: 'MarkasCompletedBtn'
						};
						return rendComponent;
					}
				},					
			},
        ]
		var columnwithDefsHistory = [
			{
                headerName:"Device ID",
                field:"device_id",
                width:150,
            },
			{
                headerName:"Shipment ID",
                field:"shipment_id",
                width:150,
            },
			{
				headerName: "Shipment Time",
				field: "shipment_time",
				width: 150,
				valueGetter:function(params){
                    try{
                       
                        if(params.data.shipment_time != "" && params.data.shipment_time != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.shipment_time);
                        }
                        else{
                            return "Not Available";
                        }
                    }
                    catch(e){}
                },
			},
			{
                headerName:"Status",
                field:"status",
                width:120,
				valueGetter:function(params){
					try {
						var status = "NA"
						if (params.data.status == 3 || params.data.status == "3") {
							status = "At Plant";
						}
						if (params.data.status == 8 || params.data.status == "8") {
							status = "Intransit To Customer";
						}
						if (params.data.status == 9 || params.data.status == "9") {
							status = "Proximity Customer Location";
						}
						if (params.data.status == 10 || params.data.status == "10") {
							status = "Near Customer Vicinity";
						}
						if (params.data.status == 11 || params.data.status == "11") {
							status = "Reached Customer Vicinity";
						}
						if (params.data.status == 12 || params.data.status == "12") {
							status = "Left Customer Vicinity";
						}
						if (params.data.status == 13 || params.data.status == "13") {
							status = "Possible Trip Clouser";
						}
						if (params.data.status == 14 || params.data.status == "14") {
							status = "Proximity Trip Clouser";
						}
						if (params.data.status == 15 || params.data.status == "15") {
							status = "Trip Completed";
						}
						return status
					}
                    catch(e){}
                }
            },
			{
                headerName:"Customer Name",
                field:"consignee_name",
                width:150,
            },
			{
                headerName:"Customer City",
                field:"consignee_city",
                width:150,
            },
			{
                headerName:"Customer State",
                field:"consignee_state",
                width:150,
            },
			{
                headerName:"Customer Code",
                field:"consignee_code",
                width:150,
            },
			{
                headerName:"Customer Pincode",
                field:"consignee_pincode",
                width:150,
            },

		]	
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				
				<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
					<div className="card">
						<div className="card-body" id="counterCardBody">
							<div className="crm-numbers pb-0">
								<div className="row">
								<div className="col cursorPointer pt-1px" onClick={this.onClickCounter.bind(this, "18")}>
										<span className="f13"><br />Total</span>
										<h4 className="txt-success f30"><span className="counter"><CountUp end={this.state.totaltrucks.length} /></span></h4>
									</div>
									<div className="col cursorPointer pt-1px" onClick={this.onClickCounter.bind(this, "0")}>
										<span className="f13"><br />At Plant</span>
										<h4 className="txt-primary f30"><span className="counter"><CountUp end={this.state.atplant.length} /></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"1")}>
										<span className='f13'><br/>Intransit To Customer</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.intransittocustomer.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"2")}>
										<span className='f13'><br/>Proximity Customer Location</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.proximitycustomerlocation.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"3")}>
										<span className='f13'><br/>Near Customer Visinity</span>
										<h4 className='txt-danger f30'><span className='counter'><CountUp end={this.state.nearcustomervisinity.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"4")}>
										<span className='f13'><br/>Reached Customer Visinity</span>
										<h4 className='txt-warning f30'><span className='counter'><CountUp end={this.state.reachedcustomervisinity.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"5")}>
										<span className='f13'><br/>Left Customer Visinity</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.leftcustomervisinity.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"6")}>
										<span className='f13'><br/>Possible Trip Closure</span>
										<h4 className='txt-primary f30'><span className='counter'><CountUp end={this.state.possibletripclosure.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-1px' onClick={this.onClickCounter.bind(this,"7")}>
										<span className='f13'><br/>Proximity Trip Closure</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.proximitytripclosure.length}/></span></h4>
									</div>
									
								</div>
							</div>
						</div>
		
					</div>
					<div className="card">
						<div className="card-body" id="counterCardBody">
							<div className="crm-numbers pb-0">
								<div className="row">
								<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"13")}>
										<span className='f13'><br/>Returned At Plant</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.returned.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"18")}>
										<span className='f13'><br/>In Transit</span>
										<h4 className='txt-danger f30'><span className='counter'><CountUp end={this.state.intranit.length}/></span></h4>
									</div>
									<div className="col cursorPointer pt-2px" onClick={this.onClickCounter.bind(this, "8")}>
										<span className="f13"><br />GPS With Driver</span>
										<h4 className="txt-secondary f30"><span className="counter"><CountUp end={this.state.gpswithdriver.length} /></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"9")}>
										<span className='f13'><br/>GPS With Transporter</span>
										<h4 className='txt-danger f30'><span className='counter'><CountUp end={this.state.gpswithtransporter.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"10")}>
										<span className='f13'><br/>GPS Deposited With Depot</span>
										<h4 className='txt-warning f30'><span className='counter'><CountUp end={this.state.gpsdepositedatdepot.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"11")}>
										<span className='f13'><br/>Misplace</span>
										<h4 className='txt-info f30'><span className='counter'><CountUp end={this.state.misplace.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"12")}>
										<span className='f13'><br/>Return In transit</span>
										<h4 className='txt-warning f30'><span className='counter'><CountUp end={this.state.returnintransit.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"14")}>
										<span className='f13'><br/>Waiting For Unloading</span>
										<h4 className='txt-primary f30'><span className='counter'><CountUp end={this.state.waitingforunload.length}/></span></h4>
									</div>
									{/* <div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"15")}>
										<span className='f13'><br/>Fixed GPS</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.fixedgps.length}/></span></h4>
									</div> */}
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"16")}>
										<span className='f13'><br/>Driver Not Responding</span>
										<h4 className='txt-secondary f30'><span className='counter'><CountUp end={this.state.drivernotresponding.length}/></span></h4>
									</div>
									<div className='col cursorPointer pt-2px' onClick={this.onClickCounter.bind(this,"17")}>
										<span className='f13'><br/>NA</span>
										<h4 className='txt-primary f30'><span className='counter'><CountUp end={this.state.emptystatus.length}/></span></h4>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
						<div className="card-header">
				       			<h5>
									
									<span className="">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</h5>
				   			</div>		
							<div className="card-body">
								<div className="row col-xl-12 col-lg-12">
								</div>
								<div className="row col-xl-12 col-lg-12">
									<div className="col-xl-12 col-lg-12">
										<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnwithDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												enableRangeSelection={true}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												components={this.state.components}
												frameworkComponents={this.state.frameworkComponents}

												//floatingFilter={true}
												onCellEditingStopped={this.onCellUpdateData.bind(this)}
												//onCellClicked={this.popmarker}
												gridOptions={{
													context: { componentParent: this }
												}}
												onCellClicked={this.handleCellClicked}
												onCellValueChanged={this.onCellValueChanged}
											/>
										</div>
										{/* <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={"dataLoadpage " + (this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r " + (this.state.sliderAddTransporter)} style={{ overflow: "auto", width: "65%", padding: "2%" }}>
					<div className="slide-r-title">
						<h4>
							Device History
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHide.bind(this)} >X</span>
						</h4>
					</div>
					<div>
						<div id="myGrid" style={{ height: "500px", width: "100%" }} className="ag-theme-balham">
							<AgGridReact
								modules={this.state.modules}
								columnDefs={columnwithDefsHistory}
								defaultColDef={this.state.defaultColDef}
								rowData={this.state.rowDataforHistory}
								enableCharts={false}
								enableRangeSelection={true}
								autoGroupColumnDef={this.state.autoGroupColumnDef}
								onGridReady={this.onGridReady}
								onGridState={this.onGridState}
								statusBar={this.state.statusBar}
								sideBar={this.state.sideBar}
								paginationPageSize={this.state.paginationPageSize}
								pagination={true}
								components={this.state.components}
								frameworkComponents={this.state.frameworkComponents}

								//floatingFilter={true}
								onCellEditingStopped={this.onCellUpdateData.bind(this)}
								//onCellClicked={this.popmarker}
								gridOptions={{
									context: { componentParent: this }
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  $(this.eInput).datepicker({ dateFormat: 'dd-mm-yy' });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


  function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function dateComparator(date1, date2) {
	
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}