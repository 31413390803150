/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import SndUnloadingColumnChart from "./sndUnloadingColumnChart";
import DrillDownBarChart from "./drillDownBarChart";
import BarChart from "./barChart";
import PieChartComponent from "./pieChart";
import LineChartComponent from "./lineChart"
import PyramidChartComponent from "./pyramidChart"
import { func } from 'prop-types';
// import { updateUnMapCarlsbergDeviceInfo } from '../../../../carlsberg_node/routes/controller/carlsbergController';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
var moment = require('moment');
export default class CBDashboard extends Component {

    constructor(props) {
        super(props);
        this.state={
            locations:[],
            customers:[],
            plantList:[],
            plantcode :{value:"all",label:"ALL"},
            location:'',
            customer:'',
            startDate:'2022-12-01',
            endDate:moment.parseZone().format("YYYY-MM-DD"),
            modules:AllModules,
            defaultColDef: {
                sortable: true,
              filter: true,
              resizable: true,
            },
            rowData: [],
            paginationPageSize:1000,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
            columnwithDefs:[{
                headerName:"Plant Name",

            }],
            loadshow : "show-m",
            overly : "show-m",
            filterCustomers : [],
            list_of_trucks:[],
            report_data:[],
            showFirstLevel : "show-n",
            plantwiseCategory:[],
            unloadingdata:[],
            unloadingcategories:[],
            unloaddrilldown : {},
            drilldownseries:[],
            chart_type:"",
            chart_hit_count:0,
            piechartSeriesData : [],
            piechartSeriesCategories:[],
            seriesDataforLineGraph:[],
            categoriesforline:[],
            showgraph:true,
            showdatabtn:"show-m",
            showgraphbtn:"show-n",
            stoppagesData:[],
            stoppagesCategory:[],
            plants:[],
            transitdelayCategory:[],
            transitDelayData:[]
        }
        this.formatChartData = this.formatChartData.bind(this);
    }
    componentDidMount(){
        this.setState({
            loadshow : "show-n",
            overly : "show-n"
        })
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }
        var customerCode = this.state.customer 
        var plantCode = this.state.plantcode 
        
        var reqparams = {
            from_date : startDate,
            to_date : endDate,
        }
        if(customerCode!="" && customerCode!=undefined){
            reqparams.consignee_code = customerCode.value
        }
        if(plantCode!="" && plantCode!=undefined){
            if(plantCode.value !="" && plantCode.value !=undefined && plantCode.value !="all")
            {
                reqparams.consigner_code = plantCode.value
            }
        }
        console.log(reqparams,"reqparamsforunloading");
        redirectURL.post("/carlsberg/getSndUnloadChartData",{"formdata":reqparams,"consignee_code":customerCode.value}).then((resp)=>{
            console.log(resp.data,"response")
            var list_of_trucks = JSON.parse(resp.data.chartdata.list_of_trucks);
            var report_data = JSON.parse(resp.data.chartdata.report_data);
            console.log(report_data,"report_data")
            this.setState({
                list_of_trucks:list_of_trucks,
                report_data:report_data
            })
            this.formatChartData()
        }).catch((e)=>{
            console.log(e);
        })
       this.getTripReportData()
        this.getRouteDeviationData()
        this.getStoppagesData()
        this.getTransitDelayData()
        if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0 || localStorage.getItem("roles").indexOf("CB_HEAD") >= 0){
            redirectURL.post("/consignees/plantsData").then(async(response)=>{
                // console.log(response.data,"177")
                let selectedPlant = response.data.filter(e => e.value == localStorage.getItem("pc"))
                var resp = [{"value":"" ,"label":"ALL"}];
                var plantList = [...resp, ...response.data]
                var plants = [...response.data]
                this.setState({
                    plantList : plantList,
                    plantcode: selectedPlant[0],
                    plants:plants
                })
            })
        }
        this.OnLoadCustomers();
    }
    getTransitDelayData(){
        var paramsforTransitDelay = {

        }
        var plantCode = this.state.plantcode
        if(plantCode!="" && plantCode!=undefined){
            if(plantCode.value !="" && plantCode.value !=undefined && plantCode.value !="all")
            {
                paramsforTransitDelay.consigner_code = plantCode.value
            }
        }
        redirectURL.post("/carlsberg/getTransitDelayData",paramsforTransitDelay).then((resp)=>{ 
            if(resp.data.report_data!="" && resp.data.report_data!=undefined){
                var transitDelayData = JSON.parse(resp.data.report_data)
            }
            else{
                var transitDelayData = []
            }
            console.log(transitDelayData,"transitDelayData")         
            var count = []
            var transitDelay_less_10 = transitDelayData.filter(e=>Math.ceil(e.eta_diff)<=10)
            count.push(transitDelay_less_10.length)
            var transitDelay_10_to_24 = transitDelayData.filter(e=>Math.ceil(e.eta_diff)>10 && Math.ceil(e.eta_diff)<=24)
            count.push(transitDelay_10_to_24.length)
            var transit_one_two_days = transitDelayData.filter(e=>Math.ceil(e.eta_diff)>24 && Math.ceil(e.eta_diff)<=48)
            count.push(transit_one_two_days.length)
            var transit_two_five_days = transitDelayData.filter(e=>Math.ceil(e.eta_diff)>48 && Math.ceil(e.eta_diff)<=120)
            count.push(transit_two_five_days.length)
            var above_5_days = transitDelayData.filter(e=>Math.ceil(e.eta_diff)>120)
            count.push(above_5_days.length)
            var transitDelayData = [
                {
                    name: plantCode.label,
                    data: count,
                    color:"#FFBB16"
                }
            ]
            console.log(count,"counttt")
            var transitdelayCategory = ["Less than 10hrs","10-24hrs","1-2days","2-5days","Above 5days"]
            this.setState({
                transitDelayData:transitDelayData,
                transitdelayCategory:transitdelayCategory
            })
        })  
    }
    getStoppagesData(){
        var paramsForStoppages = {

        }
        var plantCode = this.state.plantcode
        if(plantCode!="" && plantCode!=undefined){
            if(plantCode.value !="" && plantCode.value !=undefined && plantCode.value !="all")
            {
                paramsForStoppages.consigner_code = plantCode.value
            }
        }
        redirectURL.post("/carlsberg/getSndUnloadStoppagesData",paramsForStoppages).then((resp)=>{ 
            if(resp.data.report_data!="" && resp.data.report_data!=undefined){
                var stoppagesData = JSON.parse(resp.data.report_data)
            }
            else{
                var stoppagesData = []
            }
            console.log(stoppagesData,"stoppagesData")         
            var count = []
            var stoppage_less_10 = stoppagesData.filter(e=>Math.ceil(e.stoppage_duration_hrs)<=10)
            count.push(stoppage_less_10.length)
            var stoppage_10_to_24 = stoppagesData.filter(e=>Math.ceil(e.stoppage_duration_hrs)>10 && Math.ceil(e.stoppage_duration_hrs)<=24)
            count.push(stoppage_10_to_24.length)
            var sto_one_two_days = stoppagesData.filter(e=>Math.ceil(e.stoppage_duration_hrs)>24 && Math.ceil(e.stoppage_duration_hrs)<=48)
            count.push(sto_one_two_days.length)
            var sto_two_five_days = stoppagesData.filter(e=>Math.ceil(e.stoppage_duration_hrs)>48 && Math.ceil(e.stoppage_duration_hrs)<=120)
            count.push(sto_two_five_days.length)
            var above_5_days = stoppagesData.filter(e=>Math.ceil(e.stoppage_duration_hrs)>120)
            count.push(above_5_days.length)
            var stoppagesData = [
                {
                    name: plantCode.label,
                    data: count,
                    color:"red"
                }
            ]
            var stoppagesCategory = ["Less than 10hrs","10-24hrs","1-2days","2-5days","Above 5days"]
            this.setState({
                stoppagesData:stoppagesData,
                stoppagesCategory:stoppagesCategory
            })
        })  
    }
    getRouteDeviationData(){
        var endDate = moment.parseZone().format("YYYY-MM-DD") 
        var from_date = moment.parseZone().subtract(8,"days").format("YYYY-MM-DD")
        var paramsforlinegraph = {
            from_date : from_date,
            to_date : endDate
        }
        redirectURL.post("/carlsberg/getSndUnloadLineGraphData",).then((resp)=>{
            var data = JSON.parse(resp.data.report_data)
            var records = data 
            var results = enumerateDaysBetweenDates1(from_date, endDate);
            var dates = results 
            var all_plants = []
            var alwar = records.filter(e=>e.consigner_code=="205-FG")
            all_plants.push(alwar)
            var aurangabad = records.filter(e=>e.consigner_code=="301-FG")
            all_plants.push(aurangabad)
            var hyderabad = records.filter(e=>e.consigner_code=="501-FG")
            all_plants.push(hyderabad)
            var mysore = records.filter(e=>e.consigner_code=="904-FG")
            all_plants.push(mysore)
            var unnao = records.filter(e=>e.consigner_code=="801-FG")
            console.log(unnao.length,"unnolength")
            all_plants.push(unnao)
            var hapur = records.filter(e=>e.consigner_code=="230")
            all_plants.push(hapur)
            
            var plantsCountDateWise = []
            all_plants.map(function(each){
                var result= []
                var eachPlantData = groupBy(each , e => moment.parseZone(new Date(e.deviation_time)).format("YYYY-MM-DD"))
                dates.map(f=>{
                 var u = 0
                 eachPlantData.forEach((e,key)=>{
                     if(f==key){
                         u+=e.length
                     }
                 })
                 result.push(u)
                })
                plantsCountDateWise.push(result)
            })
           console.log(plantsCountDateWise,"plantsCountDateWise")
           var categoriesforline = dates
           var plants = this.state.plants 
           var seriesDataforLineGraph = plants.map(function(plantName, index) {
            return { name: plantName.label, data: plantsCountDateWise[index] };
          });
            this.setState({
                seriesDataforLineGraph:seriesDataforLineGraph,
                categoriesforline:categoriesforline
            })
            console.log(categoriesforline,"categoriesforline")
            console.log(seriesDataforLineGraph,"seriesDataforLineGraph")
        })
    }
    getTripReportData(){
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }
        var params = {
            from_date : startDate,
            to_date : endDate,
        }
        var plantCode = this.state.plantcode
        if(plantCode!="" && plantCode!=undefined){
            if(plantCode.value !="" && plantCode.value !=undefined && plantCode.value !="all")
            {
                params.consigner_code = plantCode.value
            }
        }
        redirectURL.post("/carlsberg/getSndUnloadPieChartData",{"formdata":params}).then((resp)=>{
            var data = resp.data 
            var piedata = []
            var data1 = data.filter(e=>e.status==3 || e.status=="3")
            piedata.push({ name: 'At Plant', y: data1.length, showTooltip: 1,color:"#FF0000" })
            var data2 = data.filter(e=>e.status==8 || e.status=="8")
            piedata.push({ name: 'Intransit To Customer', y: data2.length, showTooltip: 1,color:"#FF7300" })
            var data3 = data.filter(e=>e.status==9 || e.status=="9")
            piedata.push({ name: 'Proximity Customer Location', y: data3.length, showTooltip: 1 ,color:"#007ED6"})
            var data4 = data.filter(e=>e.status==10 || e.status=="10")
            piedata.push({ name: 'Near Customer', y: data4.length, showTooltip: 1,color:"#FFEC00" })
            var data5 = data.filter(e=>e.status==11 || e.status=="11")
            piedata.push({ name: 'Reached Customer', y: data5.length, showTooltip: 1,color:"#FB13F3" })
            var data6 = data.filter(e=>e.status==12 || e.status=="12")
            // piedata.push({ name: 'Left Customer Vicinity', y: data6.length, showTooltip: 1,color:"#FED60A" })
            var categories = piedata.map((each)=>(each.name))
            this.setState({
                piechartSeriesData:piedata,
                piechartSeriesCategories:categories
              })
        })
    }
	OnLoadCustomers(){
		var plant_code ="";
		if(localStorage.getItem("pc") != undefined && localStorage.getItem("pc") != "undefined" && localStorage.getItem("pc") != "" &&localStorage.getItem("pc") != null){
			plant_code = localStorage.getItem("pc")
		}
		var params ={
			plant_code: plant_code
		}
		redirectURL.post('/consignees/customers',params)    
		.then((response) => {
		    var records = JSON.parse(JSON.stringify(response)).data;
            var customers = [{label:"All",value:""}]
            records.map(function (c) {
                customers.push({
                    label: c.consignee_code+" - "+c.consignee_name,
                    value: c.consignee_code,
                });
            });
            //console.log("records", records);
            this.setState({
                customers:customers
            });
		})
		.catch(function (error) {
		  console.log(error);
        });
	}
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    renderMapWeather = () => {    	
        loadScriptWeather("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapWeather");
        window.initMapWeather = this.initMapWeather
    }
    onSelectState = state =>{
        console.log(state);
		var locations = this.state.orginal_cities_list
        var cites = []
        cites.push({label:'ALL' , value : "all"})
        locations.filter(e => {
            if (state.label != 'ALL'){
                if (e._id.consignee_state === state.value){
                    cites.push({label:e._id.consignee_city , value : e._id.consignee_city})
                }
            }
            else{
                cites.push({label:e._id.consignee_city , value : e._id.consignee_city})
            }
        })
        console.log(cites.length , "631")
        this.setState({
            selectedState : state,
            locations:cites
        });
    }
    onSelectTNPPlant = plant =>{
        console.log(plant);
        if(plant.value != "all")
        {
            var plant = plant;
        }
        else
        {
            var plant = "";
        }
        this.setState({
            selectedTnpPlant : plant
        })
    }
    formatChartData()
    {  
        var reportData = this.state.report_data 
        var unloadingdata = [];
        var unloadingcategories = [];
        var plantwiseCategory=["waiting for unloading < 1","waiting for unloading > 1","waiting for unloading > 2","waiting for unloading > 15","waiting for unloading > 18"];
        var drilldownseries = [];
        var plantCheck = 0
        if(this.state.plantcode !=undefined && this.state.plantcode !="")
        {
            if(this.state.plantcode.value !=undefined && this.state.plantcode.value !="" && this.state.plantcode != "all")
            {
                plantCheck = 1;
            }
        }
        console.log(plantCheck,"plantCheck")
        if(plantCheck == 1)
        {
            var plantname = this.state.plantcode 
            console.log(plantname,"plant555")
            var total = 0 
            var greaterthanone = 0 
            var greaterthantwo = 0
            var greaterthanfifteen = 0 
            var greaterthaneighteen = 0
            if(reportData!="" && reportData!=undefined){
                reportData.map(function(e){
                    total+=e["waiting for unloading < 1 day"] 
                    greaterthanone += e["waiting for unloading > 1 day"] 
                    greaterthantwo += e["waiting for unloading > 2 days"] 
                    greaterthanfifteen += e["waiting for unloading > 15 days"]
                    greaterthaneighteen += e["waiting for unloading > 18 days"]
                })
            unloadingdata.push({
                "name":plantwiseCategory[0],
                "y":parseFloat(total),
                "drilldown":plantwiseCategory[0],
                "color": '#00FF00',
            })
            unloadingdata.push({
                "name":plantwiseCategory[1],
                "y":parseFloat(greaterthanone),
                "drilldown":plantwiseCategory[1],
                "color": '#FF2E7E',
            })
            unloadingdata.push({
                "name":plantwiseCategory[2],
                "y":parseFloat(greaterthantwo),
                "drilldown":plantwiseCategory[2],
                "color": '#6050DC',
            })
            unloadingdata.push({
                "name":plantwiseCategory[3],
                "y":parseFloat(greaterthanfifteen),
                "drilldown":plantwiseCategory[3],
                "color": '#FFA500',
            })
            unloadingdata.push({
                "name":plantwiseCategory[4],
                "y":parseFloat(greaterthaneighteen),
                "drilldown":plantwiseCategory[4],
                "color": '#d93a25',
            })
            
            var waiting_for_unloading_less_than_1 = reportData.filter((k)=>k["waiting for unloading < 1 day"] > 0);
            var waiting_for_unloading_greater_than_1 = reportData.filter((k)=>k["waiting for unloading > 1 day"] > 0);
            var waiting_for_unloading_less_than_2 = reportData.filter((k)=>k["waiting for unloading > 2 days"] > 0);
            var waiting_for_unloading_less_than_15 = reportData.filter((k)=>k["waiting for unloading > 15 days"] > 0);
            var waiting_for_unloading_less_than_18 = reportData.filter((k)=>k["waiting for unloading > 18 days"] > 0);
            // DRILL DOWN STARTS
            var data1=[]
            waiting_for_unloading_less_than_1.map((wf)=>{
                data1.push([wf["consignee_city"],Number(wf["waiting for unloading < 1 day"])]);
            })
            var data2=[]
            waiting_for_unloading_greater_than_1.map((wf)=>{
                data2.push([wf["consignee_city"],Number(wf["waiting for unloading > 1 day"])]);
            })
            var data3=[]
            waiting_for_unloading_less_than_2.map((wf)=>{
                data3.push([wf["consignee_city"],Number(wf["waiting for unloading > 2 days"])]);
            })
            var data4=[]
            waiting_for_unloading_less_than_15.map((wf)=>{
                data4.push([wf["consignee_city"],Number(wf["waiting for unloading > 15 days"])]);
            })
            var data5=[]
            waiting_for_unloading_less_than_18.map((wf)=>{
                data5.push([wf["consignee_city"],Number(wf["waiting for unloading > 18 days"])]);
            })
            }
            console.log(data1,"data1")
            var drilldownseries= [
                {
                    name: plantwiseCategory[0],
                    id: plantwiseCategory[0],
                    data: data1
                },
                {
                    name: plantwiseCategory[1],
                    id: plantwiseCategory[1],
                    data: data2
                },
                {
                    name: plantwiseCategory[2],
                    id: plantwiseCategory[2],
                    data: data3
                },
                {
                    name: plantwiseCategory[3],
                    id: plantwiseCategory[3],
                    data: data4
                },
                {
                    name: plantwiseCategory[4],
                    id: plantwiseCategory[4],
                    data: data5
                },
            ]
            // DRILL DOWN ENDS
            var unloadingcategories = plantwiseCategory;
            console.log(unloadingdata,"unloadingdata")
            var chart_type = 1;
        }
        else
        {
            var plantCode = this.state.plantcode
            var plantData = []
            var plantwisedata = groupBy(reportData,rdata => rdata.consigner_name)
            console.log(plantwisedata,"plantwisedata")
            var ulcategories = [];
            plantwisedata.forEach((values,key) => {
                ulcategories.push(key);
                var total = 0 
                var greaterthanone = 0 
                var greaterthantwo = 0
                var greaterthanfifteen = 0 
                var greaterthaneighteen = 0
                var data1=[]
                var data2=[]
                var data3=[]
                var data4=[]
                var data5=[]
                values.map(function(e){
                    total+=e["waiting for unloading < 1 day"] 
                    greaterthanone += e["waiting for unloading > 1 day"] 
                    greaterthantwo += e["waiting for unloading > 2 days"] 
                    greaterthanfifteen += e["waiting for unloading > 15 days"]
                    greaterthaneighteen += e["waiting for unloading > 18 days"]
                    if(e["waiting for unloading < 1 day"] > 0)
                    {
                        data1.push([e["Depot Name"],e["waiting for unloading < 1 day"],Number(e["waiting for unloading < 1 day"])]);
                    }
                    if(e["waiting for unloading > 1 day"] > 0)
                    {
                        data2.push([e["Depot Name"],e["waiting for unloading > 1 day"],Number(e["waiting for unloading > 1 day"])]);
                    }
                    if(e["waiting for unloading > 2 days"] > 0){
                        data3.push([e["Depot Name"],e["waiting for unloading > 2 days"],Number(e["waiting for unloading > 2 days"])]);
                    }
                    if(e["waiting for unloading > 15 days"] > 0){
                        data4.push([e["Depot Name"],e["waiting for unloading > 15 days"],Number(e["waiting for unloading > 15 days"])]);
                    }
                    if(e["waiting for unloading > 18 days"] > 0){
                        data5.push([e["Depot Name"],e["waiting for unloading > 18 days"],Number(e["waiting for unloading > 18 days"])]);
                    }
                })
                unloadingdata.push({
                    "name":key,
                    "data" : [
                        {
                            "name":plantwiseCategory[0],
                            "y":parseFloat(total),
                            "drilldown":key+"_"+plantwiseCategory[0],
                            "color": '#00FF00',
                        },
                        {
                            "name":plantwiseCategory[1],
                            "y":parseFloat(greaterthanone),
                            "drilldown":key+"_"+plantwiseCategory[1],
                            "color": '#FF2E7E',
                        },
                        {
                            "name":plantwiseCategory[2],
                            "y":parseFloat(greaterthantwo),
                            "drilldown":key+"_"+plantwiseCategory[2],
                            "color": '#6050DC',
                        },
                        {
                            "name":plantwiseCategory[3],
                            "y":parseFloat(greaterthanfifteen),
                            "drilldown":key+"_"+plantwiseCategory[3],
                            "color": '#FFA500',
                        },
                        {
                            "name":plantwiseCategory[4],
                            "y":parseFloat(greaterthaneighteen),
                            "drilldown":key+"_"+plantwiseCategory[4],
                            "color": '#d93a25',
                        }
                    ]
                })
                drilldownseries.push(
                    {
                        name:key,
                        id: key+"_"+plantwiseCategory[0],
                        data: data1
                    },
                    {
                        name: key,
                        id: key+"_"+plantwiseCategory[1],
                        data: data2
                    },
                    {
                        name: key,
                        id: key+"_"+plantwiseCategory[2],
                        data: data3
                    },
                    {
                        name: key,
                        id: key+"_"+plantwiseCategory[3],
                        data: data4
                    },
                    {
                        name: key,
                        id: key+"_"+plantwiseCategory[4],
                        data: data5
                    }
                );
                console.log(data1,"dataaa")
            })
            var chart_type = 2
            var unloadingcategories = ulcategories;
        }
        
        console.log(unloadingdata,"unloadingdata")
        console.log(drilldownseries,"drilldownseries")
       
        this.setState({
            unloadingdata : unloadingdata,
            drilldownseries:drilldownseries,
            unloadingcategories:unloadingcategories,
            chart_type:chart_type,
            loadshow:'show-n',
            overly:"show-n",
        });
    }
    onSelectLocation= location =>{
		this.setState(
			{ location },
			() => console.log(`Transporter Option selected:`, this.state.location)
          );
          this.setState({
            filteredPlantData : []
          });
        console.log(location);
        
        var filterCustomers = this.state.filterCustomers
        var customers = []
        customers.push({label: "ALL" , value : "all"})
        filterCustomers.map(e=>{
            if (e._id.consignee_name != null && e._id.consignee_name!="" && e._id.consignee_code!=null && e._id.consignee_code!=""){
                if (location.label!='ALL'){
                    if (e._id.consignee_city == location.value){
                        customers.push({label:e._id.consignee_name , value : e._id.consignee_code})
                    }
                }else{
                    customers.push({label:e._id.consignee_name , value : e._id.consignee_code})
                }
            }
            
        })
        this.setState({
            customers : customers
        })
    }
    changePlantData(plantcode){
        // console.log("option ",plantcode)
        this.setState({
            plantcode : plantcode,
        });
    }
    onSelectCustomer (customer){
		this.setState(
			{ customer },
			() => console.log(`Customer Option selected:`, this.state.customer)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
          })
    }
    onClickShowGridData(){
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }
        var customerCode = this.state.customer 
        var plantCode = this.state.plantcode 
        
        var reqparams = {
            from_date : startDate,
            to_date : endDate,
        }
        if(customerCode!="" && customerCode!=undefined){
            reqparams.consignee_code = customerCode.value
        }
        if(plantCode!="" && plantCode!=undefined){
            if(plantCode.value !="" && plantCode.value !=undefined && plantCode.value !="all")
            {
                reqparams.consigner_code = plantCode.value
            }
        }
        console.log(reqparams,"reqparamsforunloading");
        redirectURL.post("/carlsberg/getSndUnloadChartData",{"formdata":reqparams,"consignee_code":customerCode.value}).then((resp)=>{
            console.log(resp.data,"response")
           
            var report_data = JSON.parse(resp.data.chartdata.report_data);
            console.log(report_data,"report")
           this.setState({rowData:report_data,showgraph:false,showdatabtn:"show-n",showgraphbtn:"show-m"})
        }).catch((e)=>{
            console.log(e);
        })
    }
    onClickShowGraph(){
        this.setState({showgraph:true,showdatabtn:true,showgraphbtn:"show-n"})
    }
    onRowSelection(event) {
		var rowIds = [];
		var rwCount = event.api.getSelectedNodes();
		rwCount.map((item) => {
			rowIds.push(item.data.shipment_id)
		});

		this.setState({
			rowIds: rowIds
		})
		console.log("checkedlist ", rowIds)
	}
    onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

    onClickGetLoadingData(event){
        event.preventDefault();
        this.setState({
            loadshow:'show-m',
            overly:"show-m",
            lat : "",
            lng : "",
            distancefromdealer : "",
            no_of_unloads : "",
            plantRowData : [],
            rowData : [],
            unloaddrilldown : []
        });
        var startDate = this.state.startDate;
        var endDate = this.state.endDate;
        if(startDate != "NaN-NaN-NaN" && startDate != "" && endDate != "NaN-NaN-NaN" && endDate != "" )
        {
            startDate = startDate+" 00:00:00";
            endDate = endDate+" 23:59:59";
        }
        else{
            startDate = "2022-12-01 00:00:00";
            endDate = moment.parseZone().format("YYYY-MM-DD")+" 23:59:59";
        }
        var customerCode = this.state.customer 
        var plantCode = this.state.plantcode 
        
        var reqparams = {
            from_date : startDate,
            to_date : endDate,
        }
        if(customerCode!="" && customerCode!=undefined){
            reqparams.consignee_code = customerCode.value
        }
        if(plantCode!="" && plantCode!=undefined){
            if(plantCode.value !="" && plantCode.value !=undefined && plantCode.value !="all")
            {
                reqparams.consigner_code = plantCode.value
            }
        }
        console.log(reqparams,"reqparamsforunloading");
        redirectURL.post("/carlsberg/getSndUnloadChartData",{"formdata":reqparams,"consignee_code":customerCode.value}).then((resp)=>{
            var list_of_trucks = []
            if(resp.data.chartdata.list_of_trucks!="" && resp.data.chartdata.list_of_trucks!=undefined){
                list_of_trucks = JSON.parse(resp.data.chartdata.list_of_trucks);
            }
            if(resp.data.chartdata.report_data!="" && resp.data.chartdata.report_data!=undefined){
                var report_data = JSON.parse(resp.data.chartdata.report_data);
            }
            console.log(report_data,"report_data")
            if(this.state.showgraph==false){
                var t = this.state.showgraph
                console.log(t,"ttttt")
                this.setState({rowData:report_data})
            }
            this.setState({
                list_of_trucks:list_of_trucks,
                report_data:report_data
            })
            this.formatChartData()
        }).catch((e)=>{
            console.log(e);
        })
        this.getTripReportData()

        var from_date = moment.parseZone().subtract(8,"days").format("YYYY-MM-DD")
        var endDate = moment.parseZone().format("YYYY-MM-DD") 
        var paramsforlinegraph = {
            from_date : from_date,
            to_date : endDate
        }
        var plantCode = this.state.plantcode 
        if(plantCode!="" && plantCode!=undefined){
            if(plantCode.value !="" && plantCode.value !=undefined && plantCode.value !="all")
            {
                paramsforlinegraph.consigner_code = plantCode.value
            }
        }
        console.log(paramsforlinegraph,"paramsforlinegraph")
        if(plantCode.value!=""){
            redirectURL.post("/carlsberg/getSndUnloadLineGraphData",paramsforlinegraph).then((resp)=>{
                if (resp.data.report_data != "" && resp.data.report_data != undefined) {
                    var data = JSON.parse(resp.data.report_data)
                }
                    var results = enumerateDaysBetweenDates1(from_date, endDate);
                    var dates = results
                    var plantsCountDateWise = []
                    var result = []
                    var eachPlantData = groupBy(data, e => moment.parseZone(new Date(e.deviation_time)).format("YYYY-MM-DD"))
                    console.log(eachPlantData, "eachplantdata")
                    dates.map(f => {
                        var count = 0
                        eachPlantData.forEach((e, key) => {
                            if (f == key) {
                                count += e.length
                            }
                        })
                        result.push(count)
                    })
                    plantsCountDateWise.push(result)
                    var categoriesforline = dates
                   var plants = [plantCode.label]
                    var seriesDataforLineGraph = plants.map(function (plantName, index) {
                        return { name: plantName, data: plantsCountDateWise[index] };
                    });
                    this.setState({
                        seriesDataforLineGraph: seriesDataforLineGraph,
                        categoriesforline: categoriesforline
                    })
            })
        }
        else {
            this.getRouteDeviationData()
        }

        this.getStoppagesData()
        this.getTransitDelayData()
       
       
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
            overly:'show-n',
            breaksSlider : "",
            selectedRow : [],
		});
		
    }
    render(){
        var columnwithDefsfordata = [
            {
				headerName: "Depot Name",
				field: ["Depot Name"],
				width: 200,
				
			},
            {
				headerName: "Waiting For Unliading<1",
				field: ["waiting for unloading < 1 day"],
				width: 200,
				
			},
            {
				headerName: "Waiting For Unliading>1",
				field: ["waiting for unloading > 1 day"],
				width: 200,
				
			},
            {
				headerName: "Waiting For Unliading>2",
				field: ["waiting for unloading > 2 days"],
				width: 200,
				
			},
            {
				headerName: "Waiting For Unliading>15",
				field: ["waiting for unloading > 15 days"],
				width: 200,
				
			},
            {
				headerName: "Waiting For Unliading>18",
				field: ["waiting for unloading > 18 days"],
				width: 200,
				
			},
        ]   
        const dStyles={
            width:'100%',
            height:'450px',
            // marginTop : "20px"
        }
        // AUTOCOMPLETE 
        var listData = [] 
        try{
            this.state.filteredLocationData.map(function(e){
                listData.push(<li key={e} className="dropdown-truck-no">{e}</li>)
                })
        }catch(e){}
        var plantListData = [] 
        try{
            this.state.filteredPlantData.map(function(e){
                plantListData.push(<li key={e} className="dropdown-customer-no">{e}</li>)
                })
        }catch(e){}
        //END
        return ( 
        		<div className="col-xl-12 col-lg-12">
                    <div className={"col-xl-12 col-lg-12 tab- pt-20px whiteRow ml-18p"}>		
                        <div className="col-xl-12 col-lg-12">		            	
                            <h5>
                                 <i className="icofont icofont-dashboard-web cus-i"></i> <span>Unloading Dashboard</span>
                            
                            </h5>                    
                        </div>
                        <div className=" col-xl-12 col-lg-12 mt-35p">
                            <div className="row col-xl-12 col-lg-12">
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Start Date: </label>
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>End Date: </label>
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="endDate" 
                                    onChange={this.handlerEndDateTime.bind(this)} />   
                                </div>
                                <div className="form-gplantwiseloadingroup col-xl-2 col-lg-2">
                                    <label>Select Plant </label>
                                    <Select 
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        onChange={this.changePlantData.bind(this)}
                                        className={"border-radius-0 w-200"}
                                        style={{borderRadius:"0px",textAlign:"left"}}
                                        value={this.state.plantcode}
                                        options={this.state.plantList} 
                                    /> 
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>Select Customer : </label>
                                    <Select 
                                    placeholder={"All"} 
                                    isMulti={false} 
                                    value={this.state.customer} 
                                    className="border-radius-0" 
                                    onChange={this.onSelectCustomer.bind(this)} 
                                    style={{borderRadius:"0px"}} 
                                    options={this.state.customers} required />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                                </div>
                            </div>
                            <div className="col-xl-12 mb-10p">
				                <span className="layoutbtns">
                                <button className={"float-right custom-btn white btn btn-warning " + this.state.showdatabtn} type="button" onClick={this.onClickShowGridData.bind(this)} style={{ marginRight: "10px" }}> Show Data
								</button>
                                </span> 
                            </div>
                            <div className="col-xl-12 mb-10p">
                            <span className="layoutbtns">
                            <button className={"float-right custom-btn white btn btn-warning " + this.state.showgraphbtn} type="button" onClick={this.onClickShowGraph.bind(this)} style={{ marginRight: "10px" }}> Show Graph
							</button>
                            </span> 
                        </div>
                            
							{this.state.showgraph ? 			
                            <div className={"row mt-40p "}>
                                <div className="col-xl-12">
                                    {(this.state.chart_type == 1)?
                                    <DrillDownBarChart
                                        chartTitle={"Unloading Delay"} 
                                        seriesData={this.state.unloadingdata}
                                        seriesCategories={this.state.unloadingcategories}
                                        chartType={this.state.chart_type}
                                        drilldowndata={this.state.drilldownseries}
                                        chart_hit_count={this.state.chart_hit_count}
                                    />
                                    :""}
                                    {(this.state.chart_type == 2)?
                                    <BarChart
                                        chartTitle={"Unloading Delay"} 
                                        seriesData={this.state.unloadingdata}
                                        seriesCategories={this.state.unloadingcategories}
                                        chartType={this.state.chart_type}
                                        drilldowndata={this.state.drilldownseries}
                                        chart_hit_count={this.state.chart_hit_count}
                                    />
                                    :""}
                                </div>
                        </div> :
                            <div id="myGrid" style={{ width: "100%", height: "580px" }} className="ag-theme-balham">
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefsfordata}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={true}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                // rowSelection="single"
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                frameworkComponents={this.state.frameworkComponents}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                stopEditingWhenGridLosesFocus={true}
                                enableCellChangeFlash={true}
                                suppressCellFlash={true}
                                rowClassRules={this.state.rowClassRules}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                suppressRowClickSelection={false}
                                masterDetail={true}
                                onRowSelected={this.onRowSelection.bind(this)}
                            />

                        </div>}
                        <div className={"row mt-40p "}>
                            <div className="col-xl-6" style={{ width: '500px', height: '500px' }}>
                                <PieChartComponent
                                    chartType="pie"
                                    title={"Trip Report"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.piechartSeriesData}
                                    categories={this.state.piechartSeriesCategories}
                                    seriesName=""
                                    yaxistitle="Orders For Load Plan"
                                    drilldownseries={[]}
                                    innerSize="0%"
                                    format='<b>{point.name}-{point.y}</b>'
                                />
                            </div>
                            <div className="col-sm-6">
                                <span>
                                    <LineChartComponent
                                        title={"Route Deviation"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.seriesDataforLineGraph}
                                        categories={this.state.categoriesforline}
                                        yaxistitle={"Plant Wise Count"}
                                        drilldownseries={""}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: "2em" }}>
                            <div className="col-sm-6">
                                <span>
                                    <LineChartComponent
                                        title={"Stoppages"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.stoppagesData}
                                        categories={this.state.stoppagesCategory}
                                        yaxistitle={"In Hours"}
                                        drilldownseries={""}
                                    />
                                </span>
                            </div>
                            <div className="col-sm-6">
                                <span>
                                    <LineChartComponent
                                        title={"Transit Delay"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.transitDelayData}
                                        categories={this.state.transitdelayCategory}
                                        yaxistitle={"In Hours"}
                                        drilldownseries={""}
                                    />
                                </span>
                            </div>

                        </div>
                    </div> 
                        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
	                </div>
	           </div>
			);
		}
}

function loadScriptWeather(url){
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distinctArrayBy(arr, propName,propName1) {
    var result = arr.reduce(function (arr1, e1) {
    var matches = arr1.filter(function (e2) {
        return e1[propName] == e2[propName] && e1[propName1] == e2[propName1];
    })
    if (matches.length == 0)

        arr1.push(e1)
        
        return arr1;
    }, []);
    return result;    
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
var enumerateDaysBetweenDates1 = (startDate, endDate) => {
    const dateArray = [];
    const currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  };