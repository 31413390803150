import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const getGradientColor = (color1, color2) => ({
    linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
    stops: [
        [0, color1],
        [1, color2],
    ],
});

const StackedBarChart = ({ detentionRecords, title }) => {
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const categories = detentionRecords.map(item => item.name);
        const getRandomColor = () => {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        };
        const colors = [
            getGradientColor('#FF6B6B', '#FF8E53'),
            getGradientColor('#55EFC4', '#2ECC71'),
            getGradientColor('#4A90E2', '#3B5998'),
            getGradientColor('#FFC658', '#FFA726'),
            getGradientColor('#A29BFE', '#8E44AD'),
            getGradientColor('#FF6B81', '#FF4757'),
        ];

        const data = detentionRecords.map((item, index) => ({
            y: parseFloat(item.averageDetentionHours),
            color: colors[index % colors.length],
            customLabel: `${parseFloat(item.averageDetentionHours)} hrs`,
            customColor: colors[index % colors.length],
        }));

        setChartOptions({
            chart: {
                type: 'column',
                // height: 350,
                // marginBottom: 130,
                backgroundColor: 'transparent',
                style: { fontFamily: 'Arial, sans-serif' },
                // spacingBottom: 10,
                // spacingTop: 20,
                // spacingLeft: 20,
                // spacingRight: 20, // Prevents extra spacing on the right
            },
            title: {
                text:""
            },
            xAxis: {
                categories,
                labels: {
                    style: {
                        color: '#2C3E50', // Dark blue-gray for readability
                        fontSize: '10px',
                        fontWeight: 'bold',
                    },
                    rotation: -45, // Rotate for better readability, as before
                },
                lineColor: '#ddd',
                gridLineWidth: 0,
            },
            yAxis: {
                title: {
                    text: 'Detention Hours',
                    style: { fontSize: '12px', fontWeight: 'bold' },
                },
                
                gridLineWidth: 0,
                lineWidth: 0,
                tickWidth: 0,
                min: 0,
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    // Show color dot and detention hours in tooltip
                    return `<span style="display: inline-block; width: 10px; height: 10px; background: ${this.point.customColor.stops[1][1]}; border-radius: 50%; margin-right: 5px;"></span><strong>${this.x}</strong><br/>Average Detention Hours: <b>${this.point.customLabel}</b>`;
                },
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderColor: '#ddd',
                borderRadius: 8,
                style: { color: '#333' },
            },
            
            plotOptions: {
                column: {
                    pointPadding: 0.3, // Thin bar width
                    borderWidth: 0,
                    borderRadius: 10, // Rounded corners for bars
                    colorByPoint: true,
                },
            },
            series: [
                {
                    name: 'Average Detention Hours',
                    data,
                    showInLegend: false,
                }
            ],
            credits: {
                enabled: false,
            },
            // responsive: {
            //     rules: [
            //         {
            //             condition: {
            //                 maxWidth: 600,
            //             },
            //             chartOptions: {
            //                 xAxis: {
            //                     labels: {
            //                         style: { fontSize: '10px' },
            //                         rotation: -45,
            //                     },
            //                 },
            //                 chart: {
            //                     height: 250,
            //                 },
            //             },
            //         },
            //     ],
            // },
        });
    }, [detentionRecords, title]);

    return (
        <div style={{
            overflowX: 'auto',
            padding: '0 10px', // Add padding to avoid right overflow
            boxSizing: 'border-box', // Ensures padding does not increase overall width
            maxWidth: '100%' // Limit max width to prevent overflow
        }}>
            <h3 className="chart-title">{title}</h3>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default StackedBarChart;
