import React, { Component } from 'react'

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class SecurityDashboard extends Component {
  constructor(props) {
    super(props);
    this.displayData = [];
    this.state = {
      loadshow:'show-n',
      alert:null,
      show: false,
      basicTitle:'',
      basicType:"default",
      show1: false,
      basicTitle1:'',
      basicType1:"default",
      rowData:[],
      truckNoList:[],
      filteredData : [],
      showTrucksList : "show-n",
      shipmentList:[],
      filteredShipmentData : [],
      showShipmentsList : "show-n",
      allrowData : [],
      tabOneactive: "activet",
      tabTwodelivered: '',
      tabThreeconsignmentform: '',
      shipmentType:{label:"All",value:"All"},      
      seconds: 120,
      lastRunTime : "",
      gps_status : 0,
    }
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.timer = 0;
  }

componentDidMount(){    
    this.onLoadPageData();
}
onLoadPageData = () => {
    var params = {};
    if(localStorage.getItem("pc") !=undefined && localStorage.getItem("pc") !="" && localStorage.getItem("pc") !="undefined")
    {
        params.plant_code = localStorage.getItem("pc");
    }
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    });
    redirectURL.post('/carlsberg/getSecurityDashboardData',params)    
    .then(async (response) => {
        var records = response.data;
        if(records.length > 0)
        {
            records = await distinctArrayBy(records,"shipment_id");
            if(records.length > 0)
            {
                var trucks = records.map(function(e)
                {
                    if(e.truck_no != "")
                    {
                        return e.truck_no;
                    }
                })
                this.setState({
                    truckNoList :trucks
                })
                var shipments = records.map(function(e)
                {
                    if(e.shipment_id != "")
                    {
                        return e.shipment_id;
                    }
                })
                this.setState({
                    shipmentList :shipments
                })
            }
        }
        var lastRunTime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
        await this.setState({
            rowData:records,
            allrowData:records,
            lastRunTime:lastRunTime,
            loadshow:"show-n",
            overly:"show-n",
            tabOneactive: "activet",
            tabTwodelivered: '',
            tabThreeconsignmentform: '',
        });            
        this.countDownTimer();
    })
    .catch(function (error) {
        console.log(error);
    });
}
onClickHideAll() {
    this.setState({
        loadshow: 'show-n',
        overly: 'show-n',
    });

}
initalList =(event)=>{
    $(".trucks-dropdown").removeClass("show-n");
    var dataset = this.state.truckNoList;
    dataset = dataset.slice(0,100);
    this.setState({
        filteredData : dataset,
        showTrucksList : "show-m",
    })
}
initalList1 =(event)=>{
    $(".shipment-dropdown").removeClass("show-n");
    var dataset = this.state.shipmentList;
    dataset = dataset.slice(0,100);
    this.setState({
        filteredShipmentData : dataset,
        showShipmentsList : "show-m",
    })
}
changeList = (event) => {
    var truckNo = $("#inputTruck").val().toUpperCase();
    var allrowData = this.state.allrowData;
    var rowData = allrowData.filter(function(e){
        if(e != "" && e != null)
        {
            return e.truck_no.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
        }        
    })
    this.setState({
        rowData : rowData,
    })
}
handlerForm = (event) => {
    //console.log("Select", event.target.value);
    // let name = event.target.name;
    // let value = event.target.value;
    // this.setState({[name]:value});
    $(".trucks-dropdown").removeClass("show-n");
    var dataset = this.state.truckNoList;
    var filteredResult = dataset.filter(function(e){
        if(e != "" && e != null)
        {
            return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
        }
        
    });
    filteredResult = filteredResult.slice(0,100);
    var allrowData = this.state.allrowData;
    var rowData = allrowData.filter(function(e){
        if(e != "" && e != null)
        {
            return e.truck_no.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
        }
        
    })
    this.setState({
        filteredData : filteredResult,
        rowData : rowData,
        showTrucksList : "show-m",           
        tabOneactive: "activet",
        tabTwodelivered: '',
        tabThreeconsignmentform: '',
    })
}
handlerForm1 = (event) => {
    $(".shipment-dropdown").removeClass("show-n");
    var dataset = this.state.shipmentList;
    var filteredResult = dataset.filter(function(e){
        if(e != "" && e != null)
        {
            return e.toString().includes($("#inputShipment").val());
        }
        
    });
    filteredResult = filteredResult.slice(0,100);
    var allrowData = this.state.allrowData;
    var rowData = allrowData.filter(function(e){
        if(e != "" && e != null)
        {
            return e.shipment_id.toString().includes($("#inputShipment").val());
        }
        
    })
    this.setState({
        filteredShipmentData : filteredResult,
        rowData : rowData,
        showShipmentsList : "show-m",           
        tabOneactive: "activet",
        tabTwodelivered: '',
        tabThreeconsignmentform: '',
    })
}
formHandler = async (event) => {
    this.setState({
        loadshow:'show-m'
    })
    event.preventDefault();
    var truckNo = $("#inputTruck").val().toUpperCase();
    var params = {
    }
    if(truckNo !=undefined && truckNo !="")
    {
        params.truck_no = truckNo;
    }
    if(localStorage.getItem("plant_code") !=undefined && localStorage.getItem("plant_code") !="" && localStorage.getItem("plant_code") !="undefined")
    {
        params.plant_code = localStorage.getItem("plant_code");
    }
    var shipment_id = $("#inputShipment").val();
    if(shipment_id !=undefined && shipment_id !="")
    {
        params.shipment_id = shipment_id;
    }
    redirectURL.post('/carlsberg/getSecurityDashboardData',params)    
    .then(async (response) => {
        var records = response.data;
        if(records.length > 0)
        {
            records = await distinctArrayBy(records,"shipment_id");
        }
        await this.setState({
            rowData:records,
            loadshow:"show-n",
            overly:"show-n",            
            tabOneactive: "activet",
            tabTwodelivered: '',
            tabThreeconsignmentform: '',
        });
    })
    .catch(function (error) {
        console.log(error);
        this.setState({
            loadshow:"show-n",
            overly:"show-n"
        });
    });
}
modechangehandler =(shipmentType)=>{
    console.log("ShipmentType", shipmentType)
    this.setState({
        shipmentType:shipmentType
    })
    var shipment_type = shipmentType.value;
    console.log(shipment_type,"shipment_type")
    var allrowData = this.state.allrowData;
    var rowData = [];
    var gps_status = this.state.gps_status;   
    console.log(gps_status,"gps status 259") 
    if(gps_status == 1)
    {
        var rowData = allrowData.filter((ed) => ed.no_gps_data == 0 || ed.sim_tracking == 1);
    }
    if(gps_status == 2)
    {
        var rowData = allrowData.filter((ed) => {
            if(ed.no_gps_data == 1)
            {
                if(ed.sim_tracking == 0)
                {
                    return ed;
                }
            }
        });
    }
    if(gps_status == 0)
    {
        var rowData = allrowData;
    }
    if(shipment_type == "All"){
        var rowData = rowData;
    }
    if(shipment_type == "ROAD"){
        var rowData = rowData.filter((e)=>e.shipment_mode == "ROAD")
    }
    if(shipment_type == "RAIL"){
        var rowData = rowData.filter((e)=>e.shipment_mode == "RAIL")
    }
    this.setState({
        rowData : rowData,
        loadshow : "show-n",
        overly : "show-n"
    });
}
onClickTab(pageType) {
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    });
    if (pageType == "all") {
        $("#inputTruck").val("");
        var tabOneactive = "activet";
        var tabTwodelivered = "";
        var tabThreeconsignmentform = "";
        var gps_status = 0
    }
    else if (pageType == "active") {
        $("#inputTruck").val("");
        tabOneactive = "";
        tabTwodelivered = "activet";
        tabThreeconsignmentform = "";
        var gps_status = 1
    }
    else if (pageType == "inactive") {
        $("#inputTruck").val("");
        tabOneactive = "";
        tabTwodelivered = "";
        tabThreeconsignmentform = "activet";
        var gps_status = 2
    }
    var allrowData = this.state.allrowData;
    var rowData = [];
    if(gps_status == 1)
    {
        var rowData = allrowData.filter((ed) => ed.no_gps_data == 0 || ed.sim_tracking == 1);
    }
    if(gps_status == 2)
    {
        var rowData = allrowData.filter((ed) => {
            if(ed.no_gps_data == 1)
            {
                if(ed.sim_tracking == 0)
                {
                    return ed;
                }
            }
        });
    }
    if(gps_status == 0)
    {
        var rowData = allrowData;
    }
    var shipment_type  = this.state.shipmentType.value;
    if(shipment_type == "All"){
        var rowData = rowData
    }
    if(shipment_type == "ROAD"){
        var rowData = rowData.filter((e)=>e.shipment_mode == "ROAD")
    }
    if(shipment_type == "RAIL"){
        var rowData = rowData.filter((e)=>e.shipment_mode == "RAIL")
    }
    this.setState({
        tabOneactive,
        tabTwodelivered,
        tabThreeconsignmentform,
        gps_status : gps_status,
        rowData : rowData,
        loadshow : "show-n",
        overly : "show-n"
    });
}
countDownTimer = async() => {
    var lastRunTime = this.state.lastRunTime;
    var currentDate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
    // var startdate = moment.parseZone(lastRunTime).format("YYYY-MM-DD HH:mm:ss")
    var startdate = lastRunTime
    var diffreetime = moment.duration(moment(currentDate).diff(startdate));
    var runseconds = Math.round(diffreetime.asSeconds());
    console.log(runseconds,lastRunTime,currentDate,"runseconds");
    if(parseInt(runseconds) < 60)
    {
        this.startTimer();
    }
}

secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
}

startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 2000);
    }
}

countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    if(this.state.seconds == 30 || this.state.seconds == 60 || this.state.seconds == 90)
    {
        //console.log(this.state.seconds,"this.state.seconds")
        this.onLoadPageData();
    }
    // Check if we're at zero.
    if (seconds == 0) { 
        clearInterval(this.timer);
        $("#timerDiv").hide();
        this.onLoadPageData();
    }
}
render() {
    var listData = [] 
    this.state.filteredData.map(function(e){
        listData.push(<li className="dropdown-truck-no" id={e}>{e}</li>)
    })
    var listShipmentData = [] 
    this.state.filteredShipmentData.map(function(e){
        listShipmentData.push(<li className="dropdown-shipment-no" id={e}>{e}</li>)
    })
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="row col-sm-12">
                    <div className={"trucks-dropdown "+this.state.showTrucksList}>
                        <ul>
                            {listData}
                        </ul>
                    </div>
                    <div className={"shipment-dropdown "+this.state.showShipmentsList}>
                        <ul>
                            {listShipmentData}
                        </ul>
                    </div>
                    <form className="row theme-form col-xl-8 col-lg-8" onSubmit={this.formHandler}>
                        <div className="col-xl-3 col-lg-3 form-group">
                            <input type="text" style={{height:"37px"}} name="truck_no" id="inputTruck"  placeholder="Search By Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
                        </div>
                        <div className="col-xl-3 col-lg-3 form-group">
                            <input type="text" style={{height:"37px"}} name="shipment_id" id="inputShipment"  placeholder="Search By Shipment Id" autoComplete="off" className="form-control" onFocus={this.initalList1.bind(this)} onChange={this.handlerForm1.bind(this)} />
                        </div>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <button type="submit" className="btn btn-info">Find</button>
                        </div>
                    </form>
                        {/* <div className="col-xl-5 col-lg-5 form-group"></div>
                        <div className="col-xl-2 col-lg-2 form-group">
                            <select name="gps_status" id="gps_status" onChange={this.changeGPSStatus} className="form-control">
                                <option value="">Filter By Status</option>
                                <option value="0">All</option>
                                <option value="1">GPS Available</option>
                                <option value="2">GPS Not Available</option>
                            </select>
                        </div> */}
                        <div className="col-xl-4 col-lg-4">
                            <div className="row">
                                <div className={"form-group col-xl-12 col-lg-12"} style={{paddingLeft:"0px"}} >
                                    <Select 
                                    className="border-radius-0"
                                    isMulti={false}
                                    id="selectedFilter"
                                    style={{borderRadius:"0px"}} 
                                    onChange = {this.modechangehandler}
                                    value = {this.state.shipmentType}
                                    options={[
                                        {label:"All",value:"All"},
                                        {label:"ROAD",value:"ROAD"},
                                        {label:"RAIL",value:"RAIL"},
                                        // {label:"Vin",value:"Vin"},
                                        ]} required />
                                               
                                </div>
                            </div>
                        </div>
                </div>
                <div className="col-sm-12">
                    <div className="f16" style={{ marginBottom: "10px" }}>
                        <ul className="d-tabs">
                            <li onClick={this.onClickTab.bind(this, "all")} className={"lttabs " + (this.state.tabOneactive)}>
                                <button type="button" className="btn">All Trucks</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this, "active")} className={"lttabs " + (this.state.tabTwodelivered)}>
                                <button type="button" className="btn">Active Trucks</button>
                            </li>

                            <li onClick={this.onClickTab.bind(this, "inactive")} className={"lttabs " + (this.state.tabThreeconsignmentform)}>
                                <button type="button" className="btn">In Active Trucks</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {(this.state.rowData.length > 0) ?
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <h6 style={{textAlign:"center",fontWeight:"700"}}>{(this.state.rowData.length)} trucks found</h6>
                </div>
                {(this.state.rowData.map((ed) => {
                return <div className="col-xl-3 col-lg-3">
                    <div className="card">
                        <div className="card-header n-p-0">
                            <h5 className={(ed.no_gps_data == 0 || ed.sim_tracking ==1 )?"f22 envcard-g-2":"f22 envcard-r-2"}>
                                <span className={(ed.no_gps_data == 0 || ed.sim_tracking ==1 )?"circle-border-g":"circle-border-r"}>
                                <i className="icon-truck"></i>
                                </span>&nbsp;&nbsp; <span> {ed.truck_no}</span>
                            </h5>
                        </div>
                        <div className={(ed.no_gps_data == 0 || ed.sim_tracking == 1 )?"card-body envcard-b-g-2a":"card-body envcard-b-r-2a"}>
                            <div className="row">
                                <div className="col-sm-12" style={{marginBottom:"2%"}}>
                                    <div className="row">
                                        <div className="col-sm-5"><label style={{lineHeight:"18px",fontWeight:"800"}}>Shipment Code:</label></div>
                                        <div className="col-sm-7">{ed.shipment_id}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12" style={{marginBottom:"2%"}}>
                                    <div className="row">
                                        <div className="col-sm-5"><label style={{lineHeight:"18px",fontWeight:"800"}}>Driver Name:</label></div>
                                        <div className="col-sm-7" style={{lineHeight:"18px"}}>{ed.driver_name}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12" style={{marginBottom:"2%"}}>
                                    <div className="row">
                                        <div className="col-sm-5"><label style={{lineHeight:"18px",fontWeight:"800"}}>Mobile Number:</label></div>
                                        <div className="col-sm-7">{ed.shipment_mobile_no}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12" style={{marginBottom:"2%"}}>
                                    <div className="row">
                                        <div className="col-sm-5"><label style={{lineHeight:"18px",fontWeight:"800"}}>Tracking Mode:</label></div>
                                        {/* {ed.sim_tracking == 1 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""}
                                        {ed.no_gps_data == 0 ?
                                        <div className="col-sm-7">GPS Device</div>
                                        :""}
                                        {ed.sim_tracking == 0 && ed.no_gps_data == 1 ?
                                        <div className="col-sm-7">N/A</div>
                                        :""} */}
                                        {ed.sim_tracking_lifetime_in_trip == 1 && ed.no_gps_data == 0 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""}
                                        {ed.sim_tracking_lifetime_in_trip == 0 && ed.no_gps_data == 0 ?
                                        <div className="col-sm-7">GPS Device</div>:""
                                        }
                                        {ed.sim_tracking_lifetime_in_trip == 0 && ed.no_gps_data == 1 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""}
                                        {ed.sim_tracking_lifetime_in_trip == 1 && ed.no_gps_data == 1 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""}
                                    </div>
                                </div>
                                {(ed.sim_tracking_lifetime_in_trip == 0 && ed.no_gps_data ==0)?"":
                                
                                <div className="col-sm-12" style={{marginBottom:"2%"}}>
                                    <div className="row">
                                        <div className="col-sm-5"><label style={{lineHeight:"18px",fontWeight:"800"}}>Sim Tracking No:</label></div>
                                        <div className="col-sm-7">{ed.driver_mobile_no}</div>
                                        {/* {ed.sim_tracking == 1 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""}
                                        {ed.no_gps_data == 0 ?
                                        <div className="col-sm-7">GPS Device</div>
                                        :""}
                                        {ed.sim_tracking == 0 && ed.no_gps_data == 1 ?
                                        <div className="col-sm-7">N/A</div>
                                        :""} */}
                                        {/* {ed.sim_tracking_lifetime_in_trip == 1 && ed.no_gps_data == 0 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""}
                                        {ed.sim_tracking_lifetime_in_trip == 0 && ed.no_gps_data == 0 ?
                                        <div className="col-sm-7">GPS Device</div>:""
                                        }
                                        {ed.sim_tracking_lifetime_in_trip == 0 && ed.no_gps_data == 1 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""}
                                        {ed.sim_tracking_lifetime_in_trip == 1 && ed.no_gps_data == 1 ?
                                        <div className="col-sm-7">SIM Tracking</div>
                                        :""} */}
                                    </div>
                                </div>}
                                <div className="col-sm-12" style={{marginBottom:"2%"}}>
                                    <div className="row">
                                        <div className="col-sm-5"><label style={{lineHeight:"18px",fontWeight:"800"}}>Shipment Mode:</label></div>
                                        <div className="col-sm-7">{ed.shipment_mode}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }))}
            </div>
            :
            <div className="row">
                <div className='col-sm-12'>
                    <h4 style={{textAlign:"center",marginTop:"2em"}}>No Records Found</h4>
                </div>
            </div>
            }

            <div className={"dataLoadpage " + (this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            <div className={"slide-r " + (this.state.slideBar)} >
                {this.displayData}
            </div>
        </div>
    )
  }
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}


$(document).ready(function () {
    $(document).on("click", ".dropdown-shipment-no", function () {
        var vals = $(this).text();
        $("#inputShipment").val(vals);
        $(".shipment-dropdown").removeClass("show-m");
        $(".shipment-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputShipment");
        if (!container.is(e.target)) {
            try {
                if ($(".shipment-dropdown").attr("class").includes("show-m") || $(".shipment-dropdown").attr("class") == "shipment-dropdown") {
                    $(".shipment-dropdown").removeClass("show-m");
                    $(".shipment-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})