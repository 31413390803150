import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import EditMLLConsignment from "./edittptconsignment";

$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class chettinadLastMileBatch extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
                editMLLConsignment:EditMLLConsignment
            },
			components: {
				numericCellEditor: NumericCellEditor,
			},
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "multiple" ,
			rowSelection1: "multiple" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"",
            sliderRouteTranslate:'',
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            eventData:[],
            sourceopt:'',
            unassignvins:[],
			assignvins:[],
			modetype:{"value":"Trailer", "label":"Trailer"},
			gpsdeviceid:"",
			deviceplaceat:'',
			loadingyard:'',
			ewaybilldate:"",
			assignedvins:[],
			showconvoyfields:"show-n",
			trailerno:"",
			allvins:[],
			slideupload:"",
			vinsfile:"",
			allunassignvins:[],
            sources:[],
            consignees:[],
            rsos:[],
            lastmiledestination:"",
			destypeopt:"",
			destopts:[],			
			transporters:[],
			driver_name:"",
			driver_mobile:"",
			loading_officer:"",
			loadofficers:"",
			devices:[],
			alldevices:[],
			allunassigndata:[],
			unassignrowData:[],
			assignrowData:[],
			batransporter:"",
            refno:"",
			railno:"",
			dummytrucks:[],
			master_ref_no:"",
			dummytruck:"",
			rake_no:"default",
        };
        // this.editRakeData = this.editRakeData.bind(this);
        // this.deleteRakeData = this.deleteRakeData.bind(this);
        this.onClickShowMap = this.onClickShowMap.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onLoadRakePlanningData = this.onLoadRakePlanningData.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
        this.onClickEditBlock = this.onClickEditBlock.bind(this);
	}
	
    componentDidMount(){
		loadDateTimeScript() 
		var rake_no = this.props.match.params.rake_no;
	    var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        // console.log("Match props ", this.props.match.params.refno)
        // if(this.props.match.params.refno != undefined)
        // {
        //     var refno = this.props.match.params.refno;
        //     var master_ref_no = this.props.match.params.refno;
        // }
        // else{
        //     var refno = "";
        //     var master_ref_no = "";
		// }
		// // railno
		
        // if(this.props.match.params.railno != undefined)
        // {
        //     var railno = this.props.match.params.railno
        // }
        // else{
        //     var railno = "";
		// }
       
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate,
			rake_no:rake_no
            // refno:refno,
            // railno:railno
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("mindate ", mindate)
       // console.log("minactdate ", minactdate)
        this.setState({
            dminDate:mindate,
            dactminDate:minactdate
        });
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var urlpath;
        var dpt;
        
			dpt='SNDG';
        
        this.setState({
			dept_code:dpt,
		});
		
		// redirectURL.post("/consignments/getSNDGTransporters")
		// .then((response) => {
		// 	var records = response.data;
		// 	this.setState({
		// 		transporters:records
		// 	});
		// })
		// redirectURL.post("/consignments/mllloadofficers")
		// .then((response) => {
		// 	var records = response.data.records;
		// 	var recordsarr = [];
		// 	var unrecordsarr = []
		// 	if(records.length > 0)
		// 	{
		// 		records.map((item) => {
		// 			if(item.officer_type == "Loading")
		// 			{
		// 				recordsarr.push(item)
		// 			}
		// 			if(item.officer_type == "Unloading")
		// 			{
		// 				unrecordsarr.push(item)
		// 			}
		// 		})
		// 	}
		// 	this.setState({
		// 		loadofficers:recordsarr
		// 	});
		// })
		// var tquery = {
		// 	transporter_code:localStorage.getItem("transportercode")
		// }
        // redirectURL.post("/dashboard/mlllastmilebatchdata")
		// .then((response) => {
		// 	var transporters = response.data.transporters;
		// 	var loadofficers = response.data.loadingofficers;
		// 	var devices = response.data.devices;
		// 	var recordsarr = [];
		// 	var unrecordsarr = []
		// 	var deviceslist =[]
		//    if(devices.length > 0)
		//    {
		// 		devices.map((item) => {
		// 			// console.log("item.status ",item.status)
		// 			// console.log("item.code ",item.device_code)
					
		// 			// if(parseInt(item.status) != 11 && parseInt(item.status) != 12)
		// 			// if(parseInt(item.status) == 9 || parseInt(item.status) == 13 || parseInt(item.status) == 14 || parseInt(item.status) == 15)
		// 			if(parseInt(item.status) != 11 && parseInt(item.status) != 12)
		// 			{
		// 				deviceslist.push(item)
		// 			}
		// 		})
		//    }
		// 	// if(loadofficers.length > 0)
		// 	// {
		// 	// 	loadofficers.map((item) => {
		// 	// 		if(item.officer_type == "Loading")
		// 	// 		{
		// 	// 			recordsarr.push(item)
		// 	// 		}
		// 	// 		if(item.officer_type == "Unloading")
		// 	// 		{
		// 	// 			unrecordsarr.push(item)
		// 	// 		}
		// 	// 	})
		// 	// }
		// 	if(loadofficers.length > 0)
		// 	{
		// 		loadofficers.map((item) => {
		// 			// if(item.officer_type == "Loading")
		// 			// {
		// 			// 	recordsarr.push(item)
		// 			// }
		// 			// if(item.officer_type == "Unloading")
		// 			// {
		// 			// 	unrecordsarr.push(item)
		// 			// }
		// 			var nm = item.load_officer_name
		// 			unrecordsarr.push(nm)
		// 		})
		// 	}
		// 	// console.log("deviceseviceslist ",devices)
		// 	// console.log("deviceslist ",deviceslist)

		// 	this.setState({
		// 		transporters:transporters,
		// 		loadofficers:loadofficers,
		// 		devices:deviceslist,
		// 		alldevices:devices
		// 	});
		// })
        
	   redirectURL.post("/dashboard/tptsndconsignees")
		.then((response) => {
			var records = response.data.records;
			this.setState({
				consignees:records
			});
		})
       
	//    redirectURL.post("/mllconsignments/mlllastmiledevices")
	//    .then((response) => {
	// 	   var records = response.data.records;
	// 	   this.setState({
	// 		   devices:records,
	// 		   alldevices:records
	// 	   });
	//    })
	   redirectURL.post("/consignments/mllrsos")
	   .then((response) => {
		   var records = response.data.records;
		   this.setState({
			   rsos:records
		   });
	   })
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        
        var parameters = {
            startdate:sdate,
            enddate:edate,
            // refno:refno
        }
		this.onLoadRakePlanningData(parameters)
		//this.onLoadDummyTrucks(parameters);

	}
    
    onLoadRakePlanningData(parameters)
    {
		var rake_no = this.props.match.params.rake_no;
		var transporter_code = localStorage.getItem("transportercode")
		// var prms = {
		// 	transporter_code:transporter_code
		// }
		// redirectURL.post("/dashboard/tptloaddestpoints",prms)
		// .then((response) => {
		// 	var records = response.data.records;
		// 	this.setState({
		// 		sources:records
		// 	});
		// })
		if(rake_no != "default")
		{
			parameters.rake_no = rake_no;
		}
		redirectURL.post("/dashboard/mlldealervinslist",parameters)
		.then(async (response) => {
			var records = response.data.records;
			//console.log("records ", records)
			var unassignvins = [];
			var allvins = []
			var allunassignvins =[]
            if(records.length > 0)
            {
                records.map((item,index) => {
					
						unassignvins.push({
							consignment_code:item.consignment_code,
							//group_code:item.group_code,
							consignee_city:item.consignee_city,
							order:index,
							//tos_dummy_truck:item.tos_dummy_truck
						})
						allunassignvins.push({
							consignment_code:item.consignment_code,
							//group_code:item.group_code,
							consignee_city:item.consignee_city,
							order:index,
							//tos_dummy_truck:item.tos_dummy_truck
						})
					
					item.order = index
					allvins.push(item)
                })
			
				// await redirectURL.post("/dashboard/tptbatchloadofficer",{refno:records[0].master_ref_no, transporter_code:localStorage.getItem("transportercode")})
				// .then((response) => {
				// 	var recordsr = response.data.records;
				// 	console.log("recordsr ", recordsr)
				// 	if(recordsr.length > 0)
				// 	{
				// 		this.setState({
				// 			loading_officer:{value:recordsr[0].last_mile_loading_officer_code,label:recordsr[0].last_mile_loading_officer_name}
				// 		});
				// 	}
					
				// })
			}
			this.setState({
				rowData:records,
				unassignrowData:unassignvins,
				allunassigndata:unassignvins,
				unassignvins:unassignvins,
				allvins:allvins,
				allunassignvins:allunassignvins
			});
		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
	}
	
	onLoadDummyTrucks(params){
		redirectURL.post("/dashboard/dummytrucks",{master_ref_no:params.refno})
		.then((response) => {
			var records = response.data.records;
			this.setState({
				dummytrucks:records
			})
		})
	}
    
	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		screenpage='Consignments';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    onClickCounterShowData(param)
    {
        if(param == "approval")
        {
            this.gridApi.setRowData(this.state.to_be_approved_list);
        }
    }
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onClickShowRakeSidebar = (params) =>{
		//console.log("Pareas ", params)
		//ar rownode = this.gridApi.getSelectedNodes();
    
        this.setState({
            forceclosedata:params.data,
            sliderRakeTranslate:"slider-translate",
            overly:'show-m',
            formtype:"add"
        })
	
    }
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
			sliderBulkForceClose:"",
			slideupload:""
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
    
    
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    handlerArrivalDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        
        var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
        
        this.setState({
            arrival_Date:startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    
    handlerActualArrivalDate = (event, currentDate, selectedDate) => {
       var d = new Date(event._d);
       
       var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
       
       this.setState({
        msilactualrake:startdate
       });
       //let value = event.target.value;
       //this.setState({'startDate':value});
   }

   handleEventClick= ({event}) => {
    // openAppointment is a function I wrote to open a form to edit that appointment
    //this.props.openAppointment(event.extendedProps)
        //console.log("Evernt ", event)
       // console.log(event._def.extendedProps)
        var rowid = event._def.extendedProps.rowid;
        redirectURL.post("/consignments/rakescheduleitem",{
            rowid:rowid
          })
          .then((response) => {
              var recds = response.data;
              //console.log("recds ", recds)
                if(recds.length > 0)
                {
                   // this.editRakeData(recds[0])
                }
          })
    }

    handleEventDrop = (info) => {
            if(window.confirm("Are you sure you want to change the event date?")){
                console.log('change confirmed')

                // updateAppointment is another custom method
               // this.props.updateAppointment({...info.event.extendedProps, start: info.event.start, end: info.event.end})

            } else {
                console.log('change aborted')
            }
    }

    onClickShowView(view)
    {
       // console.log("View ",view)
        if(view == "grid")
        {
            this.setState({
                gridview:'show-m',
                calcview:'show-n'
            })
        }
        if(view == "calender")
        {
            this.setState({
                gridview:'show-n',
                calcview:'show-m',
                calview:"timeGridWeek"
            })
        }
    }
    
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			slideupload:""
		});
	}
	onClickShowMap = (params) =>{
       
    }

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

	 onGridReady1 = params => {
	    this.gridApi1 = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi1 = params.columnApi;
	
	
	};
	 onGridState1 = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi1.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState1 = this.gridApi1.getFilterModel();
		   this.gridApi1.setFilterModel(window.filterState1);
	 }


     handlerStartDateTime = (event) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		ewaybilldate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilter = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		if(sDate != "NaN-NaN-NaN" || sDate != "")
		{
			var stdate = sDate;	
		}
		else
		{
			var stdate = this.state.defaultsdate
		}
		if(eDate != "NaN-NaN-NaN" || eDate != "")
		{
			var etdate = eDate;	
		}
		else
		{
			var etdate = this.state.defaultedate
        }
        var parameters = {
            startdate:stdate,
            enddate:etdate,
        }
        this.onLoadRakePlanningData(parameters)
    }


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// // const config = {
		// // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// // }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'trc_no',
					inputName: 'trc_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				
				{ 
					name: 'driver_name',
					inputName: 'driver_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'driver_mobile',
					inputName: 'driver_mobile',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			// console.log("csvData.data ", csvData.data)
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				// console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		// console.log("Out ", out);
		// console.log("e.target.files[0] ", e.target.files[0])
		
		this.setState({
			vinsfile:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }


    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
    
    onClickEditBlock(params){
        console.log("params ",params)
	}
	
	
    onChangeModeItem(modetype){
		if(modetype.value == "Trailer")
		{
			var showconvoyfields = "show-n"
			var showtrailer = "show-m"
		}
		else
		{
			var showconvoyfields = "show-m"
			var showtrailer = "show-n"
		}
		this.setState(
			{ modetype },
			() => console.log(`Option selected:`, this.state.modetype)
          );
		  this.setState({
			  errorMessage:'',
			  successMessage:'',
			  showconvoyfields:showconvoyfields,
			  showtrailer:showtrailer
		  })
	}

   
    selectmodes(){
		let items = [];    
		items.push({"value":"Trailer","label":"Trailer"})
		items.push({"value":"Convoy","label":"Convoy"})
		
		return items;
	}

	onChangeGPSDeviceTruckItem(gps_truck_no){
		this.setState(
			{ gps_truck_no },
			() => console.log(`Option selected:`, this.state.gps_truck_no)
          );
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	onChangeGPSDeviceItem(gpsdeviceid){
		this.setState(
			{ gpsdeviceid },
			() => console.log(`Option selected:`, this.state.gpsdeviceid)
          );
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

   
    selectgpsdevices(){
		let items = [];
		var devices = this.state.devices;
		if(devices.length > 0)
		{
			devices.map((item) => {
				items.push({"value":item.device_code,"label":item.device_code})
			})
		}    
		return items;
	}

	selectDummyTrucks(){
		let items = [];
		var devices = this.state.dummytrucks;
		try{
				
			if(devices.length > 0)
			{
				devices.map((item) => {
					items.push({"value":item.truck_no,"label":item.truck_no})
				})
			}    
		}
		catch(e){

		}
		return items;
	}

	onChangeDeviceAtItem(deviceplaceat){
		this.setState(
			{ deviceplaceat },
			() => console.log(`Option selected:`, this.state.deviceplaceat)
          );
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

   
    assignedVins(){
		let items = [];
		var assignedvins = this.state.assignedvins;    
		if(assignedvins.length > 0)
		{
			assignedvins.map((item) => {
				items.push({"value":item.consignment_code,"label":item.consignment_code})
			})
		}
		
		return items;
	}
	
	onChangeLoadingYardItem(loadingyard){
		this.setState(
			{ loadingyard },
			() => console.log(`Option selected:`, this.state.loadingyard)
          );
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	loadingyards(){
		let items = [];
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item._id,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
    }
    
    allunassingVinsNow(){
		var arr=[]
		var ischeck = $(".allunvins");
		// console.log("ischeck.is(':checked') ", ischeck.is(':checked'))
		if(ischeck.is(':checked') == true)
		{
			$(".unvins").each(function(){			
				$(this).prop("checked",true)
			})
		}
		else
		{
			$(".unvins").each(function(){			
				$(this).prop("checked",false)
			})
		}
		$(".unvins").each(function(){
			if($(this).is(":checked") == true)
			{
				arr.push(1)
			}
		})
		$("#totalunassigns").html(arr.length)
	}
	allassingVinsNow(){

		var ischeck = $(".allassignvins");
		if(ischeck.is(':checked') == true)
		{
			$(".assignvins").each(function(){		
				$(this).prop("checked",true)
			})
		}
		else
		{
			$(".assignvins").each(function(){		
				$(this).prop("checked",false)
			})
		}
	
	}
	totatselectitem(){
		var arr=[]
		$(".unvins").each(function(){
			if($(this).is(":checked") == true)
			{
				arr.push(1)
			}
		})
		$("#totalunassigns").html(arr.length)
	}
    
	assingVinsNow = async() =>{

		var rwCount = this.gridApi.getSelectedNodes();
        // console.log("rwCount ",rwCount)
		var records = []
		var dtrucks = []
        if(rwCount.length > 0)
        {
            rwCount.map((item) => {
				records.push(item.data)
				try{
					dtrucks.push({truck:item.data.tos_dummy_truck})
				}
				catch(e){

				}
				
            })
		}

		let dummytrucks = [];   
		try{            
			// Declare an empty object 
			let totuniqueObject = {}; 
			var totobjTitle;
			// Loop for the array elements 
			for (let i in dtrucks) { 

				// Extract the title 
				totobjTitle = dtrucks[i]['truck']; 

				// Use the title as the index 
				totuniqueObject[totobjTitle] = dtrucks[i]; 
			} 

			// Loop to push unique object into array 
			for (var a in totuniqueObject) { 
				dummytrucks.push(totuniqueObject[a].truck); 
			} 
		}
		catch(e){

		}
		if(this.state.assignrowData.length > 0)
		{
			this.state.assignrowData.map((item) => {
				records.push(item)
			})
		}
		else{
			try{
				this.setState({
					gps_truck_no:{value:rwCount[0].data.tos_dummy_truck,label:rwCount[0].data.tos_dummy_truck},
					dummytruck:rwCount[0].data.tos_dummy_truck
				})
			}
			catch(e){

			}
			
		}
		// console.log("Assign ",records)
		var unassingecs = [];
		if(this.state.allunassigndata.length > 0)
		{
			unassingecs = await this.state.allunassigndata.filter(f =>
				!records.some(d => d.consignment_code == f.consignment_code)
			);

			//  unassingecs = _(this.state.allunassigndata) 
			// 		.differenceBy(records, 'consignment_code')
			// 		.value();
		}
		// console.log("unassingecs ",unassingecs)
		if(localStorage.getItem("oem") == "KIA")
		{
			if(dummytrucks.length == 1)
			{
				await this.setState({
					assignrowData:records,
					assignedvins:records,
					unassignrowData:unassingecs			
				})
			}
			else
			{
				this.setState({
					show:true,
					basicTitle:"Multiple Dummy trucks not allowed",
					basicType:"danger"
				})
			}
		}
		else{
			await this.setState({
				assignrowData:records,
				assignedvins:records,
				unassignrowData:unassingecs			
			})
		}
		

		// var unvins = []
		// $(".unvins").each(function(){
		// 	var val = $(this).val();
		// 	var order = $(this).attr("order");
		// 	var group_code = $(this).attr("group_code");
		// 	var consignee_city = $(this).attr("consignee_city");
		// 	if($(this).is(":checked") == true)
		// 	{
		// 		unvins.push({
		// 			consignment_code:val,
		// 			group_code:group_code,
		// 			consignee_city:consignee_city,
		// 			order:order
		// 		})
		// 		$("#"+val).prop("disabled",true)
		// 		$(this).prop("disabled",true)
		// 		$(this).remove()
		// 		$("li#"+val).remove()
		// 	}
			
		// })
		// // console.log(unvins)
		// var html = '';
		// if(unvins.length > 0)
		// {
		// 	unvins.map((item, index) => {
		// 		html += "<li id='"+item.consignment_code+"' class='"+item.order+"'>";
		// 		html += '<span class="scolumn-l">'
		// 		html += "<input type='checkbox' order='"+item.order+"' group_code='"+item.group_code+"' consignee_city='"+item.consignee_city+"' name='assignvins[]' class='assignvins' value='"+item.consignment_code+"'  />"+item.consignment_code 
		// 		html += "</span>";
		// 		html += '<span class="scolumn-s">'+item.group_code+'</span>'
		// 		html += '<span class="scolumn-m">'+item.consignee_city+'</span>'
		// 		html += "</li>"
			
				
		// 	})
		// 	this.setState({
		// 		assignedvins:unvins
		// 	})
		// }
		// // console.log("html ",html)
		// $("#assignvins").append(html)
		// $(".allunvins").prop("checked",false)
	}

	unassignNow= async() =>{
		// var assignvins = []
		// var asigned = []
		// $(".assignvins").each(function(){
		// 	var val = $(this).val();
		// 	var order = $(this).attr("order");
		// 	var group_code = $(this).attr("group_code");
		// 	var consignee_city = $(this).attr("consignee_city");
		// 	if($(this).is(":checked") == true)
		// 	{
		// 		assignvins.push({
		// 			consignment_code:val,
		// 			group_code:group_code,
		// 			consignee_city:consignee_city,
		// 			order:order
		// 		})
		// 		$("#"+val).prop("disabled",true)
		// 		$(this).prop("disabled",true)
		// 		$(this).remove()
		// 		$("li#"+val).remove()
		// 	}
		// 	else
		// 	{
		// 		asigned.push({
		// 			consignment_code:val,
		// 			group_code:group_code,
		// 			consignee_city:consignee_city,
		// 			order:order
		// 		})
		// 	}
			
		// })
		// //console.log("asigned ",asigned)
		// var html = '';
		// if(assignvins.length > 0)
		// {
		// 	if(asigned.length > 0)
		// 	{
		// 		var movetounassign =this.state.allunassignvins.filter(f =>
		// 			!asigned.some(d => d.consignment_code == f.consignment_code)
		// 		);
		// 	}
		// 	else{
		// 		var movetounassign = this.state.allunassignvins;
		// 	}
		// 	//console.log("movetounassign ", movetounassign)
		// 	movetounassign.sort(GetSortAscOrder("order"))
		// 	if(movetounassign.length > 0)
		// 	{
		// 		movetounassign.map((item, index) => {
		// 			html += "<li id='"+item.consignment_code+"' class='"+item.order+"'>";
		// 			html += '<span class="scolumn-l">'
		// 			html += "<input type='checkbox' order='"+item.order+"' group_code='"+item.group_code+"' consignee_city='"+item.consignee_city+"' name='unvins[]' class='unvins' value='"+item.consignment_code+"'  /> "+item.consignment_code;
		// 			html += "</span>";
		// 			html += '<span class="scolumn-s">'+item.group_code+'</span>'
		// 			html += '<span class="scolumn-m">'+item.consignee_city+'</span>'
		// 			html += "</li>";
		// 		})
		// 	}
			
		// 	// console.log("html ",html)
		// 	this.setState({
		// 		assignedvins:asigned
		// 	})
		// }
		
		// $("#unassignvins").html(html)
		// $(".allassignvins").prop("checked",false)

		var rwCount = this.gridApi1.getSelectedNodes();
        // console.log("rwCount ",rwCount)
        var records = []
        if(rwCount.length > 0)
        {
            rwCount.map((item) => {
                records.push(item.data)
            })
		}
		// console.log("Assign ",records)
		var unassingecs = [];
		var rmunassingecs = [];
		var assingecs = [];
		if(this.state.assignedvins.length > 0)
		{
			assingecs = await this.state.assignedvins.filter(f =>
				!records.some(d => d.consignment_code == f.consignment_code)
			);

			//  unassingecs = _(this.state.allunassigndata) 
			// 		.differenceBy(records, 'consignment_code')
			// 		.value();
		}
		if(assingecs.length > 0)
		{
			rmunassingecs = await assingecs.filter(f =>
				!records.some(d => d.consignment_code == f.consignment_code)
			);

			//  unassingecs = _(this.state.allunassigndata) 
			// 		.differenceBy(records, 'consignment_code')
			// 		.value();
		}
		if(this.state.allunassigndata.length > 0)
		{
			unassingecs = await this.state.allunassigndata.filter(f =>
				!rmunassingecs.some(d => d.consignment_code == f.consignment_code)
			);

			//  unassingecs = _(this.state.allunassigndata) 
			// 		.differenceBy(records, 'consignment_code')
			// 		.value();
		}
		
		// console.log("unassingecs here ",unassingecs)
		await this.setState({
			assignrowData:assingecs,
			assignedvins:assingecs,
			unassignrowData:unassingecs			
		})

	}
	
	onChangeTrailer = (event) => {
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}

	transporters()
	{
		let items = [];
		try{
			if(this.state.transporters.length > 0)
			{
				this.state.transporters.map((item) =>{
					items.push({"value":item.transporter_code,"label":item.transporter_name})
				})
			}
		}
		catch(e){

		}
		
		return items;
	}
	loadingofficers()
	{
		let items = [];
		// try{
		// 	if(this.state.loadofficers.length > 0)
		// 	{
		// 		this.state.loadofficers.map((item) =>{
		// 			items.push({"value":item.load_officer_code,"label":item.load_officer_name})
		// 		})
		// 	}
		// }
		// catch(e){

		// }
		try{
			if(this.state.loadofficers.length > 0)
			{
				this.state.loadofficers.map((item) =>{
					var nm = item.load_officer_name
					items.push({"value":nm,"label":nm})
				})
			}
		}
		catch(e){

		}
		return items;
	}
	
    onChangeBA(batransporter){
		this.setState(
			{ batransporter },
			() => console.log(`Option selected:`, this.state.batransporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
	
	
    onChangeLoadingOfficer(loading_officer){
		this.setState(
			{ loading_officer },
			() => console.log(`Option selected:`, this.state.loading_officer)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	formHandler = async() => {
		// event.preventDefault();
		// var destypeopt = this.state.destypeopt.value;
		// var destypeoptname = this.state.destypeopt.label;
		// var lastmiledestination = this.state.lastmiledestination.value;

		// var modetype = this.state.modetype;
		// var gpsdeviceid = this.state.gpsdeviceid;
		// var deviceplaceat = this.state.deviceplaceat;


		// if(this.state.destypeopt != "" && this.state.destypeopt != undefined)
		// {
		// 	if(this.state.destypeopt.value != undefined && this.state.destypeopt.value != "")
		// 	{
		// 		var destypeopt = this.state.destypeopt.value;
		// 		var destypeoptname = this.state.destypeopt.label;

		// 	}
		// 	else
		// 	{
		// 		var destypeopt = "";
		// 		var destypeoptname = "";
		// 	}
		// }
		// else
		// {
			var destypeopt = "";
			var destypeoptname = "";
		// }
		// if(this.state.lastmiledestination != "" && this.state.lastmiledestination != undefined)
		// {
		// 	if(this.state.lastmiledestination.value != undefined && this.state.lastmiledestination.value != "")
		// 	{
		// 		var lastmiledestination = this.state.lastmiledestination.value;
		// 	}
		// 	else
		// 	{
		// 		var lastmiledestination = "";
		// 	}
		// }
		// else
		// {
			var lastmiledestination = "";
		// }
		
		// if(this.state.modetype != "" && this.state.modetype != undefined)
		// {
		// 	var modetype = this.state.modetype.value;
		// }
		// else
		// {
			var modetype = "Trailer";
		// }
		
		if(this.state.gpsdeviceid != "" && this.state.gpsdeviceid != undefined)
		{
			if(this.state.gpsdeviceid.value !=undefined && this.state.gpsdeviceid.value != "")
			{
				var gpsdeviceid = this.state.gpsdeviceid.value;
			}
			else{
				var gpsdeviceid = "";
			}
		}
		else
		{
			var gpsdeviceid = "";
		}
		if(this.state.deviceplaceat != "" && this.state.deviceplaceat != undefined)
		{
			if(this.state.deviceplaceat.value != undefined && this.state.deviceplaceat.value != "")
			{
				var deviceplaceat = this.state.deviceplaceat.value;
			}
			else{
				var deviceplaceat = "";
			}
		}
		else
		{
			var deviceplaceat = "";
		}

		
		
		var assignedvins = []
		this.gridApi1.forEachNode(node => assignedvins.push(node.data));
        // console.log("parmas ", assignedvins)
					
		var tflag  = 0;
		var trcs = []
		if(assignedvins.length > 0)
		{
			assignedvins.map((item) => {
				// if(modetype == "Convoy" && (item.last_mile_trc_no == "" || item.last_mile_trc_no == undefined))
				// {
				// 	tflag = 1
				// }
				trcs.push(item.last_mile_trc_no)
			})

			
		}

		if(this.state.trailerno != "" && this.state.trailerno != undefined)
		{
			var trailerno = this.state.trailerno;
		}
		else
		{
			var trailerno = "";
		}
		
		// if(this.state.batransporter != "" && this.state.batransporter != undefined)
		// {
		// 	if(this.state.batransporter.value != undefined && this.state.batransporter.value != "")
		// 	{
		// 		var transporter_code  = this.state.batransporter.value;
		// 		var transporter_name  = this.state.batransporter.label;
		// 	}
		// 	else
		// 	{
		// 		var transporter_code  = "";
		// 		var transporter_name  = "";

		// 	}
		// }
		// else
		// {
		// 	var transporter_code  = "";
		// 	var transporter_name  = "";
		// }
		// var transporter_code  = localStorage.getItem("transportercode");
		// var transporter_name  = localStorage.getItem("firstname");
		var gps_truck_no="";
		if(this.state.gps_truck_no != "" && this.state.gps_truck_no != undefined)
		{
			if(this.state.gps_truck_no.value != "")
			{
				gps_truck_no = this.state.gps_truck_no.value;
			}
			
		}
		// if(this.state.loading_officer != "" && this.state.loading_officer != undefined)
		// {
		// 	if(this.state.loading_officer.value != undefined)
		// 	{

		// 		var loading_officer_code =  this.state.loading_officer.value;
		// 		var loading_officer_name =  this.state.loading_officer.label;
		// 	}
		// 	else
		// 	{
		// 		var loading_officer_code =  "";
		// 		var loading_officer_name =  "";

		// 	}
		// }
		// else
		// {
			var loading_officer_code =  "";
			var loading_officer_name =  "";
		// }

		// var trailerno = this.state.trailerno;
		// var transporter_code  = this.state.batransporter.value;
		// var transporter_name  = this.state.batransporter.label;
		// var driver_name = this.state.driver_name;
		// var driver_mobile = this.state.driver_mobile;
		// var loading_officer_code =  this.state.loading_officer.value;
		// var loading_officer_name =  this.state.loading_officer.label;
		var cdt = moment.parseZone().format("DDMMYYHHmmss")
		if(modetype == "Trailer")
		{
			
			var trailerno = trailerno;
			var batchno = trailerno+""+cdt
		}
		else
		{
			
			var trailerno = "";
			if(deviceplaceat != "")
			{
				var dpval = deviceplaceat;
				var btno = dpval.substr(dpval.length - 4)
				var batchno = btno+""+cdt
			}
			else
			{
				var batchno = ""
			}
			
		}

		
		var dispatch_time = $("#dispatch_time").val();
		
		if(dispatch_time != "__-__-____ __:__" && dispatch_time != "")
		{
			var dispatch_time = dispatch_time
		}
		else{
			dispatch_time = ""
		}

		if(assignedvins.length == 0)
		{
			this.setState({
				show1:true,
				basicTitle1:"Please select Batch No",
				basicType1:"danger"
			})
		}
		else
		{
			var formdata = {
				//batchno:batchno,
				modetype:"Trailer",
				gpsdeviceid:gpsdeviceid,
				deviceplaceat:deviceplaceat,
				gps_truck_no:gps_truck_no,
				// loadingyard:loadingyard.value,
				// loadingyard_name:loadingyard.label,
				// ewaybilldate:ewaybilldate,
				// ewaybillexpirydate:ewaybillexpirydate,
				assignedvins:assignedvins,
				destypeopt:destypeopt,
				destypeoptname:destypeoptname,
				lastmiledestination:lastmiledestination,
				// driver_name:driver_name,
				// driver_mobile:driver_mobile,
				// loading_officer_code:loading_officer_code,
				// loading_officer_name:loading_officer_name,
				//transporter_code:transporter_code,
                //transporter_name:transporter_name,
                //tpt_code:localStorage.getItem("transportercode"),
				trailer_no:trailerno,
				trcnos: trcs,
				dispatch_time:dispatch_time,
				email:localStorage.getItem("email"),
				username:localStorage.getItem("username"),
				userid:localStorage.getItem("userid")
			}
			console.log("Formdata ", formdata)
			if(tflag == 0)
			{
				redirectURL.post("/dashboard/tptlasmileupdate", formdata)
				.then((response) => {
					// console.log("respns ", response.data)
					if(response.data.status == "exists")
					{
						this.setState({
							show1:true,
							basicTitle1:"Duplicate TRC Nos not allowed",
							basicType1:"info"
						});
					}
					else if(response.data.status == "fail")
					{
						this.setState({
							show:true,
							basicTitle:"No TRC No found",
							basicType:"danger"
						});
					}
					else if(response.data.status == "formfields")
					{
						this.setState({
							show1:true,
							basicTitle1:"Please enter or select fields",
							basicType1:"danger"
						});
					}
					else{
						this.setState({
							show:true,
							basicTitle:"Successfully created batch consignments",
							basicType:"success"
						});
						document.getElementById("batchForm").reset();
						if(this.props.match.params.refno != undefined)
						{
							var refno = this.props.match.params.refno
						}
						else{
							var refno = "";
						}
						var parameters = {
							startdate:this.state.startDate,
							enddate:this.state.endDate,
							refno:refno
						}
						//this.onLoadRakePlanningData(parameters)
					}
				})
			}
			else
			{
				this.setState({
					show1:true,
					basicTitle1:"Please enter trc no field",
					basicType1:"danger"
				})
			}
		}
		
	}
	showUploadVins(){
		this.setState({
			overly:"show-m",
			slideupload:"slider-translate"
		})
	}

	formUploadVins(event){
		event.preventDefault()
		// xlsxurl();
		//jszipurl();
		// var formdata = new FormData();
		// console.log("this.state.vinsfile[0] ", this.state.vinsfile)
		// formdata.append("Files",this.state.vinsfile)
		// console.log("Formdata ", formdata)
		// var urlpath = "http://164.52.197.10/readExcelFile";
		var urlpath = "/dashboard/lastmilereadcsvexcelfile"; 
		var formdata = {
			csvcontent: this.state.csvcontent
		}
		redirectURL.post(urlpath,formdata)
		.then(async (response) => {
			var assignedvins = response.data.records;
			// console.log("assignedvins ", assignedvins)
			var appendvins = []
			if(this.state.allvins.length > 0)
			{	
				var vns = this.state.allvins;
				for(var i=0;i<vns.length;i++)
				{
					for(var j=0;j<assignedvins.length;j++)
					{
						if(vns[i].consignment_code == assignedvins[j].consignment_code)
						{
							vns[i]["last_mile_trc_no"] = assignedvins[j].last_mile_trc_no
							vns[i]["last_mile_driver_name"] = assignedvins[j].driver_name
							vns[i]["last_mile_driver_mobile"] = assignedvins[j].driver_mobile
							vns[i]["tos_dummy_truck"] = assignedvins[j].tos_dummy_truck
						}
					}
				}
			}
			if(vns.length > 0)
			{
				var movetounassign =await vns.filter(f =>
					assignedvins.some(d => d.consignment_code == f.consignment_code)
				);
			}
			else
			{
				var movetounassign = []
			}

			var unmovetounassign =await this.state.allunassignvins.filter(f =>
				!assignedvins.some(d => d.consignment_code == f.consignment_code)
			);
			if(movetounassign.length > 0)
			{
				// console.log("movetounassign ",movetounassign)
				// this.setState({
					
				// 	dummytruck:movetounassign[0].tos_dummy_truck,
				// 	gps_truck_no:{value:movetounassign[0].tos_dummy_truck,label:movetounassign[0].tos_dummy_truck}
				// })
				var flag = []
				var avins=[]
				await movetounassign.map((item, index) => {
					
						avins.push({
							consignment_code:item.consignment_code,
							//group_code:item.group_code,
							consignee_city:item.consignee_city,
							order:item.order,
							last_mile_trc_no:item.last_mile_trc_no,
							last_mile_driver_name:item.last_mile_driver_name,
							last_mile_driver_mobile:item.last_mile_driver_mobile,
							//tos_dummy_truck:item.tos_dummy_truck
						})
					
				})	
				
					var html="";
					if(avins.length > 0)
					{
						// avins.map((item, index) => {
						// 	html += "<li id='"+item.consignment_code+"' class='"+item.order+"'>";
						// 	html += '<span class="scolumn-l">'
						// 	html += "<input type='checkbox' order='"+item.order+"' group_code='"+item.group_code+"' consignee_city='"+item.consignee_city+"' name='assignvins[]' class='assignvins' value='"+item.consignment_code+"'  />"+item.consignment_code 
						// 	html += "</span>";
						// 	html += '<span class="scolumn-s">'+item.group_code+'</span>'
						// 	html += '<span class="scolumn-m">'+item.consignee_city+'</span>'
						// 	html += "</li>"
						
							
						// })
						//console.log("avins ", avins)

						var unassingecs = [];
						var rmunassingecs = [];
						var assingecs = [];
						if(avins.length > 0)
						{
							// assingecs = await avins.filter(f =>
							// 	!avins.some(d => d.consignment_code == f.consignment_code)
							// );

							//  unassingecs = _(this.state.allunassigndata) 
							// 		.differenceBy(records, 'consignment_code')
							// 		.value();
						}
						// if(assingecs.length > 0)
						// {
						// 	rmunassingecs = await assingecs.filter(f =>
						// 		!avins.some(d => d.consignment_code == f.consignment_code)
						// 	);

						// 	//  unassingecs = _(this.state.allunassigndata) 
						// 	// 		.differenceBy(records, 'consignment_code')
						// 	// 		.value();
						// }
						if(this.state.allunassigndata.length > 0)
						{
							unassingecs = await this.state.allunassigndata.filter(f =>
								!avins.some(d => d.consignment_code == f.consignment_code)
							);

							//  unassingecs = _(this.state.allunassigndata) 
							// 		.differenceBy(records, 'consignment_code')
							// 		.value();
						}

						let dummytrucks = [];               
						// Declare an empty object 
						let totuniqueObject = {}; 
						var totobjTitle;
						// Loop for the array elements 
						for (let i in avins) { 
				
							// Extract the title 
							totobjTitle = avins[i]['tos_dummy_truck']; 
				
							// Use the title as the index 
							totuniqueObject[totobjTitle] = avins[i]; 
						} 
				
						// Loop to push unique object into array 
						for (var a in totuniqueObject) { 
							dummytrucks.push(totuniqueObject[a].truck); 
						} 
						this.setState({
							// assignedvins:avins,
							// assignrowData:avins,
							overly:"show-n",
							slideupload:"",
							unassignvins:unmovetounassign,
							assignrowData:avins,
							assignedvins:avins,
							unassignrowData:unassingecs
							
						})
						
					}
					// console.log("html ",html)
					// $("#assignvins").append(html)
				
			}
			else
			{
				this.setState({
					show:true,
					basicTitle:"Uploaded Vins are not exists",
					basicType:"danger"
				})
			}
		})
		
		
    }
    
    changeLastMileFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'vin_no',
					inputName: 'vin_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
    }
    
    onChangeLastMilDestinationItem(lastmiledestination){
		this.setState(
			{ lastmiledestination },
			async () => {
				var items = []
				var deviceslist = []
				// console.log("lastmiledestination.value ", lastmiledestination.value)
				if(lastmiledestination.value != "")
				{
					var desttype = "";
					// console.log("lastmiledestination.value ",this.state.rsos)
					if(lastmiledestination.value == "RSO")
					{
						var rso = this.state.rsos;
						if(rso.length > 0)
						{
							rso.map((item) => {
								items.push({
									value:item.plant_code,
									label:item.plant_name
								});
							})
						}
						desttype = "Select RSO";
						var sdevs = this.state.alldevices;
						
						if(sdevs.length > 0)
						{
							sdevs.map((item) => {
								if(parseInt(item.status) == 10)
								{
									deviceslist.push(item)
								}
							})
						}
					}
					if(lastmiledestination.value == "Dealer")
					{
						var consignees = this.state.consignees;
						// console.log("consignees", consignees)
						if(consignees.length > 0)
						{
							consignees.map((item) => {
								items.push({
									value:item.consignee_code,
									label:item.consignee_name
								});
							})
						}
						
						// console.log("consignees items ", items)
						desttype = "Select Dealer";
						var sdevs = this.state.alldevices;
						
						if(sdevs.length > 0)
						{
							sdevs.map((item) => {
								// if(parseInt(item.status) == 10 
								// || parseInt(item.status) == 11  || parseInt(item.status) == 12
								//   || parseInt(item.status) == 13)
								if(parseInt(item.status) != 11 && parseInt(item.status) != 12)
								{
									// deviceslist.push(item)
								}
							})
						}
					}
					
				}
				// console.log("deviceslist ", deviceslist)
				await this.setState({
					destopts:items,
					desttype:desttype,
					// devices:deviceslist
				})
			}
          );
		  
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:'',
			//   destopts:items
		  })
    }
    
    onChangeDestType(destypeopt){
		this.setState(
			{ destypeopt },
			() => console.log(`Option selected:`, this.state.destypeopt)
		  );
		  
		 
	}
	onChangeModeType(modetype){
		this.setState(
			{ modetype },
			() => console.log(`Option selected:`, this.state.modetype)
		  );
	}
	desttype(){
		var items = []
		if(this.state.destypeopt != "")
		{
			if(this.state.destypeopt.value == "RSO")
			{
				var rso = this.state.rsos;
				if(rso.length > 0)
				{
					rso.map((item) => {
						item.push({
							value:item.rso,
							label:item.rso
						});
					})
				}
			}
			if(this.state.destypeopt.value == "Dealer")
			{
				var consignees = this.state.consignees;
				if(consignees.length > 0)
				{
					consignees.map((item) => {
						item.push({
							value:item.consignee_code,
							label:item.consignee_name
						});
					})
				}
			}
		}
		return items;
	}

    render(){
		var  { unassignvins } = this.state; 
		console.log("localStorage.getItem('is_oem') ",localStorage.getItem("is_oem"))
		if(localStorage.getItem("oem") == "KIA")
		{
			var hidec = false;
		}
		else{
			var hidec = true;
		}
		var columnwithunassign = [
			{
				headerName:"Batch No",
				field:"consignment_code",
				width:180,
                headerCheckboxSelection: true,
                checkboxSelection: true
			},
			// {
			// 	headerName:"Model",
			// 	field:"group_code",
			// 	width:80
			// },
			{
				headerName:"City",
				field:"consignee_city",
				width:120
			},
			// {
			// 	headerName:"Dummy Truck No",
			// 	field:"tos_dummy_truck",
			// 	hide:hidec,
			// 	width:120
			// }
		]
		var columnwithassign = [
			{
				headerName:"Batch No",
				field:"consignment_code",
                headerCheckboxSelection: true,
                checkboxSelection: true
			},
			// {
			// 	headerName:"Model",
			// 	field:"group_code",
			// 	width:80
			// },
			{
				headerName:"City",
				field:"consignee_city",
				width:120
			},
			// {
			// 	headerName:"TRC No",
			// 	field:"last_mile_trc_no",
			// 	width:120,
			// 	editable:true,
			// 	valueGetter:function(params){
            //         if(params.data.last_mile_trc_no != ""){
            //             var last_mile_trc_no = params.data.last_mile_trc_no;
            //             return last_mile_trc_no;
            //         }
            //         else{
			// 			return "";
			// 		}
            //     }
			// },
			
			// {
			// 	headerName:"Driver Name",
			// 	field:"last_mile_driver_name",
			// 	width:100,
			// 	editable:true,
			// 	valueGetter:function(params){
            //         if(params.data.last_mile_driver_name != ""){
            //             var driver_name = params.data.last_mile_driver_name;
            //             return driver_name;
            //         }
            //         else{
			// 			return "";
			// 		}
            //     }
			// },
			// {
			// 	headerName:"Driver Contact No",
			// 	field:"last_mile_driver_mobile",
			// 	width:100,
			// 	editable:true,
			// 	cellEditor:  'numericCellEditor',
			// 	valueGetter:function(params){
            //         if(params.data.last_mile_driver_mobile != ""){
            //             var driver_mobile = params.data.last_mile_driver_mobile;
            //             return driver_mobile;
            //         }
            //         else{
			// 			return "";
			// 		}
            //     }
			// }
			
		]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                    <div className="row col-xl-12 col-lg-12">
                       
            		    <div className="col-xl-12 col-lg-12">
                        
                            <div className="card">

                                <div className="card-header">
                                    <h5>
                                        <i className="icon-map cus-i"></i> <span>Consignments - Last Mile Transport Batch (Customer)  </span>
										{/* <a href="/mllsummary" className="btn btn-danger float-right f12" >Back</a> */}
										<a href={"/rail-consignments/"+this.state.rake_no} className="btn btn-primary float-right"> Back</a>
										{/* <button type="button" className="btn btn-warning float-right f12" onClick={this.showUploadVins.bind(this)}>Upload Consignments</button> */}
										
									</h5>
                                </div>
                                <div className="card-body row">
                                    <div className={"row col-xl-12 col-lg-12 "}> 
									<form id="batchForm" className="row col-xl-12 col-lg-12" method="POST">
                                        <div className="col-xl-5 col-lg-5" id="rightdiv" style={{minHeight:"300px"}}>
                                            {/* <h6>UnAssign Vins</h6>
											<span className="listhd col-xl-12 col-lg-12">
												<span className="scolumn-l scolumn-pos-1 noborder" style={{textAlign:"left"}}>
                                                    <input type="checkbox" name="allunvins[]" className="allunvins" onClick={this.allunassingVinsNow.bind(this)} />&nbsp;
													Vin No
													
												</span>
												<span className="scolumn-s scolumn-pos-3 noborder">
													Model
												</span>
												<span className="scolumn-m scolumn-pos-2 w34 noborder">
													City
												</span>
												<input type="text" id="myInput" className="form-control" onKeyUp={myFunction} placeholder="Search VIN" />
											</span>
                                            <ul className="col-xl-12 col-lg-12 mselect" id="unassignvins">
                                            {(unassignvins.length > 0)?
                                                unassignvins.map((item, index) =>
                                                <li id={item.consignment_code} className={item.order}>
													<span className="scolumn-l">
												    	<input type="checkbox" onClick={this.totatselectitem.bind(this)} name="unvins[]" order={item.order} group_code={item.group_code} consignee_city={item.consignee_city} className="unvins" value={item.consignment_code} /> {item.consignment_code}
													</span>
													<span className="scolumn-s">
												    	{item.group_code}
													</span>
													<span className="scolumn-m">
												    	{item.consignee_city}
													</span>
												</li>
                                                )
                                            :""}
                                            </ul>
											Total Selected : <span id="totalunassigns">0</span> */}

											<div id="myGrid" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithunassign}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.unassignrowData}
													enableCharts={true}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
                                        			rowSelection={this.state.rowSelection}
													// rowClassRules={this.state.rowClassRules}
													frameworkComponents={this.state.frameworkComponents}
													gridOptions={{
														context: { componentParent: this }
													}}
													overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
													detailCellRendererParams={this.state.detailCellRendererParams}
													masterDetail={true}
												/>

											</div>
										
										</div>
										
                                        <div className="col">
											<button 
												type="button" 
												className="btn btn-danger mt-80p" 
												style={{padding:"0.375rem 0.65rem"}}
												onClick={this.assingVinsNow.bind(this)}>
													<i className="icofont icofont-rounded-double-right f36"></i>
												</button><br />
											<button 
												type="button" 
												className="btn btn-success mt-10p " 
												style={{padding:"0.375rem 0.65rem"}} 
												onClick={this.unassignNow.bind(this)}>
													<i className="icofont icofont-rounded-double-left f36"></i>
											</button>
										</div>
                                        <div className="col-xl-6 col-lg-6" id="leftdiv">
{/* 
                                            <h6>Assigned Vins</h6>
											<span className="listhd col-xl-12 col-lg-12">
												<span className="scolumn-l scolumn-pos-1 noborder" style={{textAlign:"left"}}>
                                                <input type="checkbox" name="allvins[]" className="allassignvins" onClick={this.allassingVinsNow.bind(this)} />&nbsp;
													Vin No
												</span>
												<span className="scolumn-s scolumn-pos-3 noborder">
													Model
												</span>
												<span className="scolumn-m scolumn-pos-2 w34 noborder">
													City
												</span>
												<input type="text" id="myInput1" className="form-control" onKeyUp={myFunction1} placeholder="Search VIN" />
											</span>
											<ul className="col-xl-12 col-lg-12 mselect" id="assignvins">
												
											</ul> */}
											<div id="myGrid1" style={{ height: "380px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithassign}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.assignrowData}
													enableCharts={true}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady1}
													onGridState={this.onGridState1}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
                                        			rowSelection={this.state.rowSelection1}
													// rowClassRules={this.state.rowClassRules}
													frameworkComponents={this.state.frameworkComponents}
													gridOptions={{
														context: { componentParent: this }
													}}
													components={this.state.components}
													overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
													detailCellRendererParams={this.state.detailCellRendererParams}
													masterDetail={true}
												/>

											</div>
                                        </div>
										{/* <div className="col-xl-4 col-lg-4 row form-group">
                                            <label className="col-xl-12 col-lg-12">Select Destination Type *</label>
                                            
                                            <Select 
                                            placeholder={"Select Destination"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeLastMilDestinationItem.bind(this)} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state.lastmiledestination}
                                            options={[
                                                // {"value":"RSO", "label":"RSO"},
                                                {"value":"Dealer", "label":"Dealer"}
                                            ]} />  
                                        </div>

                                        <div className="col-xl-4 col-lg-4 row form-group">
                                            <label className="col-xl-12 col-lg-12">{this.state.desttype} *</label>
                                            
                                            <Select 
                                            placeholder={this.state.desttype}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeDestType.bind(this)} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state.destypeopt}
                                            options={this.state.destopts} />  
                                        </div> */}

                                        {/* <div className="col-xl-4 col-lg-4">
											<label className="col-xl-12 col-lg-12">Select Mode:</label>
											<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
											<Select 
												placeholder={"Select Mode"}
												closeMenuOnSelect={true}
												onChange={this.onChangeModeItem.bind(this)} 
												className={"col-xl-12 col-lg-12 border-radius-0"}
												style={{borderRadius:"0px"}}
												value={this.state.modetype}
												options={this.selectmodes()} 
											/> 
											</div>
										</div> */}
										
                                        <div className="col-xl-4 col-lg-4">
											<label className="col-xl-12 col-lg-12">Time Of Dispatch</label>
											<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
											<input type="text" placeholder="Dispatch Date and Time" id="dispatch_time" name="dispatch_time" className="datetimepicker_mask form-control" required />
							
											</div>
										</div>
										<div className={"col-xl-4 col-lg-4 mt-20p "+(this.state.showtrailer)}>
											<label className="col-xl-12 col-lg-12">Truck No:</label>
											<div className="col-xl-12 col-lg-12">
												<input type="text" className="form-control" name="trailerno" value={this.state.trailerno} onChange={this.onChangeTrailer.bind(this)} />
											</div>
										</div>
										{/* <div className={"col-xl-4 col-lg-4 mt-20p "}>
											<label className="col-xl-12 col-lg-12">Select Truck No:</label>
											<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
											{(localStorage.getItem("oem") == "KIA")?
												<input type="text"
												value={this.state.dummytruck}
												readonly />
											:
												<Select 
													placeholder={"Select Truck No"}
													closeMenuOnSelect={true}
													onChange={this.onChangeGPSDeviceTruckItem.bind(this)} 
													className={"col-xl-12 col-lg-12 border-radius-0"}
													style={{borderRadius:"0px"}}
													value={this.state.gps_truck_no}
													options={this.selectDummyTrucks()} 
												/>  
												
											}
											
											</div>
										</div> */}

                                        <div className={"col-xl-4 col-lg-4 mt-20p "+(this.state.showconvoyfields)}>
											<label className="col-xl-12 col-lg-12">Select GPS Device:</label>
											<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
											<Select 
												placeholder={"Select GPS Device"}
												closeMenuOnSelect={true}
												onChange={this.onChangeGPSDeviceItem.bind(this)} 
												className={"col-xl-12 col-lg-12 border-radius-0"}
												style={{borderRadius:"0px"}}
												value={this.state.gpsdeviceid}
												options={this.selectgpsdevices()} 
											/> 
											</div>
										</div>


                                        <div className={"col-xl-4 col-lg-4 mt-20p "+(this.state.showconvoyfields)}>
											<label className="col-xl-12 col-lg-12">VIN GPS Device Placed At:</label>
											<div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
											<Select 
												placeholder={"Device At"}
												closeMenuOnSelect={true}
												onChange={this.onChangeDeviceAtItem.bind(this)} 
												className={"col-xl-12 col-lg-12 border-radius-0"}
												style={{borderRadius:"0px"}}
												value={this.state.deviceplaceat}
												options={this.assignedVins()} 
											/> 
											</div>
										</div>
										 
{/* 										 
										<div className={"col-xl-4 col-lg-4 mt-20p"}>
											 <label className="col-xl-12 col-lg-12">Select BA:</label>
											 <div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
											 <Select 
												 placeholder={"Select BA"}
												 closeMenuOnSelect={true}
												 onChange={this.onChangeBA.bind(this)} 
												 className={"col-xl-12 col-lg-12 border-radius-0"}
												 style={{borderRadius:"0px"}}
												 value={this.state.batransporter}
												 options={this.transporters()} 
											 /> 
											 </div>
										 </div> */}
										
										 {/* <div className="col-xl-4 col-lg-4 mt-20p">
											<label className="col-xl-12 col-lg-12">Driver's Name:</label>
											<div className="col-xl-12 col-lg-12">
												<input type="text" 
												placeholder="Driver's Name" 
												id="driver_name" 
												name="driver_name"
												onChange={this.changeHandler.bind(this)} 
												className="form-control"
												value={this.state.driver_name}
												required />
											</div>
										</div>
										 <div className="col-xl-4 col-lg-4 mt-20p">
											<label className="col-xl-12 col-lg-12">Driver's Mobile Number:</label>
											<div className="col-xl-12 col-lg-12">
												<input type="text" 
												placeholder="Driver's Mobile Number" 
												id="driver_mobile" 
												name="driver_mobile"
												value={this.state.driver_mobile}
												onChange={this.changeHandler.bind(this)} 
												className="form-control"
												required />
											</div>
										</div> */}

										{/* <div className={"col-xl-4 col-lg-4 mt-20p"}>
											 <label className="col-xl-12 col-lg-12">Unloading Officer:</label>
											 <div className="col-xl-12 col-lg-12" style={{paddingLeft:"0px"}}>
											 <Select 
												 placeholder={"Select Loading Officer"}
												 closeMenuOnSelect={true}
												 onChange={this.onChangeLoadingOfficer.bind(this)} 
												 className={"col-xl-12 col-lg-12 border-radius-0"}
												 style={{borderRadius:"0px"}}
												 value={this.state.loading_officer}
												 options={this.loadingofficers()} 
											 /> 
											 </div>
										 </div> */}
										

										<div className="col-xl-12 col-lg-12 mt-20p">
											<button type="button" onClick={this.formHandler.bind(this)} name="submit" className="btn btn-warning">Save</button>
										</div>
										</form>
                                    </div>
                                </div>
                            </div>	
				        </div>
				     </div>
                </div>
				
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

				<div className={"slide-r "+(this.state.slideupload)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Upload vins
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUploadVins.bind(this)} enctype="multipart/form-datas">
								<div className="form-group mt-20p">
									<label className="">Upload File</label> 
									<input type="file" name="uploadfile" id="uploadfile" onChange={this.changeFileHandler.bind(this)} className="form-control" required  />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<a href={require("../../assets/json/sample_last_mile_batch.csv")} className="btn btn-warning">Download Template</a>
								</div>
							</form>
						</div>
					</div>
				</div>

            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#ewaybilldate').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	
	$("#dispatch_time").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#ewaybilldate').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	
	$("#ewaybillexpirydate").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	
	$("#dispatch_time").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	// $('#ewaybilldate').datetimepicker({
	// 	mask:'39-19-9999',
	// 	format:'d-m-Y',
	// 	timepicker:false
	// });
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function movevinstoleft(){
	// console.log("Test left")
	var unvins = []
	$(".unvins").each(function(){
		var val = $(this).val();
		var order = $(this).attr("order");
		if($(this).is(":checked") == true)
		{
			unvins.push({
				value:val,
				index:order
			})
			$("#"+val).prop("disabled",true)
			$(this).prop("disabled",true)
			$(this).remove()
			$("li#"+val).remove()
		}
		
	})
	console.log(unvins)
	var html = '';
	if(unvins.length > 0)
	{
		unvins.map((item, index) => {
			html += "<li id='"+item.value+"'><input type='checkbox' order='"+item.index+"' name='assignvins[]' class='assignvins' value='"+item.value+"'  /> "+item.value+"</li>"
		})
	}
	console.log("html ",html)
	$("#assignvins").append(html)
}
function movevinstoright(){
	var assignvins = []
	$(".assignvins").each(function(){
		var val = $(this).val();
		var order = $(this).attr("order");
		if($(this).is(":checked") == true)
		{
			assignvins.push({
				value:val,
				index:order
			})
			$("#"+val).prop("disabled",true)
			$(this).prop("disabled",true)
			$(this).remove()
			$("li#"+val).remove()
		}
		
	})
	console.log(assignvins)
	var html = '';
	if(assignvins.length > 0)
	{
		assignvins.map((item, index) => {
			html += "<li id='"+item.value+"'><input type='checkbox' order='"+item.index+"' name='unvins[]' class='unvins' value='"+item.value+"'  /> "+item.value+"</li>"
		})
	}
	
	console.log("html ",html)
	$("#unassignvins").append(html)
}
function myFunction() {
	var input, filter, table, tr, td, i, txtValue;
	input = document.getElementById("myInput");
	filter = input.value.toUpperCase();
	table = document.getElementById("unassignvins");
	tr = table.getElementsByTagName("li");
	for (i = 0; i < tr.length; i++) {
	  td = tr[i].getElementsByTagName("span")[0];
	  if (td) {
		txtValue = td.textContent || td.innerText;
		if (txtValue.toUpperCase().indexOf(filter) > -1) {
		  tr[i].style.display = "";
		} else {
		  tr[i].style.display = "none";
		}
	  }       
	}
  }
  
function myFunction1() {
	var input, filter, table, tr, td, i, txtValue;
	input = document.getElementById("myInput1");
	filter = input.value.toUpperCase();
	table = document.getElementById("assignvins");
	console.log("table ", table)
	tr = table.getElementsByTagName("li");
	for (i = 0; i < tr.length; i++) {
	  td = tr[i].getElementsByTagName("span")[0];
	  if (td) {
		txtValue = td.textContent || td.innerText;
		if (txtValue.toUpperCase().indexOf(filter) > -1) {
		  tr[i].style.display = "";
		} else {
		  tr[i].style.display = "none";
		}
	  }       
	}
  }

  function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isKeyPressedNumeric(event) {
    var charCode = getCharCodeFromEvent(event);
    var charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

// simple function cellRenderer, just returns back the name of the country
function CountryCellRenderer(params) {
    return params.value.name;
}

// function to act as a class
function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
			this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function(event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

NumericCellEditor.prototype.isKeyPressedNavigation = function(event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function() {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function() {
	var value = this.getValue();
	// console.log("value ",value)
	if(value != undefined)
	{
		return value.indexOf('007') >= 0;
	}
    
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function() {
	if(this.eInput.value.length == 10)
	{
		return this.eInput.value;
	}
	else{
		return this.eInput.value.substring(0, 10);
	}
    
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
NumericCellEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
