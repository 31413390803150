import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var newcoordinates=[];
var geoFencingArea;
const fenchtypeoptions = [
	  
	{ value: 'Plant', label: 'Plant' },
	{ value: 'Loading Area', label: 'Loading Area' },
	{ value: 'Non Loading Area', label: 'Non Loading Area' },
	{ value: 'Railyard', label: 'Railyard' },
	// { value: 'Destination Railyard', label: 'Destination Railyard' },
	{ value: 'Workshop', label: 'Workshop' },
	{ value: 'Weigh Bridge', label: 'Weigh Bridge' },
	{ value: 'Customer', label: 'Customer' },
	{ value: 'TPA', label: 'TPA' },
	{ value: 'Marshalling Yard', label: 'Marshalling Yard' },
	{ value: 'Pipe Mill', label: 'Pipe Mill' },
	{ value: 'FG', label: 'FG' },
	{ value: 'Loaded Vehicle Parking', label: 'Loaded Vehicle Parking' }
	];

export const validAlphaNumericSpaceSlash = new RegExp(/^[a-zA-Z0-9._&// ]{1,255}$/);


export default class EditGeoFence extends Component {

    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Interplant Geo Fences",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
        	routemap:'',
        	departments:[],
        	coords:[],
        	geo_name:'',
        	geo_type:'',
        	fence_type:'',
        	dept_code:[],
        	notify:'',
        	erroMessage:'',
        	successMessage:'',
        	selectedDepts:[],
			oldgeofencename:'',
			coordinates:[],
			rowData :[],
			redUrl: "",
			consignee_code:"",
			consigneecode:{"value":"","label":"Select"},
			consigneeshow:"show-n",
			errcode:"show-n",
			errcodemsg:"",
			railyardinp:"show-n",
			railyardtype:"3",
			allcustomers:[],
			rowIds:[]
        };
        this.showMap = this.showMap.bind(this);
        this.applyCoords = this.applyCoords.bind(this);
        this.checkHandler = this.checkHandler.bind(this);
        this.checkNotifyHandler = this.checkNotifyHandler.bind(this);
		this.onLoadCustomers = this.onLoadCustomers.bind(this);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
    	if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
    	await redirectURL.post('/overview/departments')
    	.then((response) => {
    		//console.log(response.data);
    		this.setState({
    			departments:response.data
    		});
    	})
    	.catch(function(error){
    		console.log(error)
    	});
    	
    	 
    	await redirectURL.post('/geofence/getInterplantGeofences',{
			headers:{
				'Content-Type': 'application/json'
			}
		})
    	.then(async (response) => {
			var deptarr=[];
			// console.log("response.data[0].dept_code; ", response.data[0].dept_code);
    		var alldepts = [ 
				{
					"value" : "SNDG",
					"label" : "Sales and Dispatch"
				}
			];
			
			var redurl='/manage/geofences';
            var respData = response.data;
            respData.map((r)=>{
                coordinates.push({
                    geofence_name : r.geofence_name,
                    geofence_coordinates : JSON.parse(r.geofence_coordinates)
                });
            });
			//console.log("cc ", coordinates)
			//console.log("response.data[0].geofence_coordinates ", response.data[0].geofence_coordinates)
			var geotypesarr='';
    		this.setState({
				coordinates:coordinates,
    		});
    		
			this.getdatafordealer();
    	})
    	.catch(function(error){
    		console.log(error)
    	});
    	setTimeout(
    		    function() {
    		    	this.showMap();
		    }
		    .bind(this),
		    2000
		);
    	
    	
		this.onLoadCustomers();
    }
	getdatafordealer(){
		var customerCode =""
		if(this.state.consigneecode.value != undefined && this.state.consigneecode.value != null && this.state.consigneecode.value !=""){
			customerCode = this.state.consigneecode.value
		}
		console.log(customerCode,"customer_code12")
		if(customerCode !=undefined && customerCode !="")
		{
			var status = 9;
			var params ={
				customer_code : customerCode,
				status : status,
			}
			redirectURL.post("/consignments/getdataforcustomer",params)
			.then(async(response)=>{
				var resp = response.data;
				if(resp.length > 0){
					var records = await distinctArrayBy(resp,"shipment_id");
					this.setState({
						rowData : records
					})
				}
			})
		}
	}
	onGridReady = params => {
		this.gridApi = params.api;  
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
	};
	onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}
	onRowSelection(event){
		var rowIds=[];
		var rwCount = event.api.getSelectedNodes();
		var geo_name = this.state.geo_name;
		rwCount.map((item) => {
			rowIds.push({
				truck_no : item.data.truck_no,
				shipment_id : item.data.shipment_id,
				consignee_code : item.data.consignee_code,
				consigner_code : item.data.consigner_code,
				geofence_name : geo_name
			})
		});
		console.log(rowIds,"rowIds")
		this.setState({
			rowIds:rowIds
		})		
	}
    onLoadCustomers(){
        redirectURL.post("/carlsberg/customers")
        .then((response) => {
            var customersarr = response.data.customers;
            this.setState({
                allcustomers:customersarr
            })
        })
    }
	async changeConsignee(consigneecode){
		await this.setState({consigneecode})
		await this.getdatafordealer();
	}
	customers(){
		var items = [];
		var customerlist = this.state.allcustomers;
		if(customerlist.length > 0){
			customerlist.map((itm) => {
				items.push({
					value:itm.consignee_code,
					label:itm.consignee_code
				});
			})
		}
		return items;
	}
    fenceTypeHandler = (event) => {
    	console.log(event.target)
    }


    changeHandler = geo_type => {

    	this.setState(
	      { geo_type },
		  () => {console.log(`Option selected:`, this.state.geo_type)
				
			}
		);
	}
	changeNameHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		if(value != "")
		{
			if(validAlphaNumericSpaceSlash.test(value) == true)
			{
				this.setState({[name]:value});
			}
			
		}
		else{
			this.setState({[name]:value});
		}
    	
    }
    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    changeArrayHandler = selectedDepts => {
    	/*this.setState({
    		dept_code:[...this.state.dept_code,event.target.value]
    	},() => {
    		console.log(this.state.dept_code)
    	});
    	*/
    	
    	this.setState(
	      { selectedDepts },
	      () => console.log(`Option selected:`, this.state.selectedDepts)
	    );
    	
    	
    }
onCellClicked(e)
{
    
}

    changeGeoTypeHandler = geo_type => {
    	/*this.setState({
    		dept_code:[...this.state.dept_code,event.target.value]
    	},() => {
    		console.log(this.state.dept_code)
    	});
    	*/
    	
    	this.setState(
	      { geo_type },
	      () => {
			  console.log(`GeoType Option selected:`, this.state.geo_type)
				if(this.state.geo_type.value == "Customer"){
					this.setState({
						consigneeshow:"show-m",
						consignee_code:""
					})
				}
				else{
					this.setState({
						consigneeshow:"show-n"
					})
				}
				if(this.state.geo_type.value == "Railyard"){
					this.setState({
						railyardinp:"show-m",
					})
				}
				else{
					this.setState({
						railyardinp:"show-n"
					})
				}
			}
	    );
    	
    	
    }
    
    checkNotifyHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    applyCoords =(e) =>{
    	console.log(e);
    }
    
	formHandler = (event) => {
			
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var formdata1 = new FormData(event.target);
		//console.log(coordinates.length, formdata1);
		

		if(coordinates.length > 0)
		{
			
			var flag = 0;
			if(this.state.geo_type.value == "Customer")
			{
				if(this.state.consignee_code.value == "")
				{
					flag = 1;
				}
			}
			if(flag == 0)
			{
				this.setState({
					
					errcode:"show-n",
					errcodemsg:""
				})
				var formdata ={
						geo_name: this.state.geo_name,
						// dept_code: this.state.selectedDepts,
						geo_type: this.state.geo_type,
						// fence_type: this.state.fence_type,
						// notify: this.state.notify,
						coordinates: coordinates,
						oldgeofencename:this.state.oldgeofencename,
						consignee_code:this.state.consigneecode.value,
						railyardtype:this.state.railyardtype,
						rowIds:this.state.rowIds
				} 
				console.log(formdata);
				//console.log("coordinates", coordinates);
				
				redirectURL.post("/geofence/updateGeo", formdata,{
					headers:{
						'Content-Type': 'application/json'
					}
				}).
				then(
					(response)=>{
						var resp=response;
						console.log("respones ",resp.message)
						if(this.props.match.path.indexOf("mlleditGeofence") >= 0)
						{
							window.location.href='/manage/mllsndgeofences';
						
						}
						else
						{
							window.location.href='/manage/geofences';
						
						}
						this.setState({
							successMessage:"Successfully updated"
						});
					}
				)
				.catch(function(error){
					console.log(error);
				});
			}
			else{
				this.setState({
					errcode:"show-m",
					errcodemsg:"Please enter customer code."
				})
			}
			
		}
		else
		{
			this.setState({
				erroMessage:'Please Add Coordinates'
			});
		}
		
	}
  
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
    }
    
    departmentDropDown(){
    	let items = []; 
    	this.state.departments.map(item=>{
    		items.push({ value: item.dept_code, label: item.dept_name });
    		//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
    	});
    	return items;
    }
   
    initMap = () => {
		//console.log("coordinates ", this.state.coordinates)
    	var coordsvalues;
    	var geoFencingObj;
    	if(this.state.coords != '')
		{
    		
		}
    	geoFencingObj = coordinates;
		// console.log("here coordinates",geoFencingObj);
		if(geoFencingObj.length > 0)
		{
			var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 13,
				center: new window.google.maps.LatLng(geoFencingObj[0].geofence_coordinates[0].lat,geoFencingObj[0].geofence_coordinates[0].lng),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				},            
				// disableDefaultUI: true,
				zoomControl: true,
                scaleControl: true,
                fullscreenControl: true,
                zoomControlOptions: {
                 position: window.google.maps.ControlPosition.LEFT_TOP
                }
			});
        }
  		//added by nanda for places dropdown...
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', function() {
        	 
            searchBox.setBounds(map.getBounds());
          });
  		
  		//End of adding by nanda
            geoFencingObj.map((gf)=>{
                geoFencingArea = new window.google.maps.Polygon({
                    //map:map,
                    paths: gf.geofence_coordinates,
                    editable: true,
                    strokeColor: '#1D9567',
                    strokeOpacity: 0.70,
                    strokeWeight: 0,
                    fillColor: '#1D9567',
                    fillOpacity: 0.35,
                    draggable:true
                    
                });
                geoFencingArea.setMap(map);
                fencingLength=gf.geofence_coordinates;

                window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at',getPolygonCoords);
                window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);

            })	      
    }
    
    
    render(){
    	let order_cnt=[];

    	const dStyles={
				width:'100%',
				height:'500px'
		}
    	 const { dept_code } = this.state;
    	 const { geo_type } = this.state;
    	 const { fence_type } = this.state;
    	 const { notify } = this.state;
    	 const { selectedDepts } = this.state;
    	 //selectedDepts.push(this.state.dept_code)
		// console.log("Geo Data is ",this.state.geo_type);	
		
		let redurl = this.state.redUrl
		var columnwithDefs = [ 
			{
				headerName: "Actions",
				field: "_id",
				colId: "_id",
				width: 40,
				pinned: 'left',
				filter: false,resizable: true,
				headerCheckboxSelection: true,
				//headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true
				
			},     
			{
				headerName: "Truck No",
				field: "truck_no",
				width:150,
				filter: true,
				resizable: true,
				editable:true,            
			},
			{
				headerName: "Consignee Code",
				field: "consignee_code",
				width:150,
				filter: true,
				resizable: true,
				editable:true,
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: this.state.statesList,
				},
			},
			{
				headerName: "Shipment ID",
				field: "shipment_id",
				width:150,
				filter: true,
				resizable: true,
				editable:true,
				// cellEditor: NumericCellEditor,
			},
			
			// {
			//     pinned:"left",
			//     headerName: "id",
			//     field: "id",
			//     width:100,
			//     filter: true,
			//     resizable: true,
			//     editable:false,            
			// },
		]
        return (
            <div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
				       			</h5>
				   			</div>
			   				<div className="row card-body">
				           		<div className="col-xl-12 col-lg-12">
									
									{this.state.erroMessage == '' ? '':
											<div className="alert alert-danger"></div>
									}
									  {/* <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" /> */}
				
				
				               		<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
				
				 	               <div className="col-xl-12 col-lg-12">
				 					  <input type="hidden" name="coords" id="coords" value={this.state.coords} />
				 				   </div>
				               		
			               		</div>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
                
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

function deleteSelectedShape() {
	fencingLength=0;  
	if (selectedShape) {
		selectedShape.setMap(null);
	}
	// To show:
		drawingManager.setOptions({
		drawingControl: true
		});
}

	
function selectColor(color) {
	selectedColor = color;
	for (var i = 0; i < colors.length; ++i) {
	var currColor = colors[i];
	//console.log(colorButtons[currColor]);
	//colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	}

	// Retrieves the current options from the drawing manager and replaces the
	// stroke or fill color as appropriate.
	var polylineOptions = drawingManager.get('polylineOptions');
	polylineOptions.strokeColor = color;
	drawingManager.set('polylineOptions', polylineOptions);

	var rectangleOptions = drawingManager.get('rectangleOptions');
	rectangleOptions.fillColor = color;
	drawingManager.set('rectangleOptions', rectangleOptions);

	var circleOptions = drawingManager.get('circleOptions');
	circleOptions.fillColor = color;
	drawingManager.set('circleOptions', circleOptions);

	var polygonOptions = drawingManager.get('polygonOptions');
	polygonOptions.fillColor = color;
	drawingManager.set('polygonOptions', polygonOptions);
}

function setSelectedShapeColor(color) {
	if (selectedShape) {
	if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
		selectedShape.set('strokeColor', color);
	} else {
		selectedShape.set('fillColor', color);
	}
	}
}

function makeColorButton(color) {
	var button = document.createElement('span');
	button.className = 'color-button';
	button.style.backgroundColor = color;
	window.google.maps.event.addDomListener(button, 'click', function() {
	selectColor(color);
	setSelectedShapeColor(color);
	});

	return button;
}

function buildColorPalette() {
	var colorPalette = document.getElementById('color-palette');
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		var colorButton = makeColorButton(currColor);
		colorPalette.appendChild(colorButton);
		colorButtons[currColor] = colorButton;
	}
	selectColor(colors[0]);
	}  

function getSelectedShape(coords){
	var lt=[]
	//console.log("coordsLatlngs ",coords);
	for (let value of Object.values(coords)) {
		//console.log("Val is ",value); // John, then 30
		lt.push(value)
	}
	//console.log("Combine ",lt);
	document.getElementById('coords').value=lt;
	//return lt;
}

function getPolygonCoords()
{
	
	var len = geoFencingArea.getPath().getLength();
	console.log(len);
	var htmlStr = "";
	coordinates=[];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		coordinates.push(latlngd);
	// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);
	
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}

