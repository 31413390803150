import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const LineChartComponent = ({ detentionRecords, title }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        // Prepare data for the line chart
        const categories = detentionRecords.map(item => item.name); // X-axis labels (consignee_name)
        const data = detentionRecords.map(item => parseFloat(item.averageDetentionHours)); // Y-axis values

        // Set chart options with styling
        setOptions({
            chart: {
                type: 'line',
                backgroundColor: '#f9f9f9',
                spacingBottom: 20,
                spacingTop: 20,
                spacingLeft: 20,
                spacingRight: 20,
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: categories,
                labels: {
                    style: {
                        color: '#2C3E50',
                        fontSize: '10px',
                        fontWeight: 'bold',
                    },
                    rotation: -45,
                },
                lineColor: '#ddd',
                gridLineWidth: 0,
            },
            yAxis: {
                title: {
                    text: 'Average Detention Hours',
                    style: {
                        color: '#2C3E50',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    },
                },
                labels: {
                    style: {
                        color: '#2C3E50',
                        fontSize: '10px',
                    },
                },
                gridLineColor: '#eee',
                lineColor: '#ddd',
                gridLineWidth: 0,
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}hrs</b>',
                backgroundColor: '#ffffff',
                borderColor: '#ddd',
                borderRadius: 8,
                style: {
                    color: '#333',
                    fontSize: '12px',
                    padding: '10px',
                },
            },
            plotOptions: {
                line: {
                    color: '#FF738A',
                    lineWidth: 2,
                    marker: {
                        enabled: true,
                        fillColor: '#FF738A',
                        lineWidth: 1,
                        lineColor: '#FF738A',
                        radius: 4,
                    },
                },
            },
            series: [{
                name: 'Average Detention Hours',
                data: data,
            }],
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
        });
    }, [detentionRecords]);

    return (
        <div>
            <div style={{ width: "100%" }}>
                <h3 className="chart-title">{title}</h3>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
        </div>
    );
};

export default LineChartComponent;
